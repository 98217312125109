import { useEffect } from 'react';
import CustomLink from '@components/ui/CustomLink';
import FilledButton from '@components/ui/buttons/FilledButton';
import useUser from '@hooks/useUser';
import logo from '@svg/PropManage-logo.svg';
import logoWhite from '@svg/PropManage-logo-white.svg';
import { PATHS_DATA } from '@utils/constants';
import HeaderLink from './HeaderLink';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';

export default function Header() {
  const { isAuthenticUser } = useUser();
  const { pathname } = useLocation();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    // Toggle body overflow based on menu state
    if (open) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [open]);

  useEffect(() => {
    // Handle click outside the menu to close it
    const handleClickOutside = (event) => {
      if (
        open &&
        !event.target.closest('.menu-content') &&
        !event.target.closest('.menu-button')
      ) {
        setOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [open]);

  return (
    <header className='top-0 z-10 laptopS:sticky'>
      <div className='absolute left-1/2 top-[40px] mx-auto hidden w-full max-w-[102.5rem] -translate-x-1/2 transform rounded-full bg-secondary_2 bg-opacity-80 backdrop-blur-md laptopS:block'>
        <div className='flex justify-between'>
          <div className='my-4 ms-10 h-[3.5rem] w-[9.813rem]'>
            <CustomLink path={'/'}>
              <img
                className='h-[3.5rem] w-[9.813rem]'
                src={logo}
                alt='Prop Manage Logo'
              />
            </CustomLink>
          </div>

          <nav className='flex'>
            <ul className='flex w-full items-center justify-center gap-[3.438rem]'>
              {PATHS_DATA.map((item, index) => (
                <HeaderLink
                  key={item?.name + index + 'Nav'}
                  path={item.path}
                  name={item.name}
                  isActive={pathname === item.path}
                />
              ))}
            </ul>
          </nav>
          <div className='my-4 me-12 h-[3.5rem] w-[9.375rem]'>
            <FilledButton
              width='w-[9.375rem]'
              height='h-[3.5rem]'
              text={isAuthenticUser ? 'Dashboard' : 'Login'}
              path={isAuthenticUser ? "'/dashboard'" : '/log-in'}
              className={'rounded-custom-44 text-custom-20'}
            />
          </div>
        </div>
      </div>

      {/* Mobile Hamburger menu */}
      <nav className='absolute z-50 w-full'>
        <div className='flex items-start justify-between p-[1.5rem] laptopS:hidden'>
          <div>
            <CustomLink path={'/'}>
              <img
                className='h-[2.5rem] w-[7rem]'
                src={logo}
                alt='Prop Manage Logo'
              />
            </CustomLink>
          </div>

          <button
            type='button'
            className='menu-button hover:text-grayscale_3 inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-primary_1'
            onClick={() => setOpen(!open)}
          >
            <span className='sr-only'>Open menu</span>
            <svg
              className='h-6 w-6'
              xmlns='http:www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
              stroke='currentColor'
              aria-hidden='true'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth={2}
                d='M4 6h16M4 12h16M4 18h16'
              />
            </svg>
          </button>
        </div>
        <div
          className={`fixed inset-0 z-40 transform transition-transform duration-[700ms] ease-in-out ${
            open ? 'translate-x-0' : 'translate-x-full'
          } laptopS:hidden`}
        >
          <div className='h-full menu-content ms-16 min-h-dvh bg-primary_2 p-[1.5rem]'>
            <div className='flex items-start justify-between'>
              <div>
                <CustomLink path={'/'}>
                  <img
                    className='h-[2.5rem] w-[7rem]'
                    src={logoWhite}
                    alt='Prop Manage Logo'
                  />
                </CustomLink>
              </div>
              <div>
                <button
                  type='button'
                  className='hover:text-grayscale_3 inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary_1'
                  onClick={() => setOpen(!open)}
                >
                  <span className='sr-only'>Close menu</span>
                  <svg
                    className='h-6 w-6'
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                    stroke='currentColor'
                    aria-hidden='true'
                  >
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      strokeWidth={2}
                      d='M6 18L18 6M6 6l12 12'
                    />
                  </svg>
                </button>
              </div>
            </div>
            <div className='flex h-full w-full flex-col items-center justify-center'>
              <ul className='mt-auto flex flex-col gap-[1.5rem] justify-center'>
                {PATHS_DATA?.map((item, index) => (
                  <HeaderLink
                    key={item?.name + index + 'Nav'}
                    path={item?.path}
                    name={item?.name}
                    isActive={pathname === item.path}
                    isMobile={true}
                  />
                ))}
              </ul>
              <div className=' w-full mt-auto pb-10'>
                <FilledButton
                  width='w-full'
                  height='h-[3.5rem]'
                  className='rounded-custom-12 text-custom-20'
                  text={isAuthenticUser ? 'Dashboard' : 'Login'}
                  path={isAuthenticUser ? "'/dashboard'" : '/log-in'}
                />
              </div>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
}
