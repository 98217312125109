// src/components/CustomSwiper.js
import React, { useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import "swiper/scss";
import "swiper/scss/free-mode";
import "swiper/scss/pagination";
import "swiper/scss/navigation";

import styles from './style.module.scss';
import { FreeMode, Pagination, Navigation, Scrollbar, A11y } from 'swiper/modules';

function CustomSwiper({ children, ...props }) {
    const swiperRef = useRef();
  return (
    <div className='group w-full'>
      <Swiper
        onSwiper={(swiper) => {
          swiperRef.current = swiper;
        }}
        modules={[FreeMode, Navigation, Pagination, Scrollbar, A11y]}
        {...props}
        className={`${styles.swiperMainComp}`}
      >
        {React.Children.map(children, (child, index) => {
          return <SwiperSlide  key={index}>{child}</SwiperSlide>;
        })}
      </Swiper>
    </div>
  );
}

export default CustomSwiper;
