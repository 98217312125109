import CustomLink from '@components/ui/CustomLink';
import FilledButton from '@components/ui/buttons/FilledButton';
import CustomInput from '@components/ui/custom-inputs/CustomInput';
import PasswordInput from '@components/ui/custom-inputs/PasswordInput';
import XSvg from '@components/ui/svg/XSvg';
import PropManageLogo from '@images/PropManage-logo.png';
import { XyzTransition } from '@animxyz/react';
import useUser from '@hooks/useUser';
import { usePublicContext } from '@providers/ReactPublicContextProvider';
import useToastNotifications from '@hooks/useToastNotifications';
import { useEffect } from 'react';
import { useFormik } from 'formik';
import { SignInSchema } from '@utils/forms-schemas';
import { useMutation } from '@tanstack/react-query';
import { postSignInQueryFn } from '@queries/publicQueryFns/postQueryFns';
import ErrorFormik from '@components/ui/ErrorFormik';
import useStorage from '@hooks/useStorage';
import { VERIFICATION_TYPES } from '@utils/constants';
import RegistrationSidebar from '@components/ui/RegistrationSidebar';

export default function LogInComponent() {
  const { redirectTo, isAuthenticUser, setUserData } = useUser();
  const { setIsLoading } = usePublicContext();
  const { notifyError, notifySuccess } = useToastNotifications();
  const { setItem } = useStorage('session');

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldTouched,
  } = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: SignInSchema,
    onSubmit: onSubmit,
  });

  const { mutate, isPending: isLoadingMutate } = useMutation({
    mutationFn: postSignInQueryFn,
    onSuccess: handleSuccess,
    onError: handleFailure,
  });

  useEffect(() => {
    if (isAuthenticUser) {
      redirectTo('/dashboard');
    }
    setFieldTouched('', '');
  }, []);

  useEffect(() => {
    setIsLoading(isLoadingMutate);
  }, [isLoadingMutate]);

  function handleSuccess(data) {
    setIsLoading(false);
    setUserData({ ...data?.userData, token: data?.access_token });
    notifySuccess('Login successfully');
    redirectTo('/dashboard');
  }

  function handleFailure(error) {
    console.error(error);
    if (error.response.status === 400) {
      notifyError('Invalid credentials');
    } else if (error.response.status === 401) {
      setItem('VerificationContext', {
        email: values.email,
        VerificationType: VERIFICATION_TYPES.EMAIL,
      });
      notifyError(
        'You must verify your email, the verification code has been sent now',
      );
      redirectTo('/email-verification');
    } else notifyError('Something went wrong, please try later!');
  }

  function onSubmit() {
    mutate({
      ...values,
    });
  }

  return (
    <main className='flex min-h-screen justify-center laptopS:overflow-hidden'>
      <RegistrationSidebar />

      <XyzTransition appear xyz='fade down-50% duration-5'>
        <div className='mx-6 flex flex-col items-center justify-center laptopS:mx-0 laptopS:w-1/2 '>
          <div className='my-10 flex w-full items-center justify-between laptopS:hidden'>
            <CustomLink className='' path={'/'}>
              <img
                className='h-[3.5rem] w-[9.813rem]'
                src={PropManageLogo}
                alt='prop manageLogo'
              />
            </CustomLink>

            <div className='flex items-center justify-center'>
              <CustomLink path={'/'}>
                <XSvg className='h-8 w-8 fill-primary_1' />
              </CustomLink>
            </div>
          </div>
          <div className='max-w-[35rem]'>
            <h3 className='text-custom-24 font-custom-500 text-primary_2'>
              Login
            </h3>
            <p className='mt-4 text-custom-16 text-primary_2'>
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut labore et dolore magna
            </p>
          </div>
          <form
            className=' mt-10 flex w-full max-w-[35rem] flex-col gap-6'
            onSubmit={handleSubmit}
          >
            <div>
              <CustomInput
                shape={2}
                id='email'
                name='email'
                value={values?.email}
                onChange={handleChange}
                onBlur={handleBlur}
                className='h-[4.5rem] w-full'
                placeholder='Email'
                type='email'
              />
              <ErrorFormik
                isError={errors?.email}
                isTouched={touched?.email}
                error={errors?.email}
              />
            </div>
            <div>
              <PasswordInput
                shape={2}
                id='password'
                name='password'
                value={values?.password}
                onChange={handleChange}
                onBlur={handleBlur}
                className='h-[4.5rem] w-full px-6'
                placeholder='Password'
                type='password'
              />
              <ErrorFormik
                isError={errors?.password}
                isTouched={touched?.password}
                error={errors?.password}
              />
            </div>
            <CustomLink path={'/forgot-password'}>
              <p className='text-end text-custom-14 text-primary_1'>
                Forgot your password?
              </p>
            </CustomLink>
            <FilledButton
              isButton
              buttonType='submit'
              text='Login'
              width='w-full'
              height='h-[4rem]'
              className='text-custom-20 rounded-custom-16 desktop:mt-[5rem]'
            />
            <p>
              {' '}
              Don't have an account?{' '}
              <CustomLink path={'/sign-up'}>
                <span className='text-custom-14 text-primary_1'>Sign Up</span>
              </CustomLink>
            </p>
          </form>
        </div>
      </XyzTransition>
    </main>
  );
}
