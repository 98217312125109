export default function XSvg({ className = 'w-6 h-6 fill-white' }) {
  return (
    <svg
      className={className}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 32 32'
    >
      <g id='Close' transform='translate(-1813 -46)'>
        <rect
          id='Rectangle_62341'
          data-name='Rectangle 62341'
          width='32'
          height='32'
          transform='translate(1813 46)'
          fill='none'
          opacity='0'
        />
        <path
          id='Icon_ionic-ios-close'
          data-name='Icon ionic-ios-close'
          d='M22.418,20.288l6.429-6.43a1.507,1.507,0,1,0-2.13-2.131l-6.429,6.43-6.429-6.43a1.507,1.507,0,1,0-2.13,2.131l6.429,6.43-6.429,6.43a1.507,1.507,0,1,0,2.13,2.131l6.429-6.43,6.429,6.43a1.507,1.507,0,1,0,2.13-2.131Z'
          transform='translate(1808.715 41.711)'
        />
      </g>
    </svg>
  );
}
