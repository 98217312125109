export const data = [
  {
    title: 'Onboarding',
    discretion: 'Seamless account setup for a tailored PropManage experience.',
  },
  {
    title: 'Blueprint Analysis',
    discretion:
      'Effortlessly upload blueprints; AI predicts maintenance needs.',
  },
  {
    title: 'IoT Integration',
    discretion: 'Integrate IoT sensors for real-time property insights.',
  },
  {
    title: 'Proactive Alerts',
    discretion: 'Receive immediate notifications for timely issue resolution.',
  },
  {
    title: 'Supplier Management',
    discretion: 'Access a comprehensive list of suppliers on the platform.',
  },
  {
    title: 'Visual Analytics',
    discretion: 'Gain deep insights with advanced visual analytics.',
  },
];
