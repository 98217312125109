import React, { useState, useEffect, useRef, useCallback } from 'react';
import styles from './styles.module.scss';

const LazyLoadImage = ({
  src,
  placeholder,
  alt,
  placeholderClassName,
  className,
  ...props
}) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const imageRef = useRef(null);

  const loadImage = useCallback(() => {
    const imgElement = imageRef.current;
    if (imgElement) {
      imgElement.src = src;
      imgElement.onload = () => setImageLoaded(true);
    }
  }, [src]);

  useEffect(() => {
    const imgElement = imageRef.current;

    if (imgElement && 'IntersectionObserver' in window) {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              loadImage();
              observer.unobserve(imgElement);
            }
          });
        },
        {
          rootMargin: '0px',
          threshold: 0.01,
        }
      );

      observer.observe(imgElement);

      return () => {
        if (imgElement) {
          observer.unobserve(imgElement);
        }
      };
    } else {
      // Fallback for browsers without IntersectionObserver support
      loadImage();
    }
  }, [loadImage]);

  const imageClass = imageLoaded ? className : placeholderClassName;
  const effectiveSrc = imageLoaded ? src : placeholder;

  return (
    <img
      ref={imageRef}
      loading="lazy"
      className={`${styles.lazyLoadImageBackground} ${imageLoaded ? styles.lazyLoadImageLoaded : ''} ${imageClass}`}
      src={effectiveSrc}
      alt={alt}
      {...props}
    />
  );
};

export default LazyLoadImage;
