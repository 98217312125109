export default function LocationSvg({ style, width = '24', height = '24' }) {
  return (
    <svg
      className={style}
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 24 24'
    >
      <g
        id='Group_226203'
        data-name='Group 226203'
        transform='translate(-255 -815)'
      >
        <rect
          id='Rectangle_24544'
          data-name='Rectangle 24544'
          width='24'
          height='24'
          transform='translate(255 815)'
          fill='none'
          opacity='0'
        />
        <path
          id='Icon_material-location-on'
          data-name='Icon material-location-on'
          d='M16.726,3A9.219,9.219,0,0,0,7.5,12.226C7.5,19.145,12.113,27,16.726,27s9.226-7.854,9.226-14.773A9.219,9.219,0,0,0,16.726,3Zm0,12.521a3.295,3.295,0,1,1,3.295-3.295A3.3,3.3,0,0,1,16.726,15.521Z'
          transform='translate(250.274 812)'
        />
      </g>
    </svg>
  );
}
