export default function InstagramSvg({
  style,
  width = '1.5rem',
  height = '1.5rem',
}) {
  return (
    <svg
      className={style}
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 24 24'
    >
      <g
        id='Icon_feather-instagram'
        data-name='Icon feather-instagram'
        transform='translate(-2 -1.998)'
      >
        <path
          id='Subtraction_4'
          data-name='Subtraction 4'
          d='M17.538,24H6.462A6.469,6.469,0,0,1,0,17.541V6.461A6.469,6.469,0,0,1,6.462,0H17.538A6.469,6.469,0,0,1,24,6.461v11.08A6.469,6.469,0,0,1,17.538,24ZM12.049,6.6a5.353,5.353,0,1,0,5.295,4.566,5.364,5.364,0,0,0-4.508-4.512A5.59,5.59,0,0,0,12.049,6.6Zm6.043-1.615a.924.924,0,1,0,.926.922A.923.923,0,0,0,18.092,4.986ZM12.049,15.459a3.508,3.508,0,0,1,0-7.016,3.389,3.389,0,0,1,.516.041,3.508,3.508,0,0,1,1.061,6.6A3.533,3.533,0,0,1,12.049,15.459Z'
          transform='translate(2 1.998)'
        />
      </g>
    </svg>
  );
}
