import AI_Img from '@images/ourPartners/become-partner/AI.png';
import AccountingImg from '@images/ourPartners/become-partner/Accounting.png';
import SupportImg from '@images/ourPartners/become-partner/Support.png';
import AI_Img_placeholder from '@images/ourPartners/become-partner/AI_placeholder.png';
import AccountingImg_placeholder from '@images/ourPartners/become-partner/Accounting_placeholder.png';
import SupportImg_placeholder from '@images/ourPartners/become-partner/Support_placeholder.png';

export const data = [
  {
    title: 'Access to Cutting-Edge Technologies',
    discretion: `Stay ahead of the curve with exclusive access to our advanced property 
        management solutions powered by AI and IoT technologies.`,
    image: AI_Img,
    imagePlaceholder: AI_Img_placeholder,
  },
  {
    title: 'Dedicated Support',
    discretion: `Experience personalized and prompt support from our expert team, ensuring a seamless and efficient partnership.`,
    image: SupportImg,
    imagePlaceholder: SupportImg_placeholder,
  },
  {
    title: 'Collaborative Growth',
    discretion: `Join a collaborative ecosystem where mutual growth is a priority. 
        Benefit from joint marketing initiatives, shared successes, and a network of industry leaders.`,
    image: AccountingImg,
    imagePlaceholder: AccountingImg_placeholder,
  },
];
