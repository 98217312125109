import { HashLink } from 'react-router-hash-link';
import LazyLoadImage from './LazyLoadImage';

export default function ServicesCard({
  image,
  title,
  description,
  path,
  textColor = 'text-primary_4',
  imagePlaceholder,
}) {
  return (
    <HashLink to={path}>
      <div className='max-w-[48.75rem]'>
        <LazyLoadImage
          alt={title}
          src={image}
          placeholder={imagePlaceholder}
          placeholderClassName={'w-[48.75rem] max-h-[35.438rem]'}
          className={'max-h-[35.438rem] w-[48.75rem]'}
        />
        <h3 className={`${textColor} mt-6 laptopS:mt-10 text-custom-16 laptopS:text-custom-32 font-custom-600`}>
          {title}
        </h3>
        <p className={`${textColor} mt-2 laptopS:mt-4 text-custom-12 laptopS:text-custom-16`}> {description}</p>
      </div>
    </HashLink>
  );
}
