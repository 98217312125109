import companyOverview from '@images/home/about-us/companyOverview.png';
import missionAndValues from '@images/home/about-us/missionAndValues.png';
import meetTheExperts from '@images/home/about-us/meetTheExperts.png';
import technologicalAdvancements from '@images/home/about-us/technologicalAdvancements.png';

import companyOverview_placeholder from '@images/home/about-us/companyOverview_placeholder.png';
import missionAndValues_placeholder from '@images/home/about-us/missionAndValues_placeholder.png';
import meetTheExperts_placeholder from '@images/home/about-us/meetTheExperts_placeholder.png';
import technologicalAdvancements_placeholder from '@images/home/about-us/technologicalAdvancements_placeholder.png';

export const aboutData = [
    {
      src: companyOverview,
      placeholder: companyOverview_placeholder,
      alt: 'Company Overview',
      title: 'Company Overview',
      description:
        'Unveiling the foundation of PropManage, rooted in Toronto, Ontario, where innovation meets a thriving community of properties ready for the future.',
      style: 'h-[9.4375rem] laptopS:h-[19.125rem] laptopS:min-w-[41.438rem]',
    },
    {
      src: missionAndValues,
      placeholder: missionAndValues_placeholder,
      alt: 'Mission and Values',
      title: 'Mission and Values',
      description:
        "At PropManage, our mission is not just property management – it's a commitment to community well-being, excellence, and integrity, shaping the future of real estate.",
      style: 'h-[9.4375rem] laptopS:h-[19.125rem] laptopS:min-w-[41.438rem]',
    },
    {
      src: meetTheExperts,
      placeholder: meetTheExperts_placeholder,
      alt: 'Meet The Experts',
      title: 'Meet the Experts',
      description:
        "Behind PropManage's success are experienced, licensed property managers dedicated to maintaining high standards and protecting the value of every property we manage.",
      style:
        'w-full h-[22.94rem] laptopS:h-auto max-h-[40.25rem] laptopS:w-[21.1875rem]',
    },
    {
      src: technologicalAdvancements,
      placeholder: technologicalAdvancements_placeholder,
      alt: 'Technological Advancements',
      title: 'Technological Advancements',
      description:
        'PropManage pioneers property management with cutting-edge technology, revolutionizing the industry and empowering building owners for a bold future.',
      style: 'h-[22.94rem] laptopS:h-auto max-h-[40.25rem] laptopS:w-[41.438rem]',
    },
  ];