import ContactUs from '@components/puplic-pages/shared/ContactUs';
import BecomePartner from '@components/puplic-pages/OurPartners/BecomePartner/index';
import Hero from '@components/puplic-pages/OurPartners/Hero/index';
import OurTrustedPartnerships from '@components/puplic-pages/OurPartners/OurTrustedPartnerships/index';
import TrustedByThousands from '@components/puplic-pages/OurPartners/TrustedByThousands/index';
import WhyPropmanage from '@components/puplic-pages/OurPartners/WhyPropmanage/index';
import PublicLayout from '@layouts/PublicLayout/index';

export default function Partners() {
  return (
    <PublicLayout>
      <Hero />
      <OurTrustedPartnerships />
      <TrustedByThousands />
      <BecomePartner />
      <WhyPropmanage />
      <ContactUs />
    </PublicLayout>
  );
}
