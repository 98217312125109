export default function Facebook({
  style,
  width = '1.5rem',
  height = '1.5rem',
}) {
  return (
    <svg
      className={style}
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 24 24'
    >
      <g
        id='Group_228245'
        data-name='Group 228245'
        transform='translate(-899 -3232)'
      >
        <rect
          id='Rectangle_58972'
          data-name='Rectangle 58972'
          width='24'
          height='24'
          transform='translate(899 3232)'
          fill='none'
          opacity='0'
        />
        <path
          id='Icon_awesome-facebook-f'
          data-name='Icon awesome-facebook-f'
          d='M13.621,13.5l.667-4.343H10.12V6.338a2.172,2.172,0,0,1,2.449-2.347h1.895V.293A23.105,23.105,0,0,0,11.1,0C7.668,0,5.425,2.08,5.425,5.846v3.31H1.609V13.5H5.425V24h4.7V13.5Z'
          transform='translate(902.964 3232)'
        />
      </g>
    </svg>
  );
}
