import mapImage from '@images/map.png';
import CustomInput from './custom-inputs/CustomInput';
import CustomTextarea from './custom-inputs/CustomTextarea';
import EmailSvg from '@ui/svg/EmailSvg';
import LocationSvg from '@ui/svg/LocationSvg';
import PhoneSvg from '@ui/svg/PhoneSvg';
import FilledButton from './buttons/FilledButton';
import ArrowRightSvg from './svg/ArrowRightSvg';

export default function ContactUsForm() {
  return (
    <div className='flex w-full max-w-[108.5rem] flex-col justify-center gap-8 px-6 laptopS:flex-row laptopS:gap-20 laptopS:px-0'>
      <div className='max-w-[47.188rem]'>
        <h3 className='text-custom-36 font-custom-600 text-primary_1 laptopS:text-custom-48'>
          Let's Talk
        </h3>
        <p className='mt-4 text-custom-12 text-primary_2 laptopS:text-custom-16'>
          Feel free to reach out to us for any inquiries, collaborations, or
          assistance. Our dedicated team is here to support you. Connect with us
          through the following channels
        </p>

        <div className='my-6 flex flex-col gap-8 laptopS:my-[3.5rem]'>
          <div className='flex gap-6'>
            <div>
              <LocationSvg style={'fill-primary_1'} />
            </div>
            <div>
              <p className='text-custom-16 font-custom-600 text-primary_2 laptopS:text-custom-24'>
                Location
              </p>
              <p className='mt-1 text-custom-12 text-primary_2 laptopS:text-custom-16'>
                19 Thorne Street, Suite 110, Cambridge, Ontario, Canada, N1R 1S3
              </p>
            </div>
          </div>
          <div className='flex gap-6'>
            <EmailSvg style={'fill-primary_1'} />
            <div>
              <p className='text-custom-16 font-custom-600 text-primary_2 laptopS:text-custom-24'>
                E-mail
              </p>
              <p className='mt-1 text-custom-12 text-primary_2 laptopS:text-custom-16'>
                info@propmanage.com
              </p>
            </div>
          </div>
          <div className='flex gap-6'>
            <PhoneSvg style={'fill-primary_1'} />
            <div>
              <p className='text-custom-16 font-custom-600 text-primary_2 laptopS:text-custom-24'>
                Phone
              </p>
              <p className='mt-1 text-custom-12 text-primary_2 laptopS:text-custom-16'>
                Ontario Coorporation # 1000089227
              </p>
            </div>
          </div>
        </div>

        <div>
          <img
            className='block h-auto w-[47.188rem] laptopS:h-[17.625rem]'
            src={mapImage}
            alt='map'
          />
        </div>
      </div>

      <form className='flex w-full max-w-[50.313rem] flex-col gap-6 laptopS:mt-[5.188rem]'>
        <CustomInput
          shape={1}
          className='h-10 w-full rounded-custom-8 px-4 text-custom-14 laptopS:h-[5rem] laptopS:rounded-custom-53 laptopS:px-10 laptopS:text-custom-24'
          placeholder='Name'
          type='text'
        />
        <CustomInput
          shape={1}
          className='h-10 w-full rounded-custom-8 px-4 text-custom-14 laptopS:h-[5rem] laptopS:rounded-custom-53 laptopS:px-10 laptopS:text-custom-24'
          placeholder='Email'
          type='text'
        />
        <CustomInput
          shape={1}
          className='h-10 w-full rounded-custom-8 px-4 text-custom-14 laptopS:h-[5rem] laptopS:rounded-custom-53 laptopS:px-10 laptopS:text-custom-24'
          placeholder='Subject'
          type='text'
        />
        <CustomTextarea
          shape={1}
          className='h-[7.5rem] w-full rounded-custom-8 px-4 pt-[.625rem] text-custom-14 laptopS:h-[15rem] laptopS:rounded-custom-24 laptopS:px-10 laptopS:pt-6 laptopS:text-custom-24'
          placeholder='Type your message here'
        />
        <div className='self-end'>
          <FilledButton
            className={
              'rounded-custom-44 text-custom-12 laptopS:text-custom-20'
            }
            width='w-[5.5625rem] laptopS:w-[17.25rem] laptopS:mt-[2rem]'
            height='h-10 laptopS:h-[5rem]'
            text='Send'
            path='#contact-us'
            icon={<ArrowRightSvg style='fill-[#292d32] ms-2' />} //
          />
        </div>
      </form>
    </div>
  );
}
