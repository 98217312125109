import ProgressBar from '@components/ui/ProgressBar';
import PropManageLogoSvg from '@components/ui/svg/PropManageLogoSvg';
import BgLoadingSignUpPage from '@images/registration/BgLoadingSignUpPage.jpg';
import { useEffect } from 'react';

export default function LoadingPage({ progress, setProgress }) {
  const estimatedTime = 2000; // 2 seconds estimate for the request

  useEffect(() => {
    let interval;
    const startTime = new Date();

    interval = setInterval(() => {
      setProgress((oldProgress) => {
        const elapsedTime = new Date() - startTime;
        const progress = (elapsedTime / estimatedTime) * 100;
        return progress < 90 ? progress : 90;
      });
    }, 100);

    setTimeout(() => {
      clearInterval(interval);
      setProgress(100);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  return (
    <main className='relative min-h-screen px-6 laptopS:px-0'>
      <div className='flex justify-center'>
        <PropManageLogoSvg className={'mt-20 h-[3.5rem] w-[9.851rem]'} />
      </div>
      <div className='mt-[28vh] flex flex-col items-center'>
        <div className='h-[8.688rem]'>
          <h1 className='relative text-center text-custom-32 font-custom-600 text-primary_2 laptopS:text-custom-48'>
            Please wait a moment while we create your free
            <span
              className='font-rage-italic absolute left-1/2 top-[4.8rem] hidden -translate-x-1/2 -translate-y-1/2 transform text-[6.25rem] 
          font-normal text-primary_1 laptopS:block'
            >
              trial account
            </span>
          </h1>
        </div>
        <p className='max-w-[88.875rem] text-center text-custom-16 laptopS:text-custom-18 text-primary_2'>
          This process may take a few seconds.
        </p>
        <ProgressBar className={'mt-6'} progress={progress} />
      </div>
      <img
        className='absolute bottom-0 right-0 -z-10 hidden max-h-[39.75rem] max-w-[40rem] laptopS:block'
        src={BgLoadingSignUpPage}
        alt='Bg Loading Sign Up Page'
      />
    </main>
  );
}
