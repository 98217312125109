import PublicLayout from '@layouts/PublicLayout/index';
import Hero from '@components/puplic-pages/Home/Hero/index';
import RevolutionizingProperty from '@components/puplic-pages/Home/RevolutionizingProperty/index';
import AboutUs from '@components/puplic-pages/Home/AboutUs/index';
import TechnologyIntegration from '@components/puplic-pages/Home/TechnologyIntegration/index';
import OurSolutions from '@components/puplic-pages/Home/OurSolutions/index';
import DontWorry from '@components/puplic-pages/Home/DontWorry/index';
import IndustriesServe from '@components/puplic-pages/Home/IndustriesServe/index';
import HowItWorks from '@components/puplic-pages/Home/HowItWorks/index';
import HowItWorksDetails from '@components/puplic-pages/Home/HowItWorksDetails/index';
import ChoosePropmanage from '@components/puplic-pages/Home/ChoosePropmanage/index';
import Pricing from '@components/puplic-pages/Home/Pricing/index';
import WhyPropmanage from '@components/puplic-pages/Home/WhyPropmanage/index';
import ContactUs from '@components/puplic-pages/shared/ContactUs/index';

export default function HomeComp() {
  return (
    <PublicLayout>
      <Hero />
      <RevolutionizingProperty />
      <AboutUs />
      <TechnologyIntegration />
      <OurSolutions />
      <DontWorry />
      <IndustriesServe />
      <HowItWorks />
      <HowItWorksDetails />
      <ChoosePropmanage />
      <Pricing />
      <WhyPropmanage />
      <ContactUs />
    </PublicLayout>
  );
}
