import LazyLoadImage from '@components/ui/LazyLoadImage';
import HowItWorksImg from '@images/home/how-It-works/HowItWorks.png';
import HowItWorksImg_placeholder from '@images/home/how-It-works/HowItWorks_placeholder.png';

export default function HowItWorks() {
  return (
    <section className='flex min-h-screen laptopS:items-end bg-primary_2 px-4 laptopS:px-0'>
      <div className='flex w-full flex-col laptopS:flex-row justify-between'>
        <div className='flex mt-[3rem] laptopS:mt-0 laptopS:ms-[15.313rem] max-w-[55rem] flex-col laptopS:justify-center'>
          <h1 className='laptopS:text-center text-custom-36 laptopS:text-custom-96 font-custom-600 text-primary_4'>
            How It<span className='text-primary_1'> Works</span>
          </h1>
          <p className='mt-[2rem] laptopS:text-center text-custom-12 laptopS:text-custom-24 text-primary_4'>
            Explore the simplicity of PropManage's advanced property management
            system, effortlessly guiding you through each transformative step.
          </p>
        </div>
        <LazyLoadImage
          alt={'building'}
          src={HowItWorksImg}
          placeholder={HowItWorksImg_placeholder}
          placeholderClassName={
            'max-w-[48.813rem] max-h-[51.875rem] h-auto w-full'
          }
          className={'max-h-[75%] laptopS:max-h-[51.875rem] max-w-[48.813rem]'}
        />
      </div>
    </section>
  );
}
