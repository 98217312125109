import ResetPasswordComponent from '@components/puplic-pages/ResetPassword/index';
import PublicLayout from '@layouts/PublicLayout/index';

export default function ResetPassword() {
  return (
    <PublicLayout isFooter={false} isHeader={false}>
      <ResetPasswordComponent />
    </PublicLayout>
  );
}
