export const cardsInfo = [
  {
    title: 'AI Blueprint Analysis',
    description:
      "Effortless Predictions: Our AI analyzes blueprints, predicting issues before they emerge. Elevate your property's potential with PropManage.",
  },
  {
    title: 'IoT Sensors',
    description:
      'Real-time Insights: Sensors gather property details for automated planning. Supplier info, materials, and timelines – all at your fingertips.',
  },
  {
    title: 'Proactive Alerts',
    description:
      'Swift Issue Resolution: IoT sensors alert managers instantly, ensuring proactive, efficient problem-solving.',
  },
  {
    title: 'Future-Ready Solutions',
    description:
      "Innovative Technology: Immerse yourself in a future where issues are addressed before they arise, thanks to PropManage's cutting-edge solutions.",
  },
];
