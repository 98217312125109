export default function Switcher({ fields, value, onChange, className }) {
  return (
    <div className='flex rounded-[46px] bg-secondary_2 p-2'>
      {fields?.map((item, index) => {
        return (
          <button
            onClick={() => onChange(item)}
            key={item}
            className={`${item === value ? 'bg-primary_1' : ''} ${className} flex select-none items-center justify-center rounded-custom-44  text-primary_2 transition-all duration-500 ease-in-out`}
          >
            {item}
          </button>
        );
      })}
    </div>
  );
}
