
export default function ArrowSelect({
  className = 'fill-[#323232]',
  width = '1.25rem',
  height = '1.25rem',
}) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      className={className}
      viewBox='0 0 20 20'
    >
      <g
        id='Group_224047'
        data-name='Group 224047'
        transform='translate(-20 -75)'
      >
        <g
          id='Group_224046'
          data-name='Group 224046'
          transform='translate(-43 3)'
        >
          <rect
            id='Rectangle_56527'
            data-name='Rectangle 56527'
            width='20'
            height='20'
            transform='translate(63 72)'
            fill='none'
          />
          <path
            id='Path'
            d='M.142.142A.484.484,0,0,1,.772.095L.826.142,5,4.315,9.174.142A.484.484,0,0,1,9.8.095l.054.047a.484.484,0,0,1,.047.63L9.858.826,5.342,5.342a.484.484,0,0,1-.63.047l-.054-.047L.142.826A.484.484,0,0,1,.142.142Z'
            transform='translate(68 79.258)'
          />
        </g>
      </g>
    </svg>
  );
}
