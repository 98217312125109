import LazyLoadImage from '@components/ui/LazyLoadImage';
import WhyPropmanageImage from '@images/ourPartners/why-popmanage/WhyPropmanage.png';
import WhyPropmanageImage_placeholder from '@images/ourPartners/why-popmanage/WhyPropmanage_placeholder.png';

export default function WhyPropmanage() {
  return (
    <section className='min-h-screen bg-primary_4 px-6 laptopS:px-0 laptopS:pb-[6.125rem] laptopS:pt-[11rem]'>
      <div className='flex flex-col items-center'>
        <div className='h-[10.813rem] mobileM:h-[8rem] laptopS:h-[10.813rem]'>
          <h1 className='relative z-10 text-center text-custom-40 font-custom-600 text-primary_2 laptopS:text-custom-80'>
            Why Propmanage?
            <span
              className='font-rage-italic laptopS:text-custom-100 absolute left-1/2 top-[8rem] -z-10 -translate-x-1/2 -translate-y-1/2 transform text-custom-80 
              font-normal text-primary_1 mobileM:top-[4.5rem] laptopS:top-[7.375rem]'
            >
              Solution
            </span>
          </h1>
        </div>
        <p className='max-w-[88.875rem] text-center text-custom-12 laptopS:text-custom-18 text-primary_2'>
          PropManage stands out as your premier property management solution,
          offering a seamless blend of advanced technology, proactive
          maintenance strategies, and a user-friendly interface. With
          comprehensive supplier management, tailored pricing plans, and
          dedicated customer support, PropManage ensures your property
          management journey is not just efficient but future-ready.
        </p>
        <div className='mt-[2.125rem] laptopS:mt-[3.5rem]'>
          <LazyLoadImage
            alt={'Why Propmanage'}
            src={WhyPropmanageImage}
            placeholder={WhyPropmanageImage_placeholder}
            placeholderClassName={'max-h-[30.5rem] w-[38.313rem]'}
            className={'max-h-[30.5rem] w-[38.313rem]'}
          />
        </div>
      </div>
    </section>
  );
}
