import CustomLink from '@components/ui/CustomLink';
import ErrorFormik from '@components/ui/ErrorFormik';
import FilledButton from '@components/ui/buttons/FilledButton';
import CustomInput from '@components/ui/custom-inputs/CustomInput';

export default function Step1({
  handleNext,
  values,
  errors,
  touched,
  handleBlur,
  handleChange,
}) {
  return (
    <div className='w-full max-w-[35rem]'>
      <div className=''>
        <h3 className='text-custom-24 font-custom-500 text-primary_2'>
          Get started for free!
        </h3>
        <p className='mt-4 text-custom-16 text-primary_2'>
          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
          nonumy eirmod tempor invidunt ut labore et dolore magna
        </p>
      </div>
      <div className='mt-10 flex flex-col gap-6'>
        <div>
          <CustomInput
            id='fullName'
            name='fullName'
            value={values?.fullName}
            onChange={handleChange}
            onBlur={handleBlur}
            className='h-[4.5rem] w-full'
            shape={2}
            placeholder='Full Name'
            type='text'
          />
          <ErrorFormik
            isError={errors?.fullName}
            isTouched={touched?.fullName}
            error={errors?.fullName}
          />
        </div>
        <div>
          <CustomInput
            shape={2}
            id='email'
            name='email'
            value={values?.email}
            onChange={handleChange}
            onBlur={handleBlur}
            className='h-[4.5rem] w-full'
            placeholder='Business email'
            type='email'
          />
          <ErrorFormik
            isError={errors?.email}
            isTouched={touched?.email}
            error={errors?.email}
          />
        </div>

        <FilledButton
          isButton
          onClick={handleNext}
          text='Next'
          width='w-full'
          height='h-[4rem]'
          className='mt-4 rounded-custom-16 text-custom-20'
        />

        <p>
          Already have an account?{' '}
          <CustomLink path={'/log-in'}>
            <span className='text-custom-14 text-primary_1'>Login</span>
          </CustomLink>
        </p>
      </div>
    </div>
  );
}
