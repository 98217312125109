import LazyLoadImage from '@components/ui/LazyLoadImage';
import OurSolutionsImg from '@images/ourServices/ourSolutions/OurSolutions.png';
import OurSolutionsImg_placeholder from '@images/ourServices/ourSolutions/OurSolutions_placeholder.png';

export default function OurSolutions() {
  return (
    <section
      id='our-services-OurSolutions'
      className='min-h-screen bg-primary_2'
    >
      <div className='flex flex-col laptopS:flex-row w-full'>
        <div className='px-auto flex min-w-[50%] flex-col items-center justify-center'>
          <div className='flex max-w-[40.688rem] flex-col justify-center my-10 laptopS:my-0 px-4 laptopS:px-0'>
            <h1 className='text-custom-36 laptopS:text-custom-96 font-custom-600 text-primary_4'>
              Our <span className='text-primary_1'> Solutions</span>
            </h1>
            <p className='mt-4 laptopS:mt-[2rem] text-custom-12 laptopS:text-custom-24 text-primary_4'>
              Elevate your property management experience with our innovative
              solutions tailored to your unique needs.
            </p>
          </div>
        </div>
        <LazyLoadImage
          alt={'building'}
          src={OurSolutionsImg}
          placeholder={OurSolutionsImg_placeholder}
          placeholderClassName={'h-[75vh] laptopS:h-screen min-w-[50%]'}
          className={'h-[75vh] laptopS:h-screen min-w-[50%]'}
        />
      </div>
    </section>
  );
}
