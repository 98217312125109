export default function Card({ title, description }) {
  return (
    <div className='w-full h-[9.375rem] laptopS:h-[15.75rem] laptopS:w-[23.75rem] rounded-custom-20 laptopS:rounded-custom-40 border border-solid border-primary_4'>
      <div className='p-6 laptopS:p-10 text-primary_4'>
        <h3 className='mb-4 text-custom-16 laptopS:text-custom-24 font-custom-600'>{title}</h3>
        <p className='text-[0.75rem] laptopS:text-custom-16'>{description}</p>
      </div>
    </div>
  );
}
