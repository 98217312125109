export default function ArrowLeftSvg({
  style = 'fill-[#292d32]',
  width = '1.5rem',
  height = '1.5rem',
}) {
  return (
    <svg
      className={style}
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 24 24'
    >
      <g
        id='vuesax_outline_arrow-up'
        data-name='vuesax/outline/arrow-up'
        transform='translate(772)'
      >
        <g id='arrow-up' transform='translate(-772)'>
          <g
            id='Group_246045'
            data-name='Group 246045'
            transform='translate(21.249 5.184) rotate(90)'
          >
            <path
              id='Vector'
              d='M12.887,0a.742.742,0,0,0-.53.22L6.818,5.76,1.277.22A.75.75,0,0,0,.218,1.28l6.07,6.07a.754.754,0,0,0,1.06,0l6.07-6.07a.754.754,0,0,0,0-1.06A.725.725,0,0,0,12.887,0Z'
              transform='translate(0 10.93)'
            />
            <path
              id='Vector-2'
              data-name='Vector'
              d='M.75,0A.755.755,0,0,0,0,.75V17.58a.755.755,0,0,0,.75.75.755.755,0,0,0,.75-.75V.75A.755.755,0,0,0,.75,0Z'
              transform='translate(6.067 0)'
            />
          </g>
          <path
            id='Vector-3'
            data-name='Vector'
            d='M24,0H0V24H24Z'
            fill='none'
            opacity='0'
          />
        </g>
      </g>
    </svg>
  );
}
