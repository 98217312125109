import SolutionView from '@components/puplic-pages/OurServices/SolutionsSteppers/SolutionsView';
import FilledButton from '@components/ui/buttons/FilledButton';
import ArrowLeftSvg from '@components/ui/svg/ArrowLeftSvg';
import ArrowRightSvg from '@components/ui/svg/ArrowRightSvg';

export default function MobileStepper({
  data,
  activeIndex,
  setActiveIndex,
  RenderImage,
  handleNext,
  handlePrev,
}) {

  return (
    <section
      id=''
      className='mt-14 flex flex-col items-center overflow-x-hidden bg-primary_4 px-6'
    >
      <div className='w-full'>
        <div>
          {data[activeIndex] && (
            <div key={data[activeIndex].title + 'index'}>
              <RenderImage item={data[activeIndex]} />
            </div>
          )}
        </div>
        <div className='mt-4 flex flex-col justify-center'>
          <div className='flex items-center justify-center'>
            <SolutionView item={data[activeIndex]} />
          </div>
          <div
            className={`mb-14 mt-8 flex w-full ${activeIndex > 0 ? 'justify-between' : 'justify-end'}`}
          >
            {activeIndex > 0 && (
              <div className='flex items-center gap-4'>
                <FilledButton
                  text={<ArrowLeftSvg />}
                  isButton
                  onClick={handlePrev}
                  width='w-[2.5rem]'
                  height='h-[2.5rem]'
                  className={
                    'rounded-custom-44'
                  }
                />
                <p className='text-custom-14 font-custom-600'>Previous</p>
              </div>
            )}
            {activeIndex + 1 < data.length && (
              <div className='flex items-center gap-4'>
                <p className='text-custom-14 font-custom-600'>Next </p>
                <FilledButton
                  text={<ArrowRightSvg />}
                  isButton
                  onClick={handleNext}
                  width='w-[2.5rem]'
                  height='h-[2.5rem]'
                  className={
                    'rounded-custom-44'
                  }
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}
