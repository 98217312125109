import React, { useState } from 'react';
import Accordion from '@components/ui/Accordion';
import LazyLoadImage from '@components/ui/LazyLoadImage';
import { XyzTransition } from '@animxyz/react';

export default function Stepper({data}) {
  const [activeItem, setActiveItem] = useState(data[0]);

  const RenderImage = ({ item }) => {
    return (
      <div className='h-[43.5rem] w-[43.938rem]'>
        <LazyLoadImage
          isLoad={true}
          alt={item.title}
          src={item.image}
          placeholder={item.imagePlaceholder}
          placeholderClassName={
            'square w-[43.938rem] h-[43.5rem] rounded-custom-40'
          }
          className={
            'square h-[43.5rem] w-[43.938rem] rounded-custom-40 object-cover'
          }
        />
      </div>
    );
  };

  return (
    <div className='mt-4 flex gap-[3rem]'>
      <div className='customScroll h-screen max-w-[55rem] overflow-x-hidden overflow-y-scroll laptopS:h-[43.5rem]'>
        <Accordion items={data} setActiveItem={setActiveItem} />
      </div>
      <XyzTransition
        className='hidden laptopS:block'
        appear
        duration='auto'
        xyz='fade'
      >
        {data.map(
          (item, index) =>
            activeItem.index === index && (
              <div key={item.title + index}>
                <RenderImage item={item} />
              </div>
            ),
        )}
      </XyzTransition>
    </div>
  );
}
