export default function Step({ number, title, discretion }) {
  return (
    <div className='flex max-w-[9.75rem] laptopS:max-w-[18.75rem] flex-col items-center'>
      <div
        className='flex w-10 h-10 laptopS:h-[5rem] laptopS:w-[5rem] select-none items-center justify-center
       rounded-full border border-solid border-primary_1 text-custom-16 laptopS:text-custom-32 font-custom-600 text-primary_1'
      >
        {number}
      </div>
      <h3 className='mt-[.625rem] laptopS:mt-8 text-center text-custom-14 laptopS:text-custom-24 font-custom-600 text-primary_2'>
        {title}
      </h3>
      <p className='mt-1 laptopS:mt-4 text-center text-custom-12 laptopS:text-custom-16 text-primary_2'>
        {discretion}
      </p>
    </div>
  );
}
