import { RotatingLines } from 'react-loader-spinner';

export default function Loader({ isFullScreen = true, style, width = '60' }) {
  return (
    <div
      className={`${style} flex items-center justify-center ${
        isFullScreen && 'h-screen'
      } bg-opacity-10`}
    >
      <RotatingLines
        strokeColor={`#ffc723`}
        strokeWidth='3'
        animationDuration='1.5'
        width={width}
        visible={true}
      />
    </div>
  );
}
