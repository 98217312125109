import AImg from '@images/ourServices/commercial/A.png';
import BImg from '@images/ourServices/commercial/C.png';
import CImg from '@images/ourServices/commercial/B.png';
import DImg from '@images/ourServices/commercial/D.png';
import AImg_placeholder from '@images/ourServices/commercial/A_placeholder.png';
import BImg_placeholder from '@images/ourServices/commercial/C_placeholder.png';
import CImg_placeholder from '@images/ourServices/commercial/B_placeholder.png';
import DImg_placeholder from '@images/ourServices/commercial/D_placeholder.png';

export const data = [
  {
    title: 'Lorem ipsum dolor sit amet',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco labori',
    path: '',
    image: AImg,
    imagePlaceholder: AImg_placeholder,
  },
  {
    title: 'Lorem ipsum dolor sit amet',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco labori',
    path: '',
    image: BImg,
    imagePlaceholder: BImg_placeholder,
  },
  {
    title: 'Lorem ipsum dolor sit amet',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco labori',
    path: '',
    image: CImg,
    imagePlaceholder: CImg_placeholder,
  },
  {
    title: 'Lorem ipsum dolor sit amet',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco labori',
    path: '',
    image: DImg,
    imagePlaceholder: DImg_placeholder,
  },
];
