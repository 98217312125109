
export default function Hero() {
  return (
    <section className='flex min-h-screen flex-col items-center justify-end bg-ourServicesImg bg-cover bg-no-repeat'>
      <div className='mx-auto mb-[8.75rem] max-w-[102.5rem] px-6 tabletM:w-full laptopS:px-0'>
        <h1 className='text-custom-36 font-custom-600 text-primary_4 laptopS:text-custom-88'>
          Our <span className='text-primary_1'>Services</span>
        </h1>
        <p className='mt-6 max-w-[40.625rem] text-custom-16 text-primary_4 laptopS:text-custom-24'>
          Our product is an AI SaaS platform in the building industry that uses
          AI to analyze and give quick results.
        </p>
      </div>
    </section>
  );
}
