import LazyLoadImage from '@components/ui/LazyLoadImage';
import { HashLink } from 'react-router-hash-link';

export default function Box({ title, items }) {
  return (
    <div className='flex flex-col laptopS:flex-row rounded-custom-40 border border-solid border-secondary_3'>
      <div className='flex h-[48px] laptopS:h-auto w-full laptopS:w-[5.063rem] items-center justify-center rounded-t-custom-40 laptopS:rounded-s-custom-40 laptopS:rounded-tr-none bg-primary_1'>
        <p className='laptopS:-rotate-90 select-none text-custom-24 font-custom-600 text-primary_2'>
          {title}
        </p>
      </div>
      <div className='flex flex-wrap w-full items-center justify-around p-6 gap-10 laptopS:gap-0 laptopS:p-12'>
        {items?.map((item, index) => {
          return (
            <HashLink smooth key={item.name + index} to={item.path || ''} className='w-full laptopS:w-auto laptopS:mb-0'>
              <div className='flex flex-col items-center gap-6'>
                <div>
                  <LazyLoadImage
                    alt={item.name}
                    src={item.image}
                    placeholder={item.imagePlaceholder}
                    placeholderClassName='min-w-[10.688rem] min-h-[9.375rem]'
                    className='w-full h-auto'
                  />
                </div>
                <p className='text-custom-16 text-primary_2 text-center'>{item.name}</p>
              </div>
            </HashLink>
          );
        })}
      </div>
    </div>
  );
}
