import CustomLink from '@components/ui/CustomLink';
import FilledButton from '@components/ui/buttons/FilledButton';
import XSvg from '@components/ui/svg/XSvg';
import { XyzTransition } from '@animxyz/react';
import PropManageLogo from '@images/PropManage-logo.png';
import VerifyCodeInput from '@components/ui/custom-inputs/VerifyCodeInput';
import { useFormik } from 'formik';
import ErrorFormik from '@components/ui/ErrorFormik';
import useToastNotifications from '@hooks/useToastNotifications';
import RegistrationSidebar from '../RegistrationSidebar';

export default function VerificationCodeComponent({
  verificationMutate,
  resendMutate,
  initialValues,
  validationSchema,
  email = '',
}) {
  const { notifyError } = useToastNotifications();

  if (!email) {
    notifyError('Something went wrong, please try later!');
  }

  const { values, errors, touched, handleSubmit, setFieldValue } = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: onSubmitVerification,
  });

  function onSubmitVerification() {
    verificationMutate({
      ...values,
    });
  }

  function resendCode() {
    resendMutate({
      email: values.email,
    });
  }

  return (
    <main className='flex min-h-screen justify-center laptopS:overflow-hidden'>
      <RegistrationSidebar />
      <XyzTransition appear xyz='fade down-50% duration-5'>
        <div className='mx-6 flex flex-col items-center justify-center laptopS:mx-0 laptopS:w-1/2 '>
          <div className='flex my-10 w-full items-center justify-between laptopS:hidden'>
            <CustomLink className='' path={'/'}>
              <img
                className='h-[3.5rem] w-[9.813rem]'
                src={PropManageLogo}
                alt='prop manageLogo'
              />
            </CustomLink>

            <div className='flex items-center justify-center'>
              <CustomLink path={'/'}>
                <XSvg className='h-8 w-8 fill-primary_1' />
              </CustomLink>
            </div>
          </div>
          <div>
            <div className='max-w-[35rem]'>
              <h3 className='text-custom-24 font-custom-500 text-primary_2'>
                Verify the code
              </h3>
              <p className='mt-4 text-custom-16 text-primary_2'>
                A four-digit code has been sent to your email {email}
              </p>
            </div>
            <form
              className=' mt-10 flex w-full max-w-[35rem] flex-col gap-6'
              onSubmit={handleSubmit}
            >
              <VerifyCodeInput
                length={6}
                onComplete={(value) => setFieldValue('code', value)}
              />
              <ErrorFormik
                isError={errors?.code}
                isTouched={touched?.code}
                error={errors?.code}
              />
              <FilledButton
                isButton
                buttonType='submit'
                text='Verify'
                width='w-full'
                height='h-[4rem]'
                className='mt-4 rounded-custom-16 text-custom-20'
              />
              <p>
                Didn't receive the code?{' '}
                <button
                  type='button'
                  onClick={() => resendCode()}
                  className='text-custom-14 text-primary_1'
                >
                  Resend
                </button>
              </p>
            </form>
          </div>
        </div>
      </XyzTransition>
    </main>
  );
}
