import React from 'react';
import Step from './Step';
import { data } from './data';

export default function HowItWorksDetails() {
  return (
    <section className='flex laptopS:min-h-screen justify-center bg-primary_4 py-14 laptopS:pb-[9.688rem] laptopS:pt-[13rem]'>
      <div className='flex flex-col items-center'>
        <div className='hidden max-w-[55rem] text-center laptopS:block'>
          <h1 className='text-custom-48 font-custom-600 text-primary_2'>
            How It<span className='text-primary_1'> Works</span>
          </h1>
          <p className='mt-[2rem] text-custom-16 text-primary_2'>
            Explore the simplicity of PropManage's advanced property management
            system, effortlessly guiding you through each transformative step.
          </p>
        </div>

        <div className='flex max-w-[78.75rem] flex-wrap justify-center gap-x-[.9375rem] gap-y-[1.5rem] laptopS:mt-6 laptopS:gap-x-[11.25rem]  laptopS:gap-y-[5.5rem]'>
          {data.map((item, index) => {
            return (
              <Step
                key={item.title}
                number={index + 1}
                title={item.title}
                discretion={item.discretion}
              />
            );
          })}
        </div>
      </div>
    </section>
  );
}
