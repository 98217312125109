import Hr from '@components/ui/Hr';

export default function Card({ title, price, points, button, image }) {
  return (
    <div className='relative min-h-[39.0625rem] min-w-[20.4375rem] rounded-custom-16 laptopS:rounded-custom-40 border border-solid border-secondary_3 laptopS:min-h-[65.625rem] laptopS:min-w-[32.5rem]'>
      <div className='my-6 text-center laptopS:my-[3rem]'>
        <h3 className='text-custom-16 font-custom-600 laptopS:text-custom-24'>
          {title} <span className='text-primary_1'>{price}</span>
        </h3>
      </div>
      <Hr style={'mb-6 laptopS:mb-[3rem]'} />
      <div className='customScroll mx-6 flex max-h-[29.563rem] w-full justify-center overflow-y-auto'>
        <ul className='flex max-w-[17.4375rem] list-outside list-disc flex-col gap-3 text-custom-12 laptopS:max-w-[26.5rem] laptopS:gap-6 laptopS:text-custom-24'>
          {points.map((item) => {
            return <li key={item}>{item}</li>;
          })}
        </ul>
      </div>
      <div className='absolute bottom-[11rem] laptopS:bottom-[15.625rem] left-[30px] laptopS:left-[40px]'>
        {button}
      </div>
      <div className='absolute bottom-[-45px] right-0'>{image}</div>
    </div>
  );
}
