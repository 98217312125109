import Box from './Box';
import { commercialData, residentialData } from './data';

export default function IndustriesServe() {
  return (
    <section className='min-h-screen bg-primary_4 py-14 laptopS:pb-[102px] laptopS:pt-[13rem]'>
      <div className='flex w-full flex-col items-center px-4 laptopS:px-0'>
        <div className='flex flex-col laptopS:items-center justify-center gap-2 laptopS:gap-4'>
          <h1 className='text-custom-36 laptopS:text-custom-48 font-custom-600 text-primary_2'>
            Industries We <span className='text-primary_1'>Serve</span>
          </h1>
          <p className='text-custom-12 laptopS:text-custom-16 text-primary_2'>
            Explore the simplicity of PropManage's advanced property management
            system,
          </p>
        </div>
        <div className='mt-6 laptopS:mt-10 w-full max-w-[102.5rem]'>
          <Box title={'Residential'} items={residentialData} />
        </div>
        <div className='mt-6 laptopS:mt-10 w-full max-w-[102.5rem]'>
          <Box title={'Commercial'} items={commercialData} />
        </div>
      </div>
    </section>
  );
}
