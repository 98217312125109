import FilledButton from '@components/ui/buttons/FilledButton';

export default function Error404() {
  return (
    <div class='flex min-h-screen items-center justify-center bg-gray-100'>
      <div class='text-center'>
        <h1 class='text-9xl font-bold text-primary_1'>
          4<span className='text-primary_2'>0</span>4
        </h1>
        <p class='mt-4 text-5xl font-semibold text-primary_2'>Page not found</p>
        <p class='mt-2 text-3xl text-primary_2'>
          The page you're looking for doesn't exist.
        </p>
        <FilledButton
          path='/'
          onClick={() => console.log('Login')}
          text='Go To Home'
          width='w-[50%]'
          height='h-[4rem]'
          className='mt-5 rounded-custom-16 text-custom-20'
        />
      </div>
    </div>
  );
}
