import CustomLink from '@components/ui/CustomLink';
import FilledButton from '@components/ui/buttons/FilledButton';
import PasswordInput from '@components/ui/custom-inputs/PasswordInput';
import XSvg from '@components/ui/svg/XSvg';
import { XyzTransition } from '@animxyz/react';
import useURLQuery from '@hooks/useURLQuery';
import { usePublicContext } from '@providers/ReactPublicContextProvider';
import useUser from '@hooks/useUser';
import useToastNotifications from '@hooks/useToastNotifications';
import { postResetPasswordQueryFn } from '@queries/publicQueryFns/postQueryFns';
import { useMutation } from '@tanstack/react-query';
import { useEffect } from 'react';
import { resetPasswordForgotSchema } from '@utils/forms-schemas';
import { useFormik } from 'formik';
import ErrorFormik from '@components/ui/ErrorFormik';
import RegistrationSidebar from '@components/ui/RegistrationSidebar';
import PropManageLogo from '@images/PropManage-logo.png';

export default function ResetPasswordComponent() {
  const { setIsLoading } = usePublicContext();
  const { redirectTo, isAuthenticUser } = useUser();
  const { notifyError, notifySuccess } = useToastNotifications();

  const { getQueryParam } = useURLQuery();
  const token = getQueryParam('token');

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: {
        newPassword: '',
        ConfirmPassword: '',
      },
      validationSchema: resetPasswordForgotSchema,
      onSubmit: onSubmit,
    });

  const { mutate, isPending: isLoadingReset } = useMutation({
    mutationFn: postResetPasswordQueryFn,
    onSuccess: handleSuccessResetVerification,
    onError: handleFailureResetVerification,
  });

  useEffect(() => {
    if (isAuthenticUser) {
      redirectTo('/dashboard');
    } else if (!token) {
      redirectTo('/');
    }
  }, []);

  useEffect(() => {
    setIsLoading(isLoadingReset);
  }, [isLoadingReset]);

  function handleSuccessResetVerification(data) {
    setIsLoading(false);
    notifySuccess('Your password has been changed successfully, log in now');
    redirectTo(`/log-in`);
  }

  function handleFailureResetVerification(error) {
    console.error(error);
    notifyError('Something went wrong, please try later!');
  }

  function onSubmit() {
    const token = getQueryParam('token');
    mutate({
      newPassword: values.newPassword,
      token: token,
    });
  }

  return (
    <main className='flex min-h-screen justify-center laptopS:overflow-hidden'>
      <RegistrationSidebar />
      <XyzTransition appear xyz='fade down-50% duration-5'>
        <div className='mx-6 flex flex-col items-center justify-center laptopS:mx-0 laptopS:w-1/2 '>
          <div className='my-10 flex w-full items-center justify-between laptopS:hidden'>
            <CustomLink className='' path={'/'}>
              <img
                className='h-[3.5rem] w-[9.813rem]'
                src={PropManageLogo}
                alt='prop manageLogo'
              />
            </CustomLink>

            <div className='flex items-center justify-center'>
              <CustomLink path={'/'}>
                <XSvg className='h-8 w-8 fill-primary_1' />
              </CustomLink>
            </div>
          </div>

          <div className='max-w-[35rem]'>
            <h3 className='text-custom-24 font-custom-500 text-primary_2'>
              Reset your password
            </h3>
            <p className='mt-4 text-custom-16 text-primary_2'>
              Your new password must be different from previous used passwords
            </p>
          </div>
          <form
            className='mt-10 flex w-full max-w-[35rem] flex-col gap-6'
            onSubmit={handleSubmit}
          >
            <div>
              <PasswordInput
                shape={2}
                id='newPassword'
                name='newPassword'
                value={values?.newPassword}
                onChange={handleChange}
                onBlur={handleBlur}
                className='h-[4.5rem] w-full px-6'
                placeholder='New Password'
              />
              <ErrorFormik
                isError={errors?.newPassword}
                isTouched={touched?.newPassword}
                error={errors?.newPassword}
              />
            </div>
            <div>
              <PasswordInput
                shape={2}
                id='ConfirmPassword'
                name='ConfirmPassword'
                value={values?.ConfirmPassword}
                onChange={handleChange}
                onBlur={handleBlur}
                className='h-[4.5rem] w-full px-6'
                placeholder='Confirm New Password'
              />
              <ErrorFormik
                isError={errors?.ConfirmPassword}
                isTouched={touched?.ConfirmPassword}
                error={errors?.ConfirmPassword}
              />
            </div>
            <FilledButton
              isButton
              buttonType='submit'
              text='Change Password'
              width='w-full'
              height='h-[4rem]'
              className='mt-4 rounded-custom-16 text-custom-20'
            />
          </form>
        </div>
      </XyzTransition>
    </main>
  );
}
