import useToastNotifications from '@hooks/useToastNotifications';
import { usePublicContext } from '@providers/ReactPublicContextProvider';
import { postSignOutQueryFn } from '@queries/publicQueryFns/postQueryFns';
import { useMutation } from '@tanstack/react-query';
import { ROLES, USER_DETAILS } from '@utils/constants';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

/**
 * Custom hook for managing user authentication and user data.
 * @returns {{
 *   userData: object,
 *   setUserData: Function,
 *   updateUserData: Function,
 *   signOut: Function,
 *   isAuthenticUser: Boolean,
 *   redirectTo: Function
 * }}
 */
export default function useUser() {
  // Retrieve user data and navigate function
  const userData = getUserSession();
  const navigate = useNavigate();
  const { notifyError, notifySuccess } = useToastNotifications();
  const { setIsLoading } = usePublicContext();

  const {
    mutate: signOutMutate,
    isPending: isLoadingMutate,
    isError: isErrorMutate,
  } = useMutation({
    mutationFn: postSignOutQueryFn,
    onSuccess(data) {
      setIsLoading(false);
      localStorage.removeItem(USER_DETAILS);
      notifySuccess(data.message);
      redirectTo('/');
    },
    onError(error) {
      console.error(error);
      notifyError('Something went wrong, please try later!');
    },
  });

  /**
   * Retrieves user data from localStorage.
   * @returns {object} User data object.
   */
  function getUserSession() {
    try {
      const storedUserData = localStorage.getItem(USER_DETAILS);
      return storedUserData ? JSON.parse(storedUserData) : null;
    } catch (error) {
      console.error('Error retrieving user data from localStorage:', error);
      return null;
    }
  }

  /**
   * Saves user data to localStorage.
   * @param {object} data - User data to be saved.
   */
  function setUserData(data) {
    localStorage.setItem(USER_DETAILS, JSON.stringify(data));
  }

  /**
   * Updates user data in localStorage with new data.
   * @param {object} newUserData - New user data.
   */
  function updateUserDataInSession(newUserData) {
    try {
      const userData = getUserSession();
      const updatedUserData = { ...userData, ...newUserData };
      localStorage.setItem(USER_DETAILS, JSON.stringify(updatedUserData));
    } catch (error) {
      console.error(`Error updating ${USER_DETAILS} in localStorage:`, error);
    }
  }

  /**
   * Navigates to the login page and removes user data from localStorage.
   */
  async function signOut() {
    signOutMutate();
  }

  /**
   * Redirects the user to a specified path.
   * @param {string} path - Path to redirect to.
   */
  function redirectTo(path) {
    navigate(path);
  }

  /**
   * Checks if the current user is an authentic user based on their role.
   * @returns {boolean} True if the user is authentic, false otherwise.
   */
  function isAuthenticUser() {
    return userData?.role === ROLES.USER && userData?.token;
  }

  useEffect(() => {
    setIsLoading(isLoadingMutate);
  }, [isLoadingMutate]);

  // Return the user data and utility functions
  return {
    userData: getUserSession(),
    setUserData,
    updateUserData: updateUserDataInSession,
    signOut,
    isAuthenticUser: isAuthenticUser(),
    redirectTo,
  };
}
