import ResidentialImg from '@images/ourServices/residential/Residential.png';
import ResidentialImg_placeholder from '@images/ourServices/residential/Residential_placeholder.png';
import { data } from './data';
import ServicesCard from '@components/ui/ServicesCard';
import LazyLoadImage from '@components/ui/LazyLoadImage';

export default function Residential() {

  return (
    <section className='bg-primary_2 px-6 laptopS:px-0  pt-10 laptopS:pt-[13rem]'>
      <div id='C-Residential' className='flex flex-col items-center'>
        <p  className='font-rage-italic text-custom-24 laptopS:text-custom-48 text-primary_1'>
          Industries We Serve
        </p>
        <h1 className='mt-4 laptopS:mt-0 text-custom-48 laptopS:text-custom-96 font-custom-600 text-primary_4'>
          Residential
        </h1>
        <div className='flex flex-wrap justify-center gap-1 text-custom-12 laptopS:text-custom-16 text-primary_4'>
          <span>Single-Family</span>{" • "} 
          <span>Multi-Family</span>{" • "}
          <span>Student Housing</span>{" • "}
          <span>Condominium</span>
        </div>
      </div>
      <div className='flex justify-center pb-10 pt-14 laptopS:pb-[7.5rem] laptopS:pt-[5rem]'>
        <LazyLoadImage
          alt={'Residential'}
          src={ResidentialImg}
          placeholder={ResidentialImg_placeholder}
          placeholderClassName={'min-h-[10.3125rem] min-w-[20.4375rem] laptopS:max-h-[41.563rem] laptopS:max-w-[82.25rem]'}
          className={'min-h-[10.3125rem] min-w-[20.4375rem] laptopS:max-h-[41.563rem] laptopS:max-w-[82.25rem]'}
        />
      </div>

      <div className='flex flex-wrap justify-center gap-10 laptopS:gap-20 pb-10 laptopS:pb-[5.625rem]'>
        {data?.map((item, index) => {
          return (
            <div key={item.title + index}>
              <ServicesCard
                title={item.title}
                description={item.description}
                path={item.path}
                image={item.image}
                imagePlaceholder={item.imagePlaceholder}
              />
            </div>
          );
        })}
      </div>
    </section>
  );
}
