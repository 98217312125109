// Errors
import Error400 from '@components/layout/errors/Error400';
import Error403 from '@components/layout/errors/Error403';
import Error404 from '@components/layout/errors/Error404';
import Error500 from '@components/layout/errors/Error500';

// Component
import Loader from '@components/ui/Loader';

// UI/Tools
import { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from './Web/Home/index';
import OurServices from './Web/OurServices/index';
import Partners from './Web/Partners/index';
import LogIn from './Web/LogIn/index';
import SignUp from './Web/SignUp/index';
import ForgotPassword from './Web/ForgotPassword/index';
import ResetPassword from './Web/ResetPassword/index';
import Dashboard from './User/Dashboard/index';
import EmailVerification from '@components/puplic-pages/EmailVerification';
import PasswordVerification from '@components/puplic-pages/ResetPasswordVerification';

export default function Index() {
  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        <Route index path='/' element={<Home />} />
        <Route path='/our-services' element={<OurServices />} />
        <Route path='/partners' element={<Partners />} />
        <Route path='/log-in' element={<LogIn />} />
        <Route path='/sign-up' element={<SignUp />} />
        <Route path='/forgot-password' element={<ForgotPassword />} />
        <Route path='/reset-password' element={<ResetPassword />} />
        <Route path='/email-verification' element={<EmailVerification />} />
        <Route
          path='/password-verification'
          element={<PasswordVerification />}
        />
        <Route path='/dashboard' element={<Dashboard />} />
        <Route path='/error-404' element={<Error404 />} />
        <Route path='/error-400' element={<Error400 />} />
        <Route path='/error-403' element={<Error403 />} />
        <Route path='/error-500' element={<Error500 />} />
        <Route path='*' element={<Error404 />} />
      </Routes>
    </Suspense>
  );
}
