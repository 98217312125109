import Badge from '@components/ui/Badge';
import ImagesBlock from './ImagesBlock';
import ImagesBlockMobile from './ImagesBlockMobile';
import useWindowSize from '@hooks/useWindowSize';

export default function DontWorry() {
  const { width } = useWindowSize();

  const isMobile = width < 1024;

  return (
    <section className='flex snap-start flex-col items-center bg-primary_4 mx-6 laptopS:min-h-screen laptopS:px-0'>
      {isMobile && <ImagesBlockMobile />}
      <div className='h-full justify-between gap-[8.75rem] laptopS:flex'>
        <div className='flex flex-col gap-4 laptopS:gap-6 laptopS:py-20 laptopS:pt-[25.188rem]'>
          <Badge
            width='w-[8.875rem] tabletS:w-[13.125rem]'
            height='h-[2.5rem] tabletS:h-[3.75rem]'
          >
            <p className='text-custom-12 tabletS:text-custom-20'>Don't Worry</p>
          </Badge>
          <p className='max-w-[54.5rem] text-custom-16 text-primary_2 laptopS::text-custom-32'>
            Experience property management without the stress. PropManage
            ensures peace of mind, providing seamless operations, enhanced
            profitability, and effortless management. Enjoy proactive issue
            resolution and
            <span className='font-custom-600 text-primary_1'>
              {' '}
              strategic decision-making
            </span>
            , redefining property ownership for you.
          </p>
        </div>

        {!isMobile && <ImagesBlock />}
      </div>
    </section>
  );
}
