import { ROLES } from '../utils/constants';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import useUser from '../utils/hooks/useUser';
import Loader from '@components/ui/Loader';

// Define public paths that everyone can access
const publicPaths = [
  '/',
  '/our-services',
  '/partners',
  '/log-in',
  '/sign-up',
  '/forgot-password',
  '/email-verification',
  '/password-verification',
  '/reset-password',
  '/error-403',
  '/error-400',
  '/error-404',
  '/error-500',
  '/error-503',
];

// Define allowed paths for each role
const allowedPathsForRole = {
  [ROLES.USER]: [
    // Define paths accessible to users
    '/dashboard',
  ],
  // Add more roles and their allowed paths as needed
};

// Define all valid paths for error handling and redirection
const allValidPaths = [
  ...publicPaths,
  ...allowedPathsForRole[ROLES.USER],
  // Add all other paths that require authentication
  // This array needs to be manually updated or dynamically generated based on your routing configuration
];

const CustomAuthProvider = ({ children }) => {
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const { userData, redirectTo, isAuthenticUser } = useUser();

  useEffect(() => {
    const checkAccess = async () => {
      const pathExists = allValidPaths.includes(location.pathname);
      if (!pathExists) {
        // Redirect to error-404 if path does not exist
        redirectTo('/error-404');
        return;
      }

      if (!isAuthenticUser) {
        if (!publicPaths.includes(location.pathname)) {
          redirectTo('/error-403');
        }
      } else {
        const allowedPaths = allowedPathsForRole[userData?.role] || [];
        const isAllowed = allowedPaths.some((path) => {
          if (typeof path === 'string') {
            return location.pathname === path;
          } else if (path instanceof RegExp) {
            return path.test(location.pathname);
          }
          return false;
        });

        if (!isAllowed && !publicPaths.includes(location.pathname)) {
          redirectTo('/error-403');
        }
      }

      setLoading(false);
    };

    checkAccess();
  }, [location.pathname, isAuthenticUser, redirectTo, userData?.role]);

  return <>{loading ? <Loader /> : children}</>;
};

export default CustomAuthProvider;
