import CustomLink from '@components/ui/CustomLink';

export default function HeaderLink({ path, name, isActive, isMobile }) {
  return (
    <li>
      <CustomLink
        className={`
        ${isActive ? 'text-primary_1 font-custom-600' : isMobile ? 'font-custom-400 text-primary_4' : 'font-custom-400 text-primary_2'} 
        ${isMobile ? `text-[1.5rem]` : `text-custom-20`} 
        transition-color  duration-200 hover:font-custom-600 hover:text-primary_1`}
        path={path}
      >
        {name}
      </CustomLink>
    </li>
  );
}
