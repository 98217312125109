import React from 'react';
import CustomLink from './CustomLink';
import XSvg from './svg/XSvg';
import PropManageLogoWhite from './svg/PropManageLogoWhiteSvg';

export default function RegistrationSidebar({ isLeft }) {
  return (
    <div className='hidden w-1/2 flex-col bg-registrationBackground bg-cover laptopS:flex'>
      <div className='flex items-start justify-end'>
        <CustomLink path={'/'}>
          <div className='me-10 ms-auto mt-10'>
            <XSvg className='h-8 w-8 fill-white ' />
          </div>
        </CustomLink>
      </div>

      <div className='flex grow items-center justify-center'>
        <PropManageLogoWhite className={'h-[8rem] w-[22.5rem]'} />
      </div>
    </div>
  );
}
