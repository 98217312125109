import ImageA from '@images/home/dont-worry/a.png';
import ImageB from '@images/home/dont-worry/b.png';
import ImageC from '@images/home/dont-worry/c.png';
import ImageD from '@images/home/dont-worry/d.png';
import ImageE from '@images/home/dont-worry/e.png';
import ImageF from '@images/home/dont-worry/f.png';
import ImageA_placeholder from '@images/home/dont-worry/a_placeholder.png';
import ImageB_placeholder from '@images/home/dont-worry/b_placeholder.png';
import ImageC_placeholder from '@images/home/dont-worry/c_placeholder.png';
import ImageD_placeholder from '@images/home/dont-worry/d_placeholder.png';
import ImageE_placeholder from '@images/home/dont-worry/e_placeholder.png';
import ImageF_placeholder from '@images/home/dont-worry/f_placeholder.png';
import LazyLoadImage from '@components/ui/LazyLoadImage';
import CustomSwiper from '@components/ui/swiper';

const ImageData = [
  {
    src: ImageA,
    placeholderSrc: ImageA_placeholder,
    alt: 'A',
  },
  {
    src: ImageB,
    placeholderSrc: ImageB_placeholder,
    alt: 'B',
  },
  {
    src: ImageC,
    placeholderSrc: ImageC_placeholder,
    alt: 'C',
  },
  {
    src: ImageD,
    placeholderSrc: ImageD_placeholder,
    alt: 'D',
  },
  {
    src: ImageE,
    placeholderSrc: ImageE_placeholder,
    alt: 'E',
  },
  {
    src: ImageF,
    placeholderSrc: ImageF_placeholder,
    alt: 'F',
  },
];

export default function ImagesBlockMobile() {
  return (
    <div className='mb-[2rem] w-[21.9375rem] mobileM:w-11/12'>
      <CustomSwiper
        freeMode={true}
        breakpoints={{
          0: { slidesPerView: 2 },
          650: { slidesPerView: 3 },
          800: { slidesPerView: 4 },
        }}
      >
        {ImageData.map((item, index) => (
          <LazyLoadImage
            key={item?.alt + index}
            alt={item?.alt}
            src={item?.src}
            placeholder={item?.placeholderSrc}
            placeholderClassName={
              'rounded-custom-44 h-[13.6875rem] w-[9.6875rem]'
            }
            className={'h-[13.6875rem] w-[9.6875rem]'}
          />
        ))}
      </CustomSwiper>
    </div>
  );
}
