const useStorage = (storageType = 'local') => {
  const storage = storageType === 'local' ? localStorage : sessionStorage;

  const setItem = (key, value) => {
    try {
      const valueToStore = JSON.stringify(value);
      storage.setItem(key, valueToStore);
    } catch (error) {
      console.error(`Error saving to ${storageType}Storage`, error);
    }
  };

  const getItem = (key) => {
    try {
      const item = storage.getItem(key);
      return item ? JSON.parse(item) : null;
    } catch (error) {
      console.error(`Error getting item from ${storageType}Storage`, error);
      return null;
    }
  };

  const removeItem = (key) => {
    try {
      storage.removeItem(key);
    } catch (error) {
      console.error(`Error removing item from ${storageType}Storage`, error);
    }
  };

  const clearStorage = () => {
    try {
      storage.clear();
    } catch (error) {
      console.error(`Error clearing ${storageType}Storage`, error);
    }
  };

  return { setItem, getItem, removeItem, clearStorage };
};

export default useStorage;
