import { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export default function useURLQuery() {
  const { search } = useLocation();
  const navigate = useNavigate();

  const query = useMemo(() => new URLSearchParams(search), [search]);

  const getQueryParam = (key) => {
    return query.get(key);
  };

  const setQueryParam = (key, value, { replace = false } = {}) => {
    const newQuery = new URLSearchParams(search);
    newQuery.set(key, value);
    navigate(`?${newQuery.toString()}`, { replace });
  };

  const deleteQueryParam = (key, { replace = false } = {}) => {
    const newQuery = new URLSearchParams(search);
    newQuery.delete(key);
    navigate(`?${newQuery.toString()}`, { replace });
  };

  const getAllQueryParams = () => {
    return Array.from(query.entries()).reduce((acc, [key, value]) => {
      acc[key] = value;
      return acc;
    }, {});
  };

  return {
    getQueryParam,
    setQueryParam,
    deleteQueryParam,
    getAllQueryParams,
  };
}
