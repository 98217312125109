export default function SnapchatSvg({
  style,
  width = '1.5rem',
  height = '1.5rem',
}) {
  return (
    <svg
      className={style}
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 24 24'
    >
      <g
        id='Group_228243'
        data-name='Group 228243'
        transform='translate(-992 -3232)'
      >
        <rect
          id='Rectangle_58970'
          data-name='Rectangle 58970'
          width='24'
          height='24'
          transform='translate(992 3232)'
          fill='none'
          opacity='0'
        />
        <path
          id='Icon_awesome-snapchat-ghost'
          data-name='Icon awesome-snapchat-ghost'
          d='M23.946,18.766c-.244.57-1.277.989-3.158,1.281a8.792,8.792,0,0,0-.305,1.123.543.543,0,0,1-.569.416H19.9c-.44,0-.9-.2-1.821-.2a3.71,3.71,0,0,0-2.637.965,5.279,5.279,0,0,1-3.47,1.284,5.384,5.384,0,0,1-3.416-1.285,3.705,3.705,0,0,0-2.636-.965A17.7,17.7,0,0,0,4.1,21.6a.556.556,0,0,1-.582-.424,8.683,8.683,0,0,0-.306-1.131C2.242,19.9.056,19.517,0,18.542a.5.5,0,0,1,.416-.519c3.262-.537,4.731-3.886,4.792-4.028l.011-.024a1.065,1.065,0,0,0,.115-.879c-.237-.558-1.26-.758-1.69-.928-1.112-.439-1.266-.944-1.2-1.289a1.206,1.206,0,0,1,1.547-.724,2.682,2.682,0,0,0,1.1.3.957.957,0,0,0,.467-.1c-.1-1.685-.333-4.092.267-5.436a6.386,6.386,0,0,1,5.929-3.825l.474,0A6.342,6.342,0,0,1,18.172,4.9c.6,1.343.363,3.74.267,5.438a.919.919,0,0,0,.4.1,2.8,2.8,0,0,0,1.034-.293,1.269,1.269,0,0,1,.96,0h0a.974.974,0,0,1,.731.838c.009.457-.4.851-1.213,1.173-.1.039-.22.078-.348.118-.459.146-1.153.366-1.341.81a1.065,1.065,0,0,0,.115.879l.011.024c.061.142,1.529,3.49,4.792,4.028A.512.512,0,0,1,23.946,18.766Z'
          transform='translate(992 3231.641)'
        />
      </g>
    </svg>
  );
}
