import { toast } from 'react-toastify';

/**
 * Custom hook to manage toast notifications.
 */
export default function useToastNotifications() {
  const option = {
    // position: 'top-right',
    // autoClose: 5000,
    // hideProgressBar: false,
    // closeOnClick: true,
    // pauseOnHover: true,
    // draggable: true,
    // progress: undefined,
  };

  /**
   * Display a success toast
   * @param {string} message The message to display
   */
  const notifySuccess = (message) => {
    toast.success(message, option);
  };

  /**
   * Display an error toast
   * @param {string} message The message to display
   */
  const notifyError = (message) => {
    toast.error(message, option);
  };

  /**
   * Display an info toast
   * @param {string} message The message to display
   */
  const notifyInfo = (message) => {
    toast.info(message, option);
  };

  return { notifySuccess, notifyError, notifyInfo };
}
