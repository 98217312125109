export default function EmailSvg({ style, width = '24', height = '24' }) {
  return (
    <svg
      className={style}
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 24 24'
    >
      <g
        id='Group_226203'
        data-name='Group 226203'
        transform='translate(-255 -815)'
      >
        <rect
          id='Rectangle_24544'
          data-name='Rectangle 24544'
          width='24'
          height='24'
          transform='translate(255 815)'
          fill='none'
          opacity='0'
        />
        <path
          id='Icon_simple-email'
          data-name='Icon simple-email'
          d='M23.419,11.077a12.377,12.377,0,0,1-.4,2.684,7.769,7.769,0,0,1-1.044,2.358,5.508,5.508,0,0,1-1.77,1.675,4.994,4.994,0,0,1-2.584.637,3.746,3.746,0,0,1-1.869-.457,3.2,3.2,0,0,1-1.263-1.294,4,4,0,0,1-3.386,1.7,3.443,3.443,0,0,1-2.956-1.59,5.2,5.2,0,0,1-.735-1.842,7.942,7.942,0,0,1-.1-2.4A11.16,11.16,0,0,1,8.049,9.6,8,8,0,0,1,9.4,7.35a5.723,5.723,0,0,1,1.86-1.437,5.189,5.189,0,0,1,2.262-.5,7.032,7.032,0,0,1,1.353.115,5.537,5.537,0,0,1,1.05.315,5.677,5.677,0,0,1,.87.459c.265.171.532.348.8.528l-.657,7.559a2.512,2.512,0,0,0,.019.885,1.238,1.238,0,0,0,.27.546.972.972,0,0,0,.438.285,1.626,1.626,0,0,0,1.539-.3,3.279,3.279,0,0,0,.825-1.05,6.761,6.761,0,0,0,.555-1.578,10.825,10.825,0,0,0,.243-1.965,12.556,12.556,0,0,0-.309-3.671A7.3,7.3,0,0,0,19.158,4.7a6.309,6.309,0,0,0-2.436-1.83,8.84,8.84,0,0,0-3.548-.649,7.835,7.835,0,0,0-3.509.765A8.052,8.052,0,0,0,7,5.1a10.177,10.177,0,0,0-1.746,3.19,14.375,14.375,0,0,0-.735,3.974,13.216,13.216,0,0,0,.375,4.055,7.324,7.324,0,0,0,1.482,2.906,6.183,6.183,0,0,0,2.511,1.746,9.63,9.63,0,0,0,3.464.579,11.866,11.866,0,0,0,2.274-.244c.375-.075.73-.165,1.069-.264a8.048,8.048,0,0,0,.921-.33l.475,2.139a5.277,5.277,0,0,1-1.03.51,9.918,9.918,0,0,1-1.218.352A11.882,11.882,0,0,1,12.3,24a13.339,13.339,0,0,1-4.637-.747,8.535,8.535,0,0,1-3.38-2.223,9.334,9.334,0,0,1-2.029-3.67,15.654,15.654,0,0,1-.567-5.095,15.537,15.537,0,0,1,.975-4.889A12.1,12.1,0,0,1,5.006,3.493,10.7,10.7,0,0,1,8.589.93,11.217,11.217,0,0,1,13.229,0a11.711,11.711,0,0,1,4.5.805A8.745,8.745,0,0,1,20.971,3.07a9.294,9.294,0,0,1,1.926,3.5A13.378,13.378,0,0,1,23.419,11.077Zm-12.88,1.47a5.1,5.1,0,0,0,.264,2.53,1.356,1.356,0,0,0,1.3.855,1.441,1.441,0,0,0,.447-.082,1.594,1.594,0,0,0,.514-.309,2.745,2.745,0,0,0,.522-.63,4.834,4.834,0,0,0,.48-1.065l.513-5.873a3.064,3.064,0,0,0-.8-.1,2.759,2.759,0,0,0-1.327.3,2.628,2.628,0,0,0-.94.894,5.249,5.249,0,0,0-.624,1.47,12.467,12.467,0,0,0-.354,2.01Z'
          transform='translate(254.567 815)'
        />
      </g>
    </svg>
  );
}
