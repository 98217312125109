import { useState } from 'react';
import FilledButton from '../buttons/FilledButton';
import { XyzTransition } from '@animxyz/react';

const Accordion = ({ items, setActiveItem }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? activeIndex : index);
  };

  return (
    <div className='mx-auto'>
      {items.map((item, index) => (
        <div
          key={index}
          className='border-b border-solid border-secondary_2 py-10'
        >
          <button
            className='flex w-full px-4 py-2 focus:outline-none'
            onClick={() => {
              toggleAccordion(index);
              setActiveItem(item);
            }}
          >
            <div className='flex flex-col gap-4'>
              <p className='text-start text-custom-24 font-custom-600 text-primary_1'>
                {item.title}
              </p>
              <p className='text-start text-custom-16 text-primary_2'>
                {item.description}
              </p>
            </div>
          </button>
          <XyzTransition appear duration='auto' xyz='fade up'>
            {activeIndex === index && (
              <div className={`mt-[2rem]`}>
                <FilledButton
                  className={
                    'rounded-custom-44 text-custom-12 laptopS:text-custom-20'
                  }
                  width='w-[12rem]'
                  height='h-[4rem]'
                  text='Read More'
                  path={item.path}
                />
              </div>
            )}
          </XyzTransition>
        </div>
      ))}
    </div>
  );
};

export default Accordion;
