import ErrorFormik from '@components/ui/ErrorFormik';
import FilledButton from '@components/ui/buttons/FilledButton';
import CustomInput from '@components/ui/custom-inputs/CustomInput';
import PasswordInput from '@components/ui/custom-inputs/PasswordInput';

export default function Step2({
  handleNext,
  values,
  errors,
  touched,
  handleBlur,
  handleChange,
}) {
  return (
    <div className='w-full max-w-[35rem]'>
      <div className=''>
        <h3 className='text-custom-24 font-custom-500 text-primary_2'>
          Thanks! Tell us a little more about yourself.
        </h3>
        <p className='mt-4 text-custom-16 text-primary_2'>
          To customize your free trail, we need a few more details.
        </p>
      </div>
      <div className='mt-10 flex flex-col gap-6'>
        <div>
          <CustomInput
            id='companyName'
            name='companyName'
            value={values?.companyName}
            onChange={handleChange}
            onBlur={handleBlur}
            shape={2}
            className='h-[4.5rem] w-full'
            placeholder='Company name'
            type='text'
          />
          <ErrorFormik
            isError={errors?.companyName}
            isTouched={touched?.companyName}
            error={errors?.companyName}
          />
        </div>
        <div>
          <CustomInput
            id='phoneNumber'
            name='phoneNumber'
            value={values?.phoneNumber}
            onChange={handleChange}
            onBlur={handleBlur}
            shape={2}
            className='h-[4.5rem] w-full'
            placeholder='Phone number'
            type='number'
          />
          <ErrorFormik
            isError={errors?.phoneNumber}
            isTouched={touched?.phoneNumber}
            error={errors?.phoneNumber}
          />
        </div>
        <div>
          <CustomInput
            id='numberOfUnits'
            name='numberOfUnits'
            value={values?.numberOfUnits}
            onChange={handleChange}
            onBlur={handleBlur}
            shape={2}
            className='h-[4.5rem] w-full'
            placeholder='Number of units'
            type='number'
          />
          <ErrorFormik
            isError={errors?.numberOfUnits}
            isTouched={touched?.numberOfUnits}
            error={errors?.numberOfUnits}
          />
        </div>
        <div>
          <PasswordInput
            id='password'
            name='password'
            value={values?.password}
            onChange={handleChange}
            onBlur={handleBlur}
            shape={2}
            className='h-[4.5rem] w-full px-6'
            placeholder='Password'
            type='password'
          />
          <ErrorFormik
            isError={errors?.password}
            isTouched={touched?.password}
            error={errors?.password}
          />
        </div>
        <FilledButton
          isButton
          onClick={handleNext}
          text='Create Your Free Trail'
          width='w-full'
          height='h-[4rem]'
          className='mt-4 rounded-custom-16 text-custom-20'
        />
      </div>
    </div>
  );
}
