import Badge from '@components/ui/Badge';
import { data } from './data';
import Stepper from './Stepper';
import MobileStepper from './MobileStepper';
import useWindowSize from '@hooks/useWindowSize';

export default function OurSolutions() {
  const { width } = useWindowSize();

  const isMobile = width < 1024;

  return (
    <section className='flex flex-col bg-primary_4 px-6 py-14 laptopS:min-h-screen laptopS:items-center laptopS:pb-[7rem] laptopS:pt-[18.7vh] desktop:pb-0'>
      <div>
        <Badge
          width='w-[9.625rem] laptopS:w-[14.25rem]'
          height='h-[2.5rem] laptopS:h-[3.75rem]'
        >
          <p className='text-custom-12 laptopS:text-custom-20'>Our Solutions</p>
        </Badge>
        {isMobile ? <MobileStepper data={data} /> : <Stepper data={data} />}
      </div>
    </section>
  );
}
