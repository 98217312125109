import { useState } from 'react';
import { XyzTransition } from '@animxyz/react';
import useURLQuery from '@hooks/useURLQuery';
import LazyLoadImage from '@components/ui/LazyLoadImage';
import FilledButton from '@components/ui/buttons/FilledButton';
import ArrowLeftSvg from '@components/ui/svg/ArrowLeftSvg';
import ArrowRightSvg from '@components/ui/svg/ArrowRightSvg';

export default function MobileStepper({ data }) {
  const { getAllQueryParams } = useURLQuery();
  const query = getAllQueryParams();

  const [activeIndex, setActiveIndex] = useState(
    query.id < data.length ? Number(query.id) : 0,
  );

  const RenderImage = ({ item }) => {
    return (
      <LazyLoadImage
        alt={item.title}
        src={item.image}
        placeholder={item.imagePlaceholder}
        placeholderClassName={
          'w-[20.4375rem] h-[20.1875rem] object-cover  rounded-custom-20'
        }
        className={
          'h-[20.1875rem] w-[20.4375rem] rounded-custom-20 object-cover '
        }
      />
    );
  };

  function handleNext() {
    if (activeIndex + 1 < data.length) setActiveIndex((prev) => prev + 1);
  }

  function handlePrev() {
    if (activeIndex > 0) setActiveIndex((prev) => prev - 1);
  }

  return (
    <section
      id=''
      className='mt-6 flex w-full flex-col items-center bg-primary_4'
    >
      <div className='w-full'>
        <div>
          {data[activeIndex] && (
            <div key={data[activeIndex].title + 'index'}>
              <RenderImage item={data[activeIndex]} />
            </div>
          )}
        </div>
        <div className=' relative flex flex-col justify-center'>
          {data[activeIndex] && (
            <div
              className='items-center justify-center'
              key={data[activeIndex].title + 'index'}
            >
              <h3 className='mb-2 mt-4 text-custom-16 font-custom-600'>
                {data[activeIndex].title}
              </h3>
              <p className='text-custom-12'>{data[activeIndex].description}</p>

              <div className={`mt-4`}>
                <FilledButton
                  className={
                    'rounded-custom-44 text-custom-12 laptopS:text-custom-20'
                  }
                  width='w-[7rem]'
                  height='h-[2.5rem]'
                  text='Read More'
                  path={data[activeIndex].path}
                />
              </div>
            </div>
          )}

          <div className='absolute bottom-0 right-0 flex justify-between'>
            <div className={`flex w-full justify-end gap-4`}>
              {activeIndex > 0 && (
                <div className='flex items-center gap-4'>
                  <FilledButton
                    className={
                      'rounded-custom-44 text-custom-12 laptopS:text-custom-20'
                    }
                    text={<ArrowLeftSvg />}
                    isButton
                    onClick={handlePrev}
                    width='w-[2.5rem]'
                    height='h-[2.5rem]'
                  />
                </div>
              )}
              {activeIndex + 1 < data.length && (
                <div className='flex items-center gap-4'>
                  <FilledButton
                    className={
                      'rounded-custom-44 text-custom-12 laptopS:text-custom-20'
                    }
                    text={<ArrowRightSvg />}
                    isButton
                    onClick={handleNext}
                    width='w-[2.5rem]'
                    height='h-[2.5rem]'
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
