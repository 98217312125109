import Badge from '@components/ui/Badge';
import ArrowRightSvg from '@components/ui/svg/ArrowRightSvg';

export default function SolutionView({ item }) {
  return (
    <div>
      <h2 className='text-custom-16 font-custom-600 text-primary_1 laptopS:text-custom-32'>
        {item?.title}
      </h2>
      <p className='mt-2 text-custom-12 text-primary_2 laptopS:mt-4 laptopS:text-custom-16'>
        {item?.description}
      </p>
      {item?.ladle && (
        <Badge
          width='w-[10.5rem] mt-6 mb-4 laptopS:w-[15.75rem] laptopS:mt-[2.688rem] laptopS:mb-[2rem]'
          
          height='h-[2.5rem] laptopS:h-[3.75rem]'
        >
          <p className='text-custom-12 laptopS:text-custom-20'>{item?.ladle}</p>
        </Badge>
        
      )}
      {item?.capabilities && (
        <div className='customScroll flex flex-col gap-6 overflow-y-auto overflow-x-hidden laptopS:max-h-[40vh]'>
          {item?.capabilities?.map((item, index) => {
            return (
              <div key={item?.title + index}>
                <SolutionSlot
                  title={item?.title}
                  description={item?.description}
                />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

function SolutionSlot({ title, description }) {
  return (
    <div className='flex gap-2'>
      <div>
        <div className='flex w-5 h-5 laptopS:h-6 laptopS:w-6 items-center justify-center rounded-full border-2 border-solid border-primary_1'>
          <ArrowRightSvg style='fill-primary_1' width='1rem' height='1rem' />
        </div>
      </div>
      <div>
        <h3 className='laptopS:text-custom-16 text-custom-14 font-custom-600 text-primary_2'>
          {title}
        </h3>
        <p className='mt-2 laptopS:text-custom-16 text-custom-12 text-primary_2'>{description}</p>
      </div>
    </div>
  );
}
