import FooterLogo from '@images/BlackPropManageLogo.png';
import CustomLink from '@components/ui/CustomLink';
import FacebookSvg from '@ui/svg/FacebookSvg';
import InstagramSvg from '@ui/svg/InstagramSvg';
import SnapchatSvg from '@ui/svg/SnapchatSvg';
import X_SocialSvg from '@ui/svg/X_SocialSvg';
import EmailSvg from '@ui/svg/EmailSvg';
import LocationSvg from '@ui/svg/LocationSvg';
import PhoneSvg from '@ui/svg/PhoneSvg';
import CopyrightSvg from '@svg/copyright.svg';
import { useState } from 'react';
import { XyzTransition } from '@animxyz/react';
import ArrowSelect from '@components/ui/svg/ArrowSelect';
import { useLocation } from 'react-router-dom';
import { PATHS_DATA } from '@utils/constants';
import FooterLink from './FooterLink';

export default function Footer() {
  const [isContactsOpen, setIsContactsOpen] = useState(false);
  const [isLinksOpen, setIsLinksOpen] = useState(false);
  const { pathname } = useLocation();

  const toggleContacts = () => {
    setIsContactsOpen(!isContactsOpen);
  };

  const toggleLinks = () => {
    setIsLinksOpen(!isLinksOpen);
  };

  return (
    <footer className='flex min-h-[28.625rem] flex-col items-center justify-between gap-10 bg-primary_1 laptopS:gap-0'>
      <div className='mt-[3.813rem] w-full max-w-[102.5rem]'>
        <div className='flex flex-col items-center gap-x-[15.5rem] gap-y-8 laptopS:flex-row'>
          {/* Logo and social */}
          <div className='flex flex-col justify-center laptopS:w-[19rem]'>
            <CustomLink className={'flex justify-center'} path={'/'}>
              <img
                className='mb-4 h-10 w-[7.0313rem] laptopS:mb-[6.063rem]  laptopS:h-[4.063rem] laptopS:w-[11.375rem]'
                src={FooterLogo}
                alt='Logo'
              />
            </CustomLink>

            <ul className='mb-2 flex gap-10 laptopS:mb-[1.313rem]'>
              <li>
                <CustomLink path={'#'}>
                  <FacebookSvg
                    style={
                      'fill-primary_2 hover:fill-white transition-all duration-500'
                    }
                  />
                </CustomLink>
              </li>
              <li>
                <CustomLink path={'#'}>
                  <InstagramSvg
                    style={
                      'fill-primary_2 hover:fill-white transition-all duration-500'
                    }
                  />
                </CustomLink>
              </li>
              <li>
                <CustomLink path={'#'}>
                  <SnapchatSvg
                    style={
                      'fill-primary_2 hover:fill-white transition-all duration-500'
                    }
                  />
                </CustomLink>
              </li>
              <li>
                <CustomLink path={'#'}>
                  <X_SocialSvg
                    style={
                      'fill-primary_2 hover:fill-white transition-all duration-500'
                    }
                  />
                </CustomLink>
              </li>
            </ul>
            <p className='text-center text-custom-16 text-primary_2 laptopS:text-custom-24'>
              @propmanage
            </p>
          </div>

          {/* Contact and links */}
          {/* Desktop */}
          <div className='hidden w-full flex-col flex-wrap items-center justify-center gap-x-[12.5rem] gap-y-8 first-letter:flex tabletM:flex tabletM:flex-row tabletM:items-start'>
            <div className='w-[19rem] tabletM:w-[21rem]'>
              <h2 className='text-custom-24 font-custom-500 text-primary_2'>
                Contacts
              </h2>
              <div className='my-[1.5rem] flex w-full flex-col gap-8 tabletM:my-[2.188rem]'>
                <div className='flex items-center gap-2'>
                  <div>
                    <LocationSvg
                      style={
                        'fill-primary_2 hover:fill-white transition-all duration-500'
                      }
                    />
                  </div>
                  <p className='text-custom-16 text-primary_2'>
                    19 Thorne Street, Suite 110, Cambridge, Ontario, Canada, N1R
                    1S3
                  </p>
                </div>
                <div className='flex items-center gap-2'>
                  <div>
                    <EmailSvg
                      style={
                        'fill-primary_2 hover:fill-white transition-all duration-500'
                      }
                    />
                  </div>
                  <p className='text-custom-16 text-primary_2'>
                    info@propmanage.com
                  </p>
                </div>
                <div className='flex items-center gap-2'>
                  <div>
                    <PhoneSvg
                      style={
                        'fill-primary_2 hover:fill-white transition-all duration-500'
                      }
                    />
                  </div>
                  <p className='text-custom-16 text-primary_2'>
                    Ontario Coorporation # 1000089227
                  </p>
                </div>
              </div>
            </div>
            <div className='w-[19rem] tabletM:w-[10rem]'>
              <h2 className='text-custom-24 font-custom-500 text-primary_2'>
                Links
              </h2>
              <ul className='my-[1.5rem] flex w-full flex-col gap-[1.625rem] tabletM:my-[2.188rem]'>
                {PATHS_DATA.map((item, index) => (
                  <FooterLink
                    key={item?.name + index + 'Nav'}
                    path={item.path}
                    name={item.name}
                    isActive={pathname === item.path}
                  />
                ))}
              </ul>
            </div>
            <div className='w-[19rem] tabletM:w-[15.75rem]'>
              <h2 className='text-custom-24 font-custom-500 text-primary_2'>
                Start Your Demo
              </h2>
              <div>
                <p className='mt-[1.5rem] text-custom-16 text-primary_2 tabletM:mt-[2.188rem]'>
                  Email Address
                </p>
                <hr className='mt-4' />
              </div>
            </div>
          </div>

          {/* Mobile */}
          <div className='flex w-full flex-col items-start justify-center gap-6 px-6 first-letter:flex tabletM:hidden tabletM:flex-row tabletM:items-start'>
            <div className='group block w-full tabletM:hidden tabletM:w-[10rem]'>
              <button
                onClick={toggleContacts}
                className={`${isContactsOpen ? `text-primary_4` : `text-primary_2`} flex w-full items-center justify-between text-custom-16 font-custom-700 transition-all duration-500 group-hover:text-primary_4`}
              >
                Contacts
                <ArrowSelect
                  className={`${isContactsOpen ? `rotate-180 fill-primary_4` : `fill-primary_2`} transition-all duration-500 group-hover:fill-primary_4`}
                />
              </button>
              <XyzTransition duration='auto' xyz='fade-75% up-2'>
                {isContactsOpen && (
                  <div className='my-[1.5rem] flex w-full flex-col gap-8 tabletM:my-[2.188rem]'>
                    <div className='flex items-center gap-2'>
                      <div>
                        <LocationSvg
                          style={
                            'fill-primary_2 hover:fill-white transition-all duration-500'
                          }
                        />
                      </div>
                      <p className='text-custom-12 text-primary_2'>
                        19 Thorne Street, Suite 110, Cambridge, Ontario, Canada,
                        N1R 1S3
                      </p>
                    </div>
                    <div className='flex items-center gap-2'>
                      <div>
                        <EmailSvg
                          style={
                            'fill-primary_2 hover:fill-white transition-all duration-500'
                          }
                        />
                      </div>
                      <p className='text-custom-12 text-primary_2'>
                        info@propmanage.com
                      </p>
                    </div>
                    <div className='flex items-center gap-2'>
                      <div>
                        <PhoneSvg
                          style={
                            'fill-primary_2 hover:fill-white transition-all duration-500'
                          }
                        />
                      </div>
                      <p className='text-custom-12 text-primary_2'>
                        Ontario Coorporation # 1000089227
                      </p>
                    </div>
                  </div>
                )}
              </XyzTransition>
            </div>
            <div className='group block w-full tabletM:hidden tabletM:w-[10rem]'>
              <button
                onClick={toggleLinks}
                className={`${isLinksOpen ? `text-primary_4 ` : `text-primary_2`} flex w-full items-center justify-between text-custom-16 font-custom-700 transition-all duration-500 group-hover:text-primary_4`}
              >
                Links
                <ArrowSelect
                  className={`${isLinksOpen ? `rotate-180 fill-primary_4` : `fill-primary_2`} transition-all duration-500 group-hover:fill-primary_4`}
                />
              </button>
              <XyzTransition duration='auto' xyz='fade-75% up-2'>
                {isLinksOpen && (
                  <ul className='my-[1.5rem] flex w-full flex-col gap-[1.625rem] tabletM:my-[2.188rem]'>
                    {PATHS_DATA.map((item, index) => (
                      <FooterLink
                        isMobile={true}
                        key={item?.name + index + 'Nav'}
                        path={item.path}
                        name={item.name}
                        isActive={pathname === item.path}
                      />
                    ))}
                  </ul>
                )}
              </XyzTransition>
            </div>
            <div className='w-full tabletM:w-[15.75rem]'>
              <h2 className='text-custom-16 font-custom-700 text-primary_2'>
                Start Your Demo
              </h2>
              <div>
                <p className='mt-4 text-custom-14 text-primary_2 tabletM:mt-[2.188rem]'>
                  Email Address
                </p>
                <hr className='mt-2 w-full border-primary_2' />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*  Copyright */}
      <div className='h-full w-full'>
        <hr />
        <div className='my-[1.5rem] flex content-end justify-center gap-2'>
          <img loading='lazy' src={CopyrightSvg} alt='Copyright' />
          <p className='text-custom-13 text-primary_2 tabletM:text-custom-16'>
            2024 propmanage
          </p>
        </div>
      </div>
    </footer>
  );
}
