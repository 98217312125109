import ContactUsForm from '@components/ui/ContactUsForm';

export default function ContactUs() {
  return (
    <section
      id='contact-us'
      className='flex min-h-screen justify-center bg-primary_4 py-14 laptopS:pb-[4.688rem] laptopSpt-[13rem]'
    >
      <ContactUsForm />
    </section>
  );
}
