import LazyLoadImage from '@components/ui/LazyLoadImage';
import FilledButton from '@components/ui/buttons/FilledButton';
import ArrowUpSvg from '@components/ui/svg/ArrowUp';
import ChoosePropmanageImg from '@images/home/choose-propmanage/ChoosePropmanage.png';
import ChoosePropmanageImg_placeholder from '@images/home/choose-propmanage/ChoosePropmanage_placeholder.png';

export default function ChoosePropmanage() {
  return (
    <section className='flex laptopS:min-h-screen items-end bg-primary_2 pt-10 laptopS:ps-[8.75rem]'>
      <div className='flex w-full flex-col laptopS:flex-row justify-between'>
        <div className='laptopS:mt-[26.625rem] px-6 laptopS:px-0'>
          <h1 className='text-custom-24 laptopS:text-custom-48 font-custom-600 text-primary_4'>
            Choose Propmanage today so you can scale your
          </h1>
          <h1 className='mt-6 laptopS:mt-[2rem] text-custom-40 laptopS:text-custom-80 font-custom-600 text-primary_1'>
          TOMORROW
          </h1>
          <FilledButton
            className={"text-custom-12 laptopS:text-custom-20 rounded-custom-44"}
            width='w-[8.375rem] mt-10 laptopS:w-[14.875rem] laptopS:mt-[5rem]'
            height='h-10 laptopS:h-[4rem]'
            text='Start for free'
            path='/#C-Pricing'
            icon={<ArrowUpSvg style='fill-[#292d32] ms-2' />}
          />
        </div>
        <LazyLoadImage
          alt={'Choose Propmanage building'}
          src={ChoosePropmanageImg}
          placeholder={ChoosePropmanageImg_placeholder}
          placeholderClassName={'max-w-[86rem] max-h-[65rem] h-auto w-full'}
          className={'max-h-[65rem] max-w-[86rem]'}
        />
      </div>
    </section>
  );
}
