import AboutCard from './AboutCard';
import { aboutData } from './aboutData';

export default function AboutUs() {
  return (
    <section
      id='about-us'
      className='flex min-h-screen flex-col items-center bg-primary_4 px-6'
    >
      <div className='py-[2.5rem] laptopS:pb-[5.375rem] laptopS:pt-[13.563rem]'>
        <h1 className='break-words text-start text-[2.25rem] font-custom-600 text-primary_2 laptopS:text-[4.5rem]'>
          About <span className='text-primary_1'>Us</span>
        </h1>
        <section className='mt-[2rem] flex flex-col gap-8 laptopS:flex-row'>
          <div className='flex flex-col gap-8'>
            {aboutData.slice(0, 2).map((detail, index) => (
              <AboutCard key={index} {...detail} />
            ))}
          </div>
          <div className='flex  flex-col gap-8 laptopS:flex-row'>
            {aboutData.slice(2).map((detail, index) => (
              <AboutCard key={index} {...detail} />
            ))}
          </div>
        </section>
      </div>
    </section>
  );
}

