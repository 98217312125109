// Residential
import SingleFamily from '@images/home/industries-Serve/Single-Family.png';
import MultiFamily from '@images/home/industries-Serve/Multi-Family.png';
import StudentHousing from '@images/home/industries-Serve/StudentHousing.png';
import Condominium from '@images/home/industries-Serve/Condominium.png';
import SingleFamily_placeholder from '@images/home/industries-Serve/Single-Family_placeholder.png';
import MultiFamily_placeholder from '@images/home/industries-Serve/Multi-Family_placeholder.png';
import StudentHousing_placeholder from '@images/home/industries-Serve/StudentHousing_placeholder.png';
import Condominium_placeholder from '@images/home/industries-Serve/Condominium_placeholder.png';

// Commercial
import Office from '@images/home/industries-Serve/Office.png';
import Industrial from '@images/home/industries-Serve/Industrial.png';
import Retail from '@images/home/industries-Serve/Retail.png';
import Office_placeholder from '@images/home/industries-Serve/Office_placeholder.png';
import Industrial_placeholder from '@images/home/industries-Serve/Industrial_placeholder.png';
import Retail_placeholder from '@images/home/industries-Serve/Retail_placeholder.png';

export const residentialData = [
  {
    name: 'Single-Family',
    image: SingleFamily,
    imagePlaceholder: SingleFamily_placeholder,
    path: '/our-services#C-Residential',
  },
  {
    name: 'Multi-Family',
    image: MultiFamily,
    imagePlaceholder: MultiFamily_placeholder,
    path: '/our-services#C-Residential',
  },
  {
    name: 'Student Housing',
    image: StudentHousing,
    imagePlaceholder: StudentHousing_placeholder,
    path: '/our-services#C-Residential',
  },
  {
    name: 'Condominium',
    image: Condominium,
    imagePlaceholder: Condominium_placeholder,
    path: '/our-services#C-Residential',
  },
];

export const commercialData = [
  {
    name: 'Office',
    image: Office,
    imagePlaceholder: Office_placeholder,
    path: '/our-services#C-Commercial',
  },
  {
    name: 'Industrial',
    image: Industrial,
    imagePlaceholder: Industrial_placeholder,
    path: '/our-services#C-Commercial',
  },
  {
    name: 'Retail',
    image: Retail,
    imagePlaceholder: Retail_placeholder,
    path: '/our-services#C-Commercial',
  },
];
