import CustomLink from '@components/ui/CustomLink';

export default function FooterLink({ path, name, isActive, isMobile }) {
  return (
    <li>
      <CustomLink
        className={`
        ${isActive ? 'text-primary_4 font-custom-600' : 'font-custom-400 text-primary_2'} 
        ${isMobile ? `text-custom-12` : `text-custom-16`} 
        transition-all  duration-200 hover:font-custom-600 hover:text-primary_4`}
        path={path}
      >
        {name}
      </CustomLink>
    </li>
  );
}