import BasicPlan from '@images/home/pricing/BasicPlan.png';
import ProPlan from '@images/home/pricing/ProPlan.png';
import EnterprisePlan from '@images/home/pricing/EnterprisePlan.png';
import BasicPlan_placeholder from '@images/home/pricing/BasicPlan_placeholder.png';
import ProPlan_placeholder from '@images/home/pricing/ProPlan_placeholder.png';
import EnterprisePlan_placeholder from '@images/home/pricing/EnterprisePlan_placeholder.png';

import FilledButton from '@components/ui/buttons/FilledButton';
import ArrowUpSvg from '@components/ui/svg/ArrowUp';

import LazyLoadImage from '@components/ui/LazyLoadImage';

export const data = [
  {
    title: 'Basic Plan',
    price: '$99/month',
    points: [
      'Ideal for small property portfolios.',
      'Core features for essential property management. ',
      'Ideal for small property portfolios. ',
      'Core features for essential property management. ',
      'Ideal for small property portfolios.',
      'Core features for essential property management.',
    ],
    image: (
      <LazyLoadImage
        alt={'Basic Plan'}
        src={BasicPlan}
        placeholder={BasicPlan_placeholder}
        placeholderClassName={'block w-[15.0625rem] h-[11.75rem] laptopS:h-[16.75rem] laptopS:w-[21.5rem]'}
        className={'block w-[15.0625rem] h-[11.75rem] laptopS:h-[16.75rem] laptopS:w-[21.5rem]'}
      />
    ),
    button: (
      <FilledButton
      className={
        'rounded-custom-44 text-custom-12 laptopS:text-custom-20'
      }
        width='w-[8.375rem] mt-[2rem] laptopS:w-[14.875rem] laptopS:mt-[5rem]'
        height='h-10 laptopS:h-[4rem]'
        text='Start for free'
        path='#'
        icon={<ArrowUpSvg style='fill-[#292d32] ms-2' />}
      />
    ),
  },
  {
    title: 'Pro Plan',
    price: '$199/month',
    points: [
      'Perfect for growing portfolios.',
      'Additional features for more comprehensive management.',
      'Perfect for growing portfolios.',
      'Additional features for more comprehensive management.',
      'Perfect for growing portfolios.',
      'Additional features for more comprehensive management.',
    ],
    image: (
      <LazyLoadImage
        alt={'Pro Plan'}
        src={ProPlan}
        placeholder={ProPlan_placeholder}
        placeholderClassName={'block w-[15.0625rem] h-[11.75rem] laptopS:h-[16.75rem] laptopS:w-[21.5rem]'}
        className={'block w-[15.0625rem] h-[11.75rem] laptopS:h-[16.75rem] laptopS:w-[21.5rem]'}
      />
    ),
    button: (
      <FilledButton
      className={
        'rounded-custom-44 text-custom-12 laptopS:text-custom-20'
      }
        width='w-[8.375rem] mt-[2rem] laptopS:w-[14.875rem] laptopS:mt-[5rem]'
        height='h-10 laptopS:h-[4rem]'
        text='Start for free'
        path='#'
        icon={<ArrowUpSvg style='fill-[#292d32] ms-2' />}
      />
    ),
  },
  {
    title: 'Enterprise Plan',
    price: 'Custom Pricing',
    points: [
      'Tailored for large-scale property management.',
      'Advanced functionalities and premium support',
      'Tailored for large-scale property management.',
      'Advanced functionalities and premium support',
    ],
    image: (
      <LazyLoadImage
        alt={'Enterprise Plan'}
        src={EnterprisePlan}
        placeholder={EnterprisePlan_placeholder}
        placeholderClassName={'block w-[15.0625rem] h-[11.75rem] laptopS:h-[16.75rem] laptopS:w-[21.5rem]'}
        className={'block w-[15.0625rem] h-[11.75rem] laptopS:h-[16.75rem] laptopS:w-[21.5rem]'}
      />
    ),
    button: (
      <FilledButton
      className={
        'rounded-custom-44 text-custom-12 laptopS:text-custom-20'
      }
        width='w-[7.25rem] mt-[2rem] laptopS:w-[12rem] laptopS:mt-[5rem]'
        height='h-10 laptopS:h-[4rem]'
        text='Contact Us'
        path='#'
      />
    ),
  },
];
