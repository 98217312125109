const ProgressBar = ({ progress, className }) => {
  return (
    <div
      className={`w-full max-w-[21.875rem] overflow-hidden rounded-full bg-gray-200 ${className}`}
    >
      <div
        className='h-6 rounded-full bg-primary_1'
        style={{
          width: `${progress}%`,
          transition: 'width 2s',
          transitionTimingFunction: 'ease-in-out',
        }}
      ></div>
    </div>
  );
};

export default ProgressBar;
