import axios from 'axios';
import {
  saveAccessToken,
  getAuthToken,
  getStoredUserData,
} from '@utils/functions'; // Adjust according to your utility functions

const api = axios.create({
  baseURL: process.env.REACT_APP_PUBLIC_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

api.interceptors.request.use(
  (config) => {
    const accessToken = getAuthToken();
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

api.interceptors.response.use(undefined, async (error) => {
  if (error.response?.status === 401 && getStoredUserData()) {
    try {
      const { data } = await axios.get('/api/refresh-token');
      saveAccessToken(data.accessToken);
      error.config.headers['Authorization'] = `Bearer ${data.accessToken}`;
      return api(error.config);
    } catch (refreshError) {
      return Promise.reject(refreshError);
    }
  }
  return Promise.reject(error);
});

async function request(
  method,
  url,
  { data = null, params = {}, headers = {}, isPrivate = true } = {},
) {
  try {
    const config = { method, url, headers, params, ...(data && { data }) };

    if (isPrivate) {
      const authToken = getAuthToken();
      if (authToken) {
        config.headers['Authorization'] = `Bearer ${authToken}`;
      }
    }

    const response = await api(config);
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const get = (url, params, isPrivate = true, headers) =>
  request('get', url, { params, headers, isPrivate });
export const post = (url, data, isPrivate = true, headers) =>
  request('post', url, { data, headers, isPrivate });
export const put = (url, data, isPrivate = true, headers) =>
  request('put', url, { data, headers, isPrivate });
export const patch = (url, data, isPrivate = true, headers) =>
  request('patch', url, { data, headers, isPrivate });
export const del = (url, isPrivate = true, headers) =>
  request('delete', url, { headers, isPrivate });

export default api;
