import Index from './pages';
import ReactToastifyProvider from '@providers/ReactToastifyProvider';
import ReactPublicContextProvider from '@providers/ReactPublicContextProvider';
import ReactLoadingProvider from '@providers/ReactLoadingProvider';
import CustomAuthProvider from '@providers/CustomAuthProvider';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { reactQueryClientOptions } from '@configs/reactQueryClientOptions';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

const queryClient = new QueryClient(reactQueryClientOptions);

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      {process.env.NODE_ENV === "development" && <ReactQueryDevtools initialIsOpen={false} />}
      <ReactPublicContextProvider>
        <CustomAuthProvider>
          <ReactToastifyProvider>
            <ReactLoadingProvider>
              <Index />
            </ReactLoadingProvider>
          </ReactToastifyProvider>
        </CustomAuthProvider>
      </ReactPublicContextProvider>
    </QueryClientProvider>
  );
}

export default App;
