import LazyLoadImage from '@components/ui/LazyLoadImage';

export default function Card({ item }) {
  return (
    <div className='w-[27.938rem] rounded-custom-12 laptopS:rounded-custom-24 shadow-customShadow_1 laptopS:min-h-[31.375rem]'>
      <div className='p-8 laptopS:px-12 laptopS:py-20'>
        <LazyLoadImage
          alt={item?.title}
          src={item?.image}
          placeholder={item?.imagePlaceholder}
          placeholderClassName={
            'w-16 h-16 laptopS:h-[7.5rem] laptopS:w-[7.5rem]'
          }
          className={'h-16 w-16 laptopS:h-[7.5rem] laptopS:w-[7.5rem]'}
        />
        <h3 className='mt-4 text-custom-16 font-custom-600 text-primary_2 laptopS:mt-8 laptopS:text-custom-24'>
          {item?.title}
        </h3>
        <h3 className='mt-2 text-custom-12 text-primary_2 laptopS:mt-6 laptopS:text-custom-16'>
          {item?.discretion}
        </h3>
      </div>
    </div>
  );
}
