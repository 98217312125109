import A_Img from '@images/ourPartners/our-trusted-partnerships/A.png';
import B_Img from '@images/ourPartners/our-trusted-partnerships/B.png';
import C_Img from '@images/ourPartners/our-trusted-partnerships/C.png';
import D_Img from '@images/ourPartners/our-trusted-partnerships/D.png';
import E_Img from '@images/ourPartners/our-trusted-partnerships/E.png';
import F_Img from '@images/ourPartners/our-trusted-partnerships/F.png';
import J_Img from '@images/ourPartners/our-trusted-partnerships/J.png';
import K_Img from '@images/ourPartners/our-trusted-partnerships/K.png';

export const data = [
  {
    title: '',
    image: A_Img,
  },
  {
    title: '',
    image: B_Img,
  },
  {
    title: '',
    image: C_Img,
  },
  {
    title: '',
    image: D_Img,
  },
  {
    title: '',
    image: E_Img,
  },
  {
    title: '',
    image: F_Img,
  },
  {
    title: '',
    image: J_Img,
  },
  {
    title: '',
    image: K_Img,
  },
];
