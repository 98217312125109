import { useState } from 'react';
import { data } from './data';
import useURLQuery from '@hooks/useURLQuery/index';
import LazyLoadImage from '@components/ui/LazyLoadImage';
import Stepper from './Stepper';
import useWindowSize from '@hooks/useWindowSize';
import MobileStepper from './MobileStepper';

export default function SolutionsSteppers() {
  const { getAllQueryParams } = useURLQuery();

  const { width } = useWindowSize();

  const isMobile = width < 1024;

  const query = getAllQueryParams();

  const [activeIndex, setActiveIndex] = useState(
    query.id < data.length ? Number(query.id) : 0,
  );

  const RenderImage = ({ item }) => {
    return (
      <LazyLoadImage
        alt={item.title}
        src={item.image}
        placeholder={item.imagePlaceholder}
        placeholderClassName={'laptopS:w-full laptopS:h-screen object-cover'}
        className={'w-[20.4375rem] h-[20.1875rem] rounded-custom-20 laptopS:h-screen laptopS:w-full object-cover laptopS:rounded-none'}
      />
    );
  };

  function handleNext() {
    if (activeIndex + 1 < data.length) setActiveIndex((prev) => prev + 1);
  }

  function handlePrev() {
    if (activeIndex > 0) setActiveIndex((prev) => prev - 1);
  }

  return (
    <section
      id='SolutionsSteppers'
      className='flex laptopS:min-h-screen flex-col items-center overflow-x-hidden bg-primary_4'
    >
      {isMobile ? (
        <MobileStepper
          handlePrev={handlePrev}
          handleNext={handleNext}
          data={data}
          activeIndex={activeIndex}
          setActiveIndex={setActiveIndex}
          RenderImage={RenderImage}
        />
      ) : (
        <Stepper
          handlePrev={handlePrev}
          handleNext={handleNext}
          data={data}
          activeIndex={activeIndex}
          setActiveIndex={setActiveIndex}
          RenderImage={RenderImage}
        />
      )}
    </section>
  );
}
