import FilledButton from '@components/ui/buttons/FilledButton';
import PropManageLogoSvg from '@components/ui/svg/PropManageLogoSvg';

export default function GetStarted() {
  return (
    <main className='min-h-screen px-6 laptopS:px-0'>
      <div className='flex justify-center'>
        <PropManageLogoSvg className={'mt-20 h-[3.5rem] w-[9.851rem]'} />
      </div>
      <div className='flex justify-center'>
        <div className='mt-[28vh] flex max-w-[66.125rem] flex-col items-center justify-center'>
          <div className='z-10 h-[8.688rem]'>
            <h1 className='relative text-center text-custom-32 font-custom-600 text-primary_2 laptopS:text-custom-48'>
              Congratulations! Your Free Trial Account is{' '}
              <span className='inline laptopS:hidden'>Ready</span>
              <span
                className='font-rage-italic absolute left-1/2 top-[4.8rem] hidden -translate-x-1/2 -translate-y-1/2 transform text-[6.25rem] 
          font-normal text-primary_1 laptopS:block'
              >
                Ready
              </span>
            </h1>
          </div>
          <p className='mt-2 max-w-[88.875rem] text-center text-custom-16 text-primary_2 laptopS:text-custom-18'>
            Thank you for signing up for PropManage's free trial. Your account
            has been successfully created, and you're now one step closer to
            experiencing the benefits of our advanced property management
            solutions.
          </p>
          <FilledButton
            className='rounded-custom-16 text-custom-20'
            width='w-[20rem] laptopS:w-[28.625rem] mt-10 laptopS:mt-[5.5rem]'
            height='h-[4rem]'
            text='Get Started'
            path='/dashboard'
          />
        </div>
      </div>
    </main>
  );
}
