export default function PropManageLogoSvg({ className }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      viewBox='0 0 157.608 55.999'
    >
      <g
        id='Group_246467'
        data-name='Group 246467'
        transform='translate(-68.263 -148.805)'
      >
        <path
          id='Path_330111'
          data-name='Path 330111'
          d='M109.941,166.659c0,2.76-.053,5.522.016,8.28a7.791,7.791,0,0,1-2.526,6.073c-2.5,2.422-4.92,4.917-7.385,7.371a6.07,6.07,0,0,1-4.2,1.974c-2.568.076-5.135.23-7.7.257a1.708,1.708,0,0,1-1.351-.7,1.686,1.686,0,0,1,.417-1.453q5.193-5.31,10.487-10.522a6.442,6.442,0,0,0,2.165-5.116c-.125-3.55-.029-7.107-.04-10.662a2.927,2.927,0,0,0-3.163-3.188q-5.728-.031-11.456.007a6.3,6.3,0,0,0-4.507,2.074c-3.39,3.4-6.775,6.8-10.2,10.167-.375.368-1.205.792-1.476.625a2.062,2.062,0,0,1-.758-1.544c-.006-2.489.029-4.986.22-7.467a8,8,0,0,1,2.485-4.974q3.174-3.241,6.408-6.424a8.9,8.9,0,0,1,6.455-2.6c5.671-.024,11.343-.058,17.014.008,5.5.064,9.091,3.807,9.086,9.307q0,4.254,0,8.508Z'
          fill='#ffc723'
        />
        <path
          id='Path_330112'
          data-name='Path 330112'
          d='M69.186,252.144v-7.708a8.933,8.933,0,0,1,2.849-6.573c4.043-3.965,8.2-7.818,12.151-11.872a9.311,9.311,0,0,1,6.613-2.951,2.618,2.618,0,0,1,3,2.632,8.313,8.313,0,0,1-2.664,7.128c-3.521,3.311-6.858,6.817-10.279,10.235a5.949,5.949,0,0,0-1.849,4.47c.021,1.36.009,2.72,0,4.08a6.486,6.486,0,0,1-1.813,4.521c-1.7,1.818-3.492,3.556-5.228,5.344a1.66,1.66,0,0,1-1.9.635c-.808-.317-.893-1.014-.887-1.781.019-2.72.007-5.44.007-8.161Z'
          transform='translate(-0.712 -57.39)'
          fill='#ffc723'
        />
        <g
          id='Group_246268'
          data-name='Group 246268'
          transform='translate(117.156 155.879)'
        >
          <path
            id='Path_330113'
            data-name='Path 330113'
            d='M299.5,184.255a6.539,6.539,0,0,0-2.837-1.937,11.832,11.832,0,0,0-4.059-.626h-8.837v22.845h5.535v-6.388h3.3a11.368,11.368,0,0,0,3.678-.548,6.717,6.717,0,0,0,3.671-2.853,9.41,9.41,0,0,0,1.22-4.989,10.338,10.338,0,0,0-.54-3.493A6.571,6.571,0,0,0,299.5,184.255Zm-4.513,8.089a2.177,2.177,0,0,1-.932.81,3.509,3.509,0,0,1-1.484.281h-3.269v-7.029h3.269a4.409,4.409,0,0,1,1.374.186,2.2,2.2,0,0,1,.706.375,2.172,2.172,0,0,1,.674,1.012,5.462,5.462,0,0,1,.246,1.78,6.418,6.418,0,0,1-.2,1.695A3.18,3.18,0,0,1,294.991,192.344Z'
            transform='translate(-283.771 -181.305)'
            fill='#ffc723'
          />
          <path
            id='Path_330114'
            data-name='Path 330114'
            d='M387.687,193.419a8.229,8.229,0,0,0,.954-4.047,9.5,9.5,0,0,0-.557-3.4,6.113,6.113,0,0,0-1.154-1.918,6.507,6.507,0,0,0-2.829-1.8,12.436,12.436,0,0,0-3.963-.564h-9.307v22.845h5.535v-7.355h3.688l2.649,7.355h6.045l-3.359-8.661A7.067,7.067,0,0,0,387.687,193.419Zm-4.682-3.982a4.326,4.326,0,0,1-.164,1.231,2.419,2.419,0,0,1-.86,1.3,2.9,2.9,0,0,1-1.776.5h-3.839v-6.061h3.772a3.509,3.509,0,0,1,1.287.218,2.246,2.246,0,0,1,1.171,1.011A3.643,3.643,0,0,1,383.005,189.437Z'
            transform='translate(-351.08 -181.305)'
            fill='#ffc723'
          />
          <path
            id='Path_330115'
            data-name='Path 330115'
            d='M476.273,183.946a7.169,7.169,0,0,0-3.173-2.966,11.786,11.786,0,0,0-5.148-.994,12.388,12.388,0,0,0-4.676.784,7.307,7.307,0,0,0-2.473,1.624,8.742,8.742,0,0,0-2.146,3.962,21.452,21.452,0,0,0-.615,5.567,22.236,22.236,0,0,0,.48,4.987,10.175,10.175,0,0,0,1.111,2.891,6.987,6.987,0,0,0,3.178,2.861,12.27,12.27,0,0,0,5.141.94,12.939,12.939,0,0,0,4.669-.741,7.256,7.256,0,0,0,2.474-1.557,8.34,8.34,0,0,0,2.152-3.863,20.9,20.9,0,0,0,.616-5.52,22.767,22.767,0,0,0-.48-5.026A10.685,10.685,0,0,0,476.273,183.946Zm-12.363,4.721a7.752,7.752,0,0,1,.462-1.77,3.223,3.223,0,0,1,1.275-1.6,4.307,4.307,0,0,1,2.3-.537,4.551,4.551,0,0,1,2.11.427,2.838,2.838,0,0,1,.995.851,5.479,5.479,0,0,1,.879,2.289,20.194,20.194,0,0,1,.257,3.593,21.02,21.02,0,0,1-.2,3.215,7.089,7.089,0,0,1-.459,1.7,2.99,2.99,0,0,1-1.265,1.492,4.611,4.611,0,0,1-2.317.494,4.907,4.907,0,0,1-2.123-.393,2.728,2.728,0,0,1-.99-.79,4.982,4.982,0,0,1-.872-2.178,19.412,19.412,0,0,1-.257-3.541A21.833,21.833,0,0,1,463.91,188.667Z'
            transform='translate(-418.504 -179.986)'
            fill='#ffc723'
          />
          <path
            id='Path_330116'
            data-name='Path 330116'
            d='M575.715,186.266a6.324,6.324,0,0,0-3.97-3.948,11.83,11.83,0,0,0-4.059-.626h-8.837v22.845h5.535v-6.388h3.3a11.369,11.369,0,0,0,3.678-.548,6.718,6.718,0,0,0,3.671-2.853,9.41,9.41,0,0,0,1.22-4.989A10.337,10.337,0,0,0,575.715,186.266Zm-5.646,6.078a2.177,2.177,0,0,1-.932.81,3.509,3.509,0,0,1-1.484.281h-3.269v-7.029h3.269a4.408,4.408,0,0,1,1.374.186,2.205,2.205,0,0,1,.707.375,2.176,2.176,0,0,1,.674,1.012,5.468,5.468,0,0,1,.246,1.78,6.4,6.4,0,0,1-.2,1.695A3.165,3.165,0,0,1,570.069,192.344Z'
            transform='translate(-496.441 -181.305)'
            fill='#ffc723'
          />
        </g>
        <path
          id='Path_330117'
          data-name='Path 330117'
          d='M289.027,302.261h3.558l5.272,15.5,5.234-15.5h3.533V320.6h-2.371V309.774q0-.561.025-1.86t.025-2.783L299.069,320.6h-2.461l-5.284-15.464v.562q0,.674.037,2.053t.037,2.028V320.6h-2.371Z'
          transform='translate(-170.679 -118.641)'
          fill='#323232'
        />
        <path
          id='Path_330118'
          data-name='Path 330118'
          d='M383.124,302.261h2.808l6.652,18.334h-2.721L388,315.1h-7.252l-1.984,5.491h-2.546Zm4.081,10.821-2.783-8.1-2.958,8.1Z'
          transform='translate(-238.092 -118.641)'
          fill='#323232'
        />
        <path
          id='Path_330119'
          data-name='Path 330119'
          d='M458.3,302.261h2.926l9.143,14.813V302.261h2.371v18.334h-2.79l-9.279-14.8v14.8H458.3Z'
          transform='translate(-301.549 -118.641)'
          fill='#323232'
        />
        <path
          id='Path_330120'
          data-name='Path 330120'
          d='M539.634,302.261h2.808l6.652,18.334h-2.721l-1.86-5.491h-7.251l-1.984,5.491h-2.546Zm4.081,10.821-2.783-8.1-2.958,8.1Z'
          transform='translate(-359.094 -118.641)'
          fill='#323232'
        />
        <path
          id='Path_330121'
          data-name='Path 330121'
          d='M624.591,301.168a6.517,6.517,0,0,1,3.3,5.03h-2.459a4.473,4.473,0,0,0-1.821-2.927,6.17,6.17,0,0,0-3.483-.917,5.41,5.41,0,0,0-4.2,1.91q-1.7,1.909-1.7,5.691a9.231,9.231,0,0,0,1.407,5.323,5.158,5.158,0,0,0,4.586,2.053,5.786,5.786,0,0,0,4.03-1.442q1.6-1.442,1.634-4.662h-5.741v-2.059h8.05v9.81h-1.6l-.6-2.359a9.307,9.307,0,0,1-2.2,1.922,8.13,8.13,0,0,1-4.089.924,7.891,7.891,0,0,1-5.512-2.109,10.109,10.109,0,0,1-2.517-7.264,10.511,10.511,0,0,1,2.46-7.326,7.767,7.767,0,0,1,6.052-2.6A9.174,9.174,0,0,1,624.591,301.168Z'
          transform='translate(-420.127 -117.024)'
          fill='#323232'
        />
        <path
          id='Path_330122'
          data-name='Path 330122'
          d='M703.49,302.261h13.3v2.246H705.912v5.567h10.06V312.2h-10.06v6.216h11.07v2.184H703.49Z'
          transform='translate(-491.112 -118.641)'
          fill='#323232'
        />
      </g>
    </svg>
  );
}
