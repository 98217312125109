import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import { USER_DETAILS } from './constants';

export const generateQueryString = (properties) => {
  if (!properties) {
    return '';
  }

  const validProperties = Object.entries(properties)
    .filter(([key, value]) => value) // Filter out properties with falsy values
    .map(([key, value]) => `${key}=${value}`); // Create query string for each property

  return validProperties.join('&'); // Join query strings with '&'
};
export function capitalizeFirstLetter(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}
export function getRandomNumber(min = 1, max = 1000) {
  return Math.random() * (max - min) + min;
}
export function isTimestamp(value) {
  const timestamp = Date.parse(value);
  return !isNaN(timestamp);
}
export const handleCopyText = (textToCopy, successMessage) => {
  const tempInput = document.createElement('input');
  tempInput.value = textToCopy;
  document.body.appendChild(tempInput);
  tempInput.select();
  document.execCommand('copy');
  document.body.removeChild(tempInput);
  toast.success(successMessage, {
    position: 'top-right',
    autoClose: 1500,
  });
};
export const stopPropagation = (e) => {
  e.stopPropagation();
};
export const cleanObject = (obj) => {
  const cleanedObj = {};
  for (const [key, value] of Object.entries(obj)) {
    if (value !== '' && value !== null && value !== undefined && value !== 0) {
      cleanedObj[key] = value;
    }
  }
  return cleanedObj;
};
export const generateUniqueStringWithTimestamp = () => {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0');
  const day = String(now.getDate()).padStart(2, '0');
  const hour = String(now.getHours()).padStart(2, '0');
  const minute = String(now.getMinutes()).padStart(2, '0');
  const second = String(now.getSeconds()).padStart(2, '0');
  const miliseconds = String(now.getMilliseconds());

  return `${year}${month}${day}${hour}${minute}${second}${miliseconds}`;
};
export const getUniqueId = () => {
  return `${uuidv4()}-${generateUniqueStringWithTimestamp()}`;
};

export const getStoredUserData = () => {
  const userDataJSON = localStorage.getItem(USER_DETAILS);
  return userDataJSON ? JSON.parse(userDataJSON) : null;
};

export const saveAccessToken = (accessToken) => {
  const userData = getStoredUserData();
  if (userData) {
    userData.accessToken = accessToken;
    localStorage.setItem(USER_DETAILS, JSON.stringify(userData));
  }
};

export const getAuthToken = () => {
  const userData = getStoredUserData();
  return userData ? userData.token : null;
};
