import { useEffect } from 'react';

export default function useScrollToHash() {
  useEffect(() => {
    // https://github.com/rafgraph/react-router-hash-link/issues/13
    //setTimeout with 0 made it work in Safari - i don't know why
    setTimeout(() => {
      const { hash } = window.location;
      if (hash) {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ block: 'start', behavior: 'instant' });
          // window.scrollBy(0, 50);
        }
      }
    }, 0);
  }, []);
}
