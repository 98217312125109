import LazyLoadImage from '@components/ui/LazyLoadImage';

export default function AboutCard({ src, placeholder, alt, title, description, style, placeholderClassName }) {
  return (
    <div className={`relative ${style}`}>
      <LazyLoadImage
        alt={alt}
        src={src}
        placeholder={placeholder}
        placeholderClassName={"rounded-custom-20 h-full w-full object-cover laptopS:rounded-custom-40"}
        className={`rounded-custom-20 h-full w-full object-cover laptopS:rounded-custom-40`}
      />
      <div className='absolute inset-0 m-4 flex flex-col justify-end gap-1 laptopS:gap-2 laptopS:m-10'>
        <h3 className='text-custom-16 font-bold text-primary_2 laptopS:text-custom-24 desktop:text-custom-24'>
          {title}
        </h3>
        <p className='text-[0.75rem] text-primary_2 laptopS:text-custom-16'>
          {description}
        </p>
      </div>
    </div>
  );
}