import LoadingPage from './LoadingPage';
import GetStarted from './GetStarted';
import { useMutation } from '@tanstack/react-query';
import { XyzTransition } from '@animxyz/react';
import VerificationCodeComponent from '../../ui/VerificationCode';
import { useEffect, useState } from 'react';
import {
  postEmailVerificationQueryFn,
  resendEmailQueryFn,
} from '@queries/publicQueryFns/postQueryFns';
import { usePublicContext } from '@providers/ReactPublicContextProvider';
import useStorage from '@hooks/useStorage';
import useUser from '@hooks/useUser';
import { VerificationCodeSchema } from '@utils/forms-schemas';
import useToastNotifications from '@hooks/useToastNotifications';

export default function EmailVerification() {
  const { setIsLoading } = usePublicContext();
  const { redirectTo, isAuthenticUser, setUserData } = useUser();
  const { notifyError, notifySuccess } = useToastNotifications();
  const { getItem, removeItem } = useStorage('session');

  const context = getItem('VerificationContext');

  const [isStartRegister, setIsStartRegister] = useState(false);
  const [isSuccessStage, setIsSuccessStage] = useState(false);

  const [progress, setProgress] = useState(0);

  const {
    mutate: verificationMutate,
    isPending: isLoadingVerification,
    isError: isErrorVerification,
  } = useMutation({
    mutationFn: postEmailVerificationQueryFn,
    onSuccess: handleSuccessEmailVerification,
    onError: handleFailureEmailVerification,
  });

  const {
    mutate: resendMutate,
    isPending: isLoadingResend,
    isError: isErrorResend,
  } = useMutation({
    mutationFn: resendEmailQueryFn,
    onSuccess(data) {
      notifySuccess(data?.message);
    },
    onError(error) {
      console.error(error);
      notifyError('Something went wrong, please try later!');
    },
  });

  useEffect(() => {
    if (progress === 100) {
      setTimeout(() => {
        setIsSuccessStage(true);
      }, 2000);
    }
  }, [progress]);

  useEffect(() => {
    if (isAuthenticUser) {
      redirectTo('/dashboard');
    }
  }, []);

  useEffect(() => {
    setIsLoading(isLoadingVerification || isLoadingResend);
  }, [isLoadingVerification, isLoadingResend]);

  function handleSuccessEmailVerification(data) {
    setIsLoading(false);
    setIsStartRegister(true);
    setUserData({ ...data?.userData, token: data?.access_token });
    removeItem('VerificationContext');
    notifySuccess('Your email has been verified successfully');
  }

  function handleFailureEmailVerification(error) {
    console.error(error);
    notifyError('Something went wrong, please try later!');
  }

  return (
    <>
      {!isStartRegister && (
        <VerificationCodeComponent
          verificationMutate={verificationMutate}
          resendMutate={resendMutate}
          initialValues={{
            code: '',
            email: context?.email,
          }}
          validationSchema={VerificationCodeSchema}
          email={context?.email}
        />
      )}
      <div className='overflow-hidden'>
        <XyzTransition appear xyz={`fade down-50% duration-5`}>
          {isStartRegister && !isSuccessStage && (
            <div key={'LoadingPage'}>
              <LoadingPage progress={progress} setProgress={setProgress} />
            </div>
          )}
          {isSuccessStage && (
            <div key={'GetStarted'}>
              <GetStarted />
            </div>
          )}
        </XyzTransition>
      </div>
    </>
  );
}
