export default function Hero() {
  return (
    <section className='bg-ourPartnersMobileImg flex min-h-screen flex-col items-center justify-end bg-cover bg-center bg-no-repeat laptopS:justify-center laptopS:bg-ourPartnersImg'>
      <div className='mb-[8.5rem]'>
        <h1 className='text-center text-custom-36 font-custom-600 text-primary_4 laptopS:text-custom-88'>
          <span className='text-primary_2'>Our</span> Partners
        </h1>
        <p className='mt-4 text-center text-custom-16 text-primary_2 laptopS:text-custom-24'>
          Let’s grow our potential together.
        </p>
      </div>
    </section>
  );
}
