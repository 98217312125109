import Card from './Card';
import { cardsInfo } from './data';

export default function TechnologyIntegration() {
  return (
    <section className='flex laptopS:min-h-screen flex-col items-center bg-technologyIntegrationImg bg-cover bg-no-repeat  laptopS:px-none px-[1.5rem]'>
      <div className='py-[2.5rem] laptopS:pb-[86px] laptopS:pt-[14.30rem]'>
        <div className='text-[2.25rem] laptopS:text-[4.5rem] font-custom-600 text-primary_4'>
          <h1>Technology</h1>
          <h1>
            <span className='text-primary_1'>Integration</span>
          </h1>
        </div>
        <div className='mt-[1rem] laptopS:mt-[2rem] text-[0.75rem] laptopS:text-custom-24 text-primary_4'>
          <h2 className=''>
            Innovative solutions and seamless technology—PropManage shapes a
            future <br className='hidden laptopS:block' /> where property management is redefined for bold success.
          </h2>
        </div>
        <div className='mt-[2.5rem] laptopS:mt-[5rem] flex flex-wrap gap-4 laptopS:gap-10 laptopL:flex-nowrap justify-center'>
          {cardsInfo.map((item) => {
            return (
              <Card
                key={item.title}
                title={item.title}
                description={item.description}
              />
            );
          })}
        </div>
      </div>
    </section>
  );
}
