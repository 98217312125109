import Footer from '@components/layout/Footer';
import Header from '@components/layout/Header';
import { AnimationLayout } from '@layouts/AnimationLayout/index';

export default function PublicLayout({
  children,
  isFooter = true,
  isHeader = true,
}) {
  return (
    <main className='overflow-hidden'>
      {isHeader && <Header />}
      <AnimationLayout>{children}</AnimationLayout>
      {isFooter && <Footer />}
    </main>
  );
}
