import twoColleaguesFactory from '@images/home/our-solutions/two-colleagues-factory.png';
import PreventiveMaintenance from '@images/home/our-solutions/PreventiveMaintenance3.jpg';
import VendorManagement from '@images/home/our-solutions/VendorManagement.png';
import AssetsManagement from '@images/home/our-solutions/AssetsManagement.png';
import FacilityManagement from '@images/home/our-solutions/FacilityManagement.png';
import MobileAccess from '@images/home/our-solutions/MobileAccess.png';
import AIOperation from '@images/home/our-solutions/AIOperation.png';
import twoColleaguesFactory_placeholder from '@images/home/our-solutions/two-colleagues-factory_placeholder.png';
import PreventiveMaintenance_placeholder from '@images/home/our-solutions/PreventiveMaintenance3_placeholder.jpg';
import VendorManagement_placeholder from '@images/home/our-solutions/VendorManagement_placeholder.png';
import AssetsManagement_placeholder from '@images/home/our-solutions/AssetsManagement_placeholder.png';
import FacilityManagement_placeholder from '@images/home/our-solutions/FacilityManagement_placeholder.png';
import MobileAccess_placeholder from '@images/home/our-solutions/MobileAccess_placeholder.png';
import AIOperation_placeholder from '@images/home/our-solutions/AIOperation_placeholder.png';

export const data = [
  {
    index: 0,
    title: 'Maintenance Management',
    description:
      'Property management maintenance software that cuts out the paperwork, improves response times, and automates every task.',
    path: '/our-services?id=0#SolutionsSteppers',
    image: twoColleaguesFactory,
    imagePlaceholder: twoColleaguesFactory_placeholder,
  },
  {
    index: 1,
    title: 'Preventive Maintenance',
    description:
      'Prevent problems before they happen. Keep your operations running smoothly with a reliable preventive maintenance and inspection system.',
    path: '/our-services?id=1#SolutionsSteppers',
    image: PreventiveMaintenance,
    imagePlaceholder: PreventiveMaintenance_placeholder,
  },
  {
    index: 2,
    title: 'Vendor management',
    description:
      'Get status updates from vendors as jobs are completed. Print checks and pay your vendors on time so they always take your calls.',
    path: '/our-services?id=2#SolutionsSteppers',
    image: VendorManagement,
    imagePlaceholder: VendorManagement_placeholder,
  },
  {
    index: 3,
    title: 'Assets Management',
    description:
      'Increase asset uptime by tracking your downtime. Know which assets are active, which are inactive, track time between failures, and create work orders to get your assets back up and running quickly.',
    path: '/our-services?id=3#SolutionsSteppers',
    image: AssetsManagement,
    imagePlaceholder: AssetsManagement_placeholder,
  },
  {
    index: 4,
    title: 'Facility Management',
    description:
      'Streamline day-to-day management processes into a simple and reliable system your whole team will love.',
    path: '/our-services?id=4#SolutionsSteppers',
    image: FacilityManagement,
    imagePlaceholder: FacilityManagement_placeholder,
  },
  {
    index: 5,
    title: 'Mobile Access',
    description:
      "Manage maintenance from anywhere, at any time Everything you need to get the job done is in the palm of your hand with mobile app, even if you can't connect to the internet.",
    path: '/our-services?id=5#SolutionsSteppers',
    image: MobileAccess,
    imagePlaceholder: MobileAccess_placeholder,
  },
  {
    index: 6,
    title: 'Al Powered Maintenance Operation',
    description:
      'Get more done with Al-powered maintenance. Boost productivity and improve data quality with the power of Al. Reduce admin work and focus on the tasks that keep your plant or facility running.',
    path: '/our-services?id=6#SolutionsSteppers',
    image: AIOperation,
    imagePlaceholder: AIOperation_placeholder,
  },
];
