import FilledButton from '@components/ui/buttons/FilledButton';
import useUser from '@hooks/useUser';
import PrivateLayout from '@layouts/PrivateLayout/index';

export default function Dashboard() {
  const { signOut } = useUser();

  return (
    <PrivateLayout>
      <FilledButton
        isButton
        className=' absolute right-0 m-4 rounded-custom-44 text-custom-12 laptopS:text-custom-20'
        width='w-[100px]'
        height='h-[35px]'
        text='Log out'
        onClick={signOut}
      />
      <h1 className='flex h-screen items-center justify-center text-custom-32 font-custom-600  text-primary_1 laptopS:text-custom-96'>
        Dashboard
      </h1>
    </PrivateLayout>
  );
}
