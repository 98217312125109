import ImplementationImg from '@images/ourServices/services/Implementation.png';
import TechnicalSupportImg from '@images/ourServices/services/TechnicalSupport.png';
import OpenAPI_Img from '@images/ourServices/services/OpenAPI.png';
import TrainingImg from '@images/ourServices/services/Training.png';
import Implementation_placeholder_Img from '@images/ourServices/services/Implementation_placeholder.png';
import TechnicalSupport_placeholder_Img from '@images/ourServices/services/TechnicalSupport_placeholder.png';
import Training_placeholder_Img from '@images/ourServices/services/Training_placeholder.png';
import OpenAPI_placeholder_Img from '@images/ourServices/services/OpenAPI_placeholder.png';

export const data = [
  {
    title: 'Implementation',
    description: `Our service options include process analysis, data conversion,
            training, project management, customization, third-party integration and more. We offer standardized, 
            scalable implementation support based on our proven methodology. 
            Our staff works with you to establish goals, procedures and realistic timelines and guides you through the project.`,
    image: ImplementationImg,
    PlaceholderImage: Implementation_placeholder_Img,
  },
  {
    title: 'Technical Support',
    description: `Our dedicated support teams are assigned based on your real estate market. 
            One-on-one support is accessible via a toll-free hotline and email, and you’ll get regular
            notifications about new features and updates. Our development teams are also on hand for specialized troubleshooting,
            and our in-house cloud services team delivers connectivity support 24/7 via a dedicated hotline.`,
    image: TechnicalSupportImg,
    PlaceholderImage: TechnicalSupport_placeholder_Img,
  },
  {
    title: 'Training',
    description: `PropManage specialists offers a variety of training programs that can be available through 
            PropManage Aspire On Demand, provides learners with quality instruction, tailored to their needs and schedule.`,
    image: TrainingImg,
    PlaceholderImage: Training_placeholder_Img,
  },
  {
    title: 'Open API',
    description: `integrates your most critical property, resident and accounting data with your other business applications,
             to increase access and insight into what matters most.`,
    image: OpenAPI_Img,
    PlaceholderImage: OpenAPI_placeholder_Img,
  },
];
