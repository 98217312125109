import CustomLink from '@components/ui/CustomLink';
import FilledButton from '@components/ui/buttons/FilledButton';
import XSvg from '@components/ui/svg/XSvg';
import { XyzTransition } from '@animxyz/react';
import PropManageLogo from '@images/PropManage-logo.png';
import CustomInput from '@components/ui/custom-inputs/CustomInput';
import { useEffect } from 'react';
import { useMutation } from '@tanstack/react-query';
import { postForgetPasswordQueryFn } from '@queries/publicQueryFns/postQueryFns';
import { ForgetPasswordEmailSchema } from '@utils/forms-schemas';
import { usePublicContext } from '@providers/ReactPublicContextProvider';
import useUser from '@hooks/useUser';
import useToastNotifications from '@hooks/useToastNotifications';
import { useFormik } from 'formik';
import useStorage from '@hooks/useStorage';
import { VERIFICATION_TYPES } from '@utils/constants';
import ErrorFormik from '@components/ui/ErrorFormik';
import RegistrationSidebar from '@components/ui/RegistrationSidebar';

export default function ForgotPasswordComponent() {
  const { redirectTo, isAuthenticUser } = useUser();
  const { setIsLoading } = usePublicContext();
  const { notifyError, notifySuccess } = useToastNotifications();
  const { setItem } = useStorage('session');

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldTouched,
  } = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: ForgetPasswordEmailSchema,
    onSubmit: onSubmit,
  });

  const { mutate, isPending: isLoadingMutate } = useMutation({
    mutationFn: postForgetPasswordQueryFn,
    onSuccess: handleSuccess,
    onError: handleFailure,
  });

  useEffect(() => {
    if (isAuthenticUser) {
      redirectTo('/dashboard');
    }
    setFieldTouched('', '');
  }, []);

  useEffect(() => {
    setIsLoading(isLoadingMutate);
  }, [isLoadingMutate]);

  function handleSuccess(data) {
    setIsLoading(false);
    setItem('VerificationContext', {
      email: values.email,
      VerificationType: VERIFICATION_TYPES.PASSWORD,
    });

    notifySuccess(data?.message);
    redirectTo('/password-verification');
  }

  function handleFailure(error) {
    console.error(error);
    if (error.response.status === 400) notifyError('Email not found');
    else notifyError('Something went wrong, please try later!');
  }

  function onSubmit() {
    mutate({
      ...values,
    });
  }
  return (
    <main className='flex min-h-screen justify-center laptopS:overflow-hidden'>
      <RegistrationSidebar />
      <XyzTransition appear xyz='fade down-50% duration-5'>
        <div className='mx-6 flex flex-col items-center justify-center laptopS:mx-0 laptopS:w-1/2 '>
          <div className='my-10 flex w-full items-center justify-between laptopS:hidden'>
            <CustomLink className='' path={'/'}>
              <img
                className='h-[3.5rem] w-[9.813rem]'
                src={PropManageLogo}
                alt='prop manageLogo'
              />
            </CustomLink>

            <div className='flex items-center justify-center'>
              <CustomLink path={'/'}>
                <XSvg className='h-8 w-8 fill-primary_1' />
              </CustomLink>
            </div>
          </div>
          <div className='max-w-[35rem]'>
            <h3 className='text-custom-24 font-custom-500 text-primary_2'>
              Forgot your password?
            </h3>
            <p className='mt-4 text-custom-16 text-primary_2'>
              Enter the email address associated with your account, We will send
              to you a link to reset your password
            </p>
          </div>
          <form
            className=' mt-10 flex w-full max-w-[35rem] flex-col gap-6'
            onSubmit={handleSubmit}
          >
            <div>
              <CustomInput
                shape={2}
                id='email'
                name='email'
                value={values?.email}
                onChange={handleChange}
                onBlur={handleBlur}
                className='h-[4.5rem] w-full'
                placeholder='Email'
                type='email'
              />
              <ErrorFormik
                isError={errors?.email}
                isTouched={touched?.email}
                error={errors?.email}
              />
            </div>
            <FilledButton
              isButton
              buttonType='submit'
              text='Send'
              width='w-full'
              height='h-[4rem]'
              className='mt-4 rounded-custom-16 text-custom-20'
            />
            <p>
              Remembered Your Password?{' '}
              <CustomLink path={'/log-in'}>
                <span className='text-custom-14 text-primary_1'>Login</span>
              </CustomLink>
            </p>
          </form>
        </div>
      </XyzTransition>
    </main>
  );
}
