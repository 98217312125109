import React from 'react';
import { data } from './data';
import Card from './Card';

export default function BecomePartner() {
  return (
    <section className='min-h-screen bg-primary_4 px-6 pb-14 laptopS:px-0 laptopS:pb-[102px] laptopS:pt-[13rem]'>
      <div className='flex w-full flex-col items-center'>
        <div className='flex max-w-[54.75rem] flex-col items-center justify-center text-center'>
          <h1 className='text-custom-36 font-custom-600 text-primary_2 laptopS:text-custom-48'>
            Become a<span className='text-primary_1'> Partner </span>with
            PropManage
          </h1>
          <p className='mt-4 px-1 text-custom-12 text-primary_2 laptopS:text-custom-16'>
            Embark on a transformative journey with PropManage. Explore
            partnership opportunities that can redefine the future of property
            management for your business.{' '}
          </p>
        </div>
        <div className='mt-10 flex w-full max-w-[93.813rem] flex-wrap justify-center gap-4 laptopS:mt-20 laptopS:gap-20'>
          {data?.map((item, index) => {
            return (
              <React.Fragment key={item?.title + index}>
                <Card item={item} />
              </React.Fragment>
            );
          })}
        </div>
      </div>
    </section>
  );
}
