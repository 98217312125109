export const MAX_SIZE_IMAGE = 10 * 1_048_576;
export const MAX_SIZE_VIDEO = 360 * 1_048_576;
export const MAX_SIZE_PDF = 5 * 1_048_576;

export const ROLES = {
  USER: 'user',
  ADMIN: 'admin',
};

export const VERIFICATION_TYPES = {
  EMAIL: 'email',
  PASSWORD: 'password',
};

export const USER_DETAILS = 'userDetails';

export const ACCEPT_IMAGES_TYPES_OBJECT = {
  'image/jpeg': [],
  'image/png': [],
  'image/webp': [],
  'image/jpg': [],
};

export const ACCEPT_VIDEO_TYPES_OBJECT = {
  'video/mp4': [],
};
export const ACCEPT_PDF_TYPES_OBJECT = {
  'application/pdf': [],
};


export const PATHS_DATA = [
  {
    name: "Homepage",
    path: "/"
  },
  {
    name: "Services",
    path: "/our-services"
  },
  {
    name: "Partners",
    path: "/partners"
  },
]