import { post } from '@configs/api';

export async function postSignInQueryFn(data) {
  return await post(`/auth/login`, data);
}

export async function postSignUpQueryFn(data) {
  return await post(`/auth/signup`, data);
}
export async function postEmailVerificationQueryFn(data) {
  return await post(`/auth/email-verify-code`, data);
}

export async function postPasswordVerificationQueryFn(data) {
  return await post(`/auth/password-verify-code`, data);
}

export async function postForgetPasswordQueryFn(data) {
  return await post(`/auth/forgot-password`, data);
}

export async function postResetPasswordQueryFn(data) {
  return await post(`/auth/reset-password`, data);
}

export async function postChangePasswordQueryFn(data) {
  return await post(`/auth/change-password`, data);
}

export async function postRefreshTokenQueryFn(data) {
  return await post(`/auth/refresh`, data);
}

export async function postSignOutQueryFn(data) {
  return await post(`/auth/sign-out`, data);
}

export async function resendEmailQueryFn(data) {
  return await post(`/auth/resend-email-code`, data);
}
export async function resendResetPasswordQueryFn(data) {
  return await post(`/auth/resend-reset-password-code`, data);
}
