import FilledButton from '@components/ui/buttons/FilledButton';
import ButtonsOutline from '@components/ui/buttons/ButtonsOutline';
import ArrowUpSvg from '@components/ui/svg/ArrowUp';

export default function Hero() {
  return (
    <section className='flex min-h-dvh items-center justify-center bg-heroHomeImg  bg-[70%] laptopS:bg-center bg-cover bg-no-repeat laptopS:block'>
      <div className='flex flex-col px-[1.5rem] laptopS:items-center laptopS:pt-[37vh]'>
        <div className='text-center text-[2.25rem] font-custom-600 text-primary_4 laptopS:text-custom-56'>
          <h1>
            Redefining Property Management{' '}
            <br className='hidden tabletS:block' />
            for a Bold <span className='text-primary_1'>Future</span>
          </h1>
        </div>
        <div className='mt-[.5rem] text-center text-[1rem] text-primary_4 laptopS:mt-[2rem] laptopS:text-custom-24'>
          <h2 className=''>
            Innovative solutions and seamless technology—PropManage shapes a
            future <br className='hidden tabletS:block' /> where property
            management is redefined for bold success.
          </h2>
        </div>
        <div className='mt-[2rem] flex justify-center gap-6 laptopS:mt-[5rem]'>
          <ButtonsOutline
            className={
              'rounded-custom-44 text-custom-12 laptopS:text-custom-20'
            }
            width='w-[7.25rem] laptopS:w-[12rem]'
            height='h-[2.5rem] laptopS:h-[4rem]'
            text='Learn More'
            path='/#learn-more'
          />
          <FilledButton
            className={
              'rounded-custom-44 text-custom-12 laptopS:text-custom-20'
            }
            width='w-[8.375rem] laptopS:w-[14.875rem] '
            height='h-[2.5rem] laptopS:h-[4rem]'
            text='Start for free'
            path='/#C-Pricing'
            icon={<ArrowUpSvg style={'fill-[#292d32] ms-2'} />}
          />
        </div>
      </div>
    </section>
  );
}
