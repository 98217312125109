import XSvg from '@components/ui/svg/XSvg';
import { useEffect, useRef, useState } from 'react';
import Step1 from './Step1';
import Step2 from './Step2';
import { XyzTransition } from '@animxyz/react';
import CustomLink from '@components/ui/CustomLink';
import { usePublicContext } from '@providers/ReactPublicContextProvider';
import useUser from '@hooks/useUser/index';
import { useFormik } from 'formik';
import { SignUpSchema } from '@utils/forms-schemas';
import { useMutation } from '@tanstack/react-query';
import { postSignUpQueryFn } from '@queries/publicQueryFns/postQueryFns';
import { VERIFICATION_TYPES } from '@utils/constants';
import useStorage from '@hooks/useStorage';
import useToastNotifications from '@hooks/useToastNotifications';
import RegistrationSidebar from '@components/ui/RegistrationSidebar';
import PropManageLogo from '@images/PropManage-logo.png';

export default function SignUpComponent() {
  const { redirectTo, isAuthenticUser } = useUser();
  const { setIsLoading } = usePublicContext();
  const { notifyError, notifySuccess } = useToastNotifications();

  const { setItem } = useStorage('session');

  const submitButton = useRef();

  const [renderedStep, setRenderedStep] = useState();
  const [step, setStep] = useState(1);

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
    isValid,
  } = useFormik({
    initialValues: {
      fullName: '',
      email: '',
      companyName: '',
      phoneNumber: '',
      numberOfUnits: '',
      password: '',
    },
    validationSchema: SignUpSchema,
    onSubmit: onSubmit,
  });

  const {
    mutate,
    isPending: isLoadingMutate,
    isError: isErrorMutate,
  } = useMutation({
    mutationFn: postSignUpQueryFn,
    onSuccess(data) {
      setItem('VerificationContext', {
        email: values.email,
        VerificationType: VERIFICATION_TYPES.EMAIL,
        redirectUrl: '/sign-up',
      });

      notifySuccess(data?.message);
      redirectTo('/email-verification');
      setIsLoading(false);
    },
    onError(error) {
      console.error(error);
      if (error.response.status === 409) notifyError('Email Already Exist!');
      else notifyError('Something went wrong, please try later!');
    },
  });

  useEffect(() => {
    if (step === 1) {
      setRenderedStep(
        <Step1
          handleNext={handleNext}
          values={values}
          errors={errors}
          touched={touched}
          handleBlur={handleBlur}
          handleChange={handleChange}
        />,
      );
    } else if (step === 2) {
      setRenderedStep(
        <Step2
          handleNext={handleNext}
          values={values}
          errors={errors}
          touched={touched}
          handleBlur={handleBlur}
          handleChange={handleChange}
        />,
      );
    }
  }, [step, values, errors, touched]);

  useEffect(() => {
    if (isAuthenticUser) {
      redirectTo('/dashboard');
    }
    setFieldTouched('', '');
  }, []);

  useEffect(() => {
    setIsLoading(isLoadingMutate);
  }, [isLoadingMutate]);

  function handleNext() {
    if (step === 2) {
      submitButton.current.click();
      return;
    }

    if (!errors.fullName && !errors.email) {
      setStep((prev) => prev + 1);
    } else {
      setFieldTouched('fullName', true);
      setFieldTouched('email', true);
    }
  }

  function onSubmit() {
    mutate({
      ...values,
      phoneNumber: String(values.phoneNumber),
    });
  }

  return (
    <main className='flex min-h-screen justify-center laptopS:overflow-hidden'>
      <form
        className='mx-5 flex w-full flex-col items-center justify-center laptopS:mx-0 laptopS:w-1/2'
        onSubmit={handleSubmit}
      >
        <XyzTransition appear xyz={`fade down-50% duration-5`}>
          <div>
            <div className='my-10 flex w-full items-center justify-between laptopS:hidden'>
              <CustomLink className='' path={'/'}>
                <img
                  className='h-[3.5rem] w-[9.813rem]'
                  src={PropManageLogo}
                  alt='prop manageLogo'
                />
              </CustomLink>

              <div className='flex items-center justify-center'>
                <CustomLink path={'/'}>
                  <XSvg className='h-8 w-8 fill-primary_1' />
                </CustomLink>
              </div>
            </div>
            <div className='flex w-full justify-center' key={step}>
              {renderedStep}
            </div>
          </div>
        </XyzTransition>

        <button type='submit' className='hidden' ref={submitButton} />
      </form>
      <RegistrationSidebar />
    </main>
  );
}
