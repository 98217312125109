import MaintenanceManagementImg from '@images/ourServices/solutions-steppers/MaintenanceManagement.png';
import PreventiveMaintenanceImg from '@images/ourServices/solutions-steppers/PreventiveMaintenance.jpg';
import VendorManagementImg from '@images/ourServices/solutions-steppers/VendorManagement.png';
import AssetsManagementImg from '@images/ourServices/solutions-steppers/AssetsManagement.png';
import FacilityManagementImg from '@images/ourServices/solutions-steppers/FacilityManagement.png';
import MobileAccessImg from '@images/ourServices/solutions-steppers/MobileAccess.png';
import AIPoweredOperationImg from '@images/ourServices/solutions-steppers/AIPoweredOperation.png';
import MaintenanceManagementImg_placeholder from '@images/ourServices/solutions-steppers/MaintenanceManagement_placeholder.png';
import PreventiveMaintenanceImg_placeholder from '@images/ourServices/solutions-steppers/PreventiveMaintenance_placeholder.jpg';
import VendorManagementImg_placeholder from '@images/ourServices/solutions-steppers/VendorManagement_placeholder.png';
import AssetsManagementImg_placeholder from '@images/ourServices/solutions-steppers/AssetsManagement_placeholder.png';
import FacilityManagementImg_placeholder from '@images/ourServices/solutions-steppers/FacilityManagement_placeholder.png';
import MobileAccessImg_placeholder from '@images/ourServices/solutions-steppers/MobileAccess_placeholder.png';
import AIPoweredOperationImg_placeholder from '@images/ourServices/solutions-steppers/AIPoweredOperation_placeholder.png';

export const data = [
  {
    index: 0,
    title: 'Maintenance Management',
    description: `Elevate your property management experience with our innovative solutions tailored to your unique needs.`,
    ladle: 'Key capabilities',
    capabilities: [
      {
        title: 'Work order management',
        description: `Put an end to time-consuming, unclear, and ineffective work orders so you can get 
                the job done faster and keep assets running at their best.`,
      },
      {
        title: 'Maintenance Scheduling',
        description: `Schedule maintenance using date, time, meter, event or condition-based triggers.
                 Simplify schedules with nested PMs and multi-asset work orders.`,
      },
      {
        title: 'Asset tracking',
        description: `Organize users, assets, inventory, purchases, and tasks by location. Plan, schedule, 
                and trigger work by time zone and get real-time updates and KPIs from each site.`,
      },
      {
        title: 'Capital planning',
        description:
          'Track asset conditional assessments, measures remaining lifespan, and calculate upcoming replacement cost.',
      },
    ],
    image: MaintenanceManagementImg,
    imagePlaceholder: MaintenanceManagementImg_placeholder,
  },
  {
    index: 1,
    title: 'Preventive Maintenance',
    description: `Prevent problems before they happen. Keep your operations running smoothly with a 
        reliable preventive maintenance and inspection system.`,
    ladle: 'Key capabilities',
    capabilities: [
      {
        title: 'Asset record Storage',
        description: `Scan asset barcodes to access work history, users manuals, warranty information, 
                associated spare parts and more.`,
      },
      {
        title: 'Maintenance Scheduling',
        description: `Schedule time-based or meter-based maintenance activities on a 
                central calendar and get notified of upcoming tasks.`,
      },
      {
        title: 'Inspection Checklist',
        description: `Create step-by-step instructions for all of your inspections and preventive maintenance tasks.`,
      },
      {
        title: 'Floorplan visualization',
        description:
          'See all assets in a facility map, or filter them down to train new staff and contractors on maintenance routes.',
      },
      {
        title: 'Automated reporting',
        description:
          'Leverage auto generated reports to view PM completion rates, proactive versus reactive work ratios, and more.',
      },
    ],
    image: PreventiveMaintenanceImg,
    imagePlaceholder: PreventiveMaintenanceImg_placeholder,
  },
  {
    index: 2,
    title: 'Vendor management',
    description: `Get status updates from vendors as jobs are completed. 
        Print checks and pay your vendors on time so they always take your calls.`,
    image: VendorManagementImg,
    imagePlaceholder: VendorManagementImg_placeholder,
  },
  {
    index: 3,
    title: 'Assets Management',
    description: `Increase asset uptime by tracking your downtime. Know which assets are active, which are inactive, track time between failures,
         and create work orders to get your assets back up and running quickly.`,
    image: AssetsManagementImg,
    imagePlaceholder: AssetsManagementImg_placeholder,
  },
  {
    index: 4,
    title: 'Facility Management',
    description: `Streamline day-to-day management processes into a simple and reliable system your whole team will love.`,
    ladle: 'Key capabilities',
    capabilities: [
      {
        title: 'Work order management',
        description: `Receive, prioritize, and dispatch incoming tickets to resolve work quickly and accurately.`,
      },
      {
        title: 'Real Time Data',
        description: `View real-view report generated automatically for insight into performance metric.`,
      },
      {
        title: 'Preventive Maintenance',
        description: `Set proactive maintenance schedules to ensure routine service on your assets and equipment.`,
      },
      {
        title: 'Floorplan visualization',
        description:
          'See all assets in a facility map, or filter them down to train new staff and contractors on maintenance routes.',
      },
      {
        title: 'Equipment and asset management',
        description:
          'View work history, user manuals, and more for all your asset, with QR code tagging to quickly found what you need.',
      },
    ],
    image: FacilityManagementImg,
    imagePlaceholder: FacilityManagementImg_placeholder,
  },
  {
    index: 5,
    title: 'Mobile Access',
    description: `Manage maintenance from anywhere, at any time Everything you need to get the job done is
         in the palm of your hand with mobile app, even if you can’t connect to the internet.`,
    image: MobileAccessImg,
    imagePlaceholder: MobileAccessImg_placeholder,
  },
  {
    index: 6,
    title: 'AI Powered Maintenance Operation',
    description: `Get more done with AI-powered maintenance. Boost productivity and improve data quality with the power of AI.
         Reduce admin work and focus on the tasks that keep your plant or facility running.`,
    ladle: 'Key capabilities',
    capabilities: [
      {
        title: 'Automated property inspections',
        description: `Robots save time by detecting issues accurately.`,
      },
      {
        title: 'Preventive Maintenance',
        description: `AI predicts and prevents severe system issues.`,
      },
      {
        title: 'Equipment and asset management',
        description: `QR code tagging for quick access, preventing unexpected failures.`,
      },
      {
        title: 'Smart home technology',
        description: `Remote control optimizes energy and enhances security through AI.`,
      },
      {
        title: 'Energy management',
        description: `AI optimizes energy consumption, reducing costs and ensuring compliance.`,
      },
      {
        title: 'Virtual assistants for property managers and tenants',
        description: `AI-driven assistants handle tasks, saving time for property managers.`,
      },
    ],
    image: AIPoweredOperationImg,
    imagePlaceholder: AIPoweredOperationImg_placeholder,
  },
];
