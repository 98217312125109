import ServiceCard from './ServiceCard';
import { data } from './data';

export default function Services() {
  return (
    <main className='flex flex-col gap-y-10'>
      {data.map((item, index) => {
        return (
          <div
            key={item.title}
            className={`${index + 1 === data.length && 'mb-14 laptopS:mb-20 desktop:mb-0'} ${index === 0 && 'mt-10 laptopS:mt-0'}`}
          >
            <ServiceCard
              title={item.title}
              description={item.description}
              image={item.image}
              PlaceholderImage={item.PlaceholderImage}
              index={index + 1}
            />
          </div>
        );
      })}
    </main>
  );
}
