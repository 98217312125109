import PublicLayout from '@layouts/PublicLayout/index';
import Hero from '@components/puplic-pages/OurServices/Hero';
import Services from '@components/puplic-pages/OurServices/Services/index';
import OurSolutions from '@components/puplic-pages/OurServices/OurSolutions/index';
import Residential from '@components/puplic-pages/OurServices/Residential/index';
import Commercial from '@components/puplic-pages/OurServices/Commercial/index';
import SolutionsSteppers from '@components/puplic-pages/OurServices/SolutionsSteppers';
import useScrollToHash from '@hooks/useScrollToHash';

export default function OurServices() {
  useScrollToHash();


  return (
    <PublicLayout>
      <Hero />
      <Services />
      <OurSolutions />
      <SolutionsSteppers />
      <Residential />
      <Commercial />
    </PublicLayout>
  );
}
