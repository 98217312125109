import { data } from './data';

export default function OurTrustedPartnerships() {
  return (
    <section className='min-h-screen bg-primary_4 py-14 px-4 laptopS:px-0 laptopS:pb-[11rem] laptopS:pt-[17.813rem]'>
      <div className='flex w-full flex-col items-center'>
        <div className='flex max-w-[38rem] flex-col justify-center text-center'>
          <h1 className='text-custom-36 laptopS:text-custom-48 font-custom-600 text-primary_2'>
            Our Trusted <span className='text-primary_1'>Partnerships</span>
          </h1>
          <p className='mt-4 text-custom-12 laptopS:text-custom-16 text-primary_2'>
            PropManage collaborates with industry giants, ensuring cutting-edge
            solutions and shared success.
          </p>
        </div>

        <div className='-m-[1px] mt-10 laptopS:mt-20 flex max-w-[102.5rem] flex-wrap overflow-hidden rounded-3xl border border-solid border-[#E2E3E2]'>
          {data?.map((item, index) => (
            <div
              key={index}
              className='-m-[0.8px] flex h-[7rem] w-[10em] laptopS:h-[12.5rem] laptopS:w-[21.875rem] grow items-center justify-center border border-solid border-[#E2E3E2] p-[1px]'
            >
              <img
                loading='lazy'
                src={item.image}
                alt={item.title}
                className='h-[4.5rem] w-[4.5rem] laptopS:h-[8.75rem] laptopS:w-[8.75rem] object-cover'
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
