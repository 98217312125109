import { useState } from 'react';
import Switcher from '@components/ui/Switcher';
import Card from './Card';
import { data } from './data';

export default function Pricing() {
  const [value, setValue] = useState('Residentials');

  const fields = ['Residentials', 'Commercial'];

  return (
    <section className='min-h-screen bg-primary_4 px-6 py-14 laptopS:pb-[6.625rem] laptopS:pt-[13.625rem]'>
      <div className='flex flex-col items-center'>
        <div className='text-center'>
          <h1
            id='C-Pricing'
            className='text-custom-36 font-custom-600 text-primary_2 laptopS:text-custom-48'
          >
            Pricing
          </h1>
          <p className='mt-4 text-custom-12 text-primary_2 laptopS:text-custom-16'>
            Choose a Plan That Fits Your Property Portfolio
          </p>
        </div>

        <div className='mt-[1.3125rem] laptopS:mt-[2.375rem]'>
          <Switcher
            fields={fields}
            value={value}
            onChange={setValue}
            className={`
              h-[2.5rem] w-[9.625rem]
              text-custom-12 font-custom-600
              laptopS:h-[4.75rem] laptopS:w-[13.75rem]
              laptopS:text-custom-20`}
          />
        </div>

        <div className='mt-[1.3125rem] flex flex-wrap justify-center gap-x-10 gap-y-20 laptopS:mt-[2.375rem]'>
          {data.map((item, index) => {
            return (
              <div key={item.title + item.price + index}>
                <Card
                  title={item.title}
                  price={item.price}
                  points={item.points}
                  button={item.button}
                  image={item.image}
                />
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}
