export default function PropManageLogoWhite({ className, logoColor = '#FFF' }) {
  return (
    <svg
      className={className}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 360 128'
    >
      <g
        id='Group_246618'
        data-name='Group 246618'
        transform='translate(-68.263 -148.805)'
      >
        <path
          id='Path_330111'
          data-name='Path 330111'
          d='M163.544,189.62c0,6.31-.121,12.624.036,18.93a17.811,17.811,0,0,1-5.776,13.883c-5.7,5.538-11.248,11.241-16.883,16.85a13.876,13.876,0,0,1-9.593,4.513c-5.872.173-11.74.525-17.611.589a3.906,3.906,0,0,1-3.088-1.595,3.854,3.854,0,0,1,.954-3.321q11.873-12.14,23.974-24.055c3.336-3.271,5.12-6.859,4.951-11.7-.285-8.116-.067-16.248-.091-24.374-.014-4.531-2.677-7.263-7.231-7.288q-13.094-.072-26.189.017c-4.115.026-7.447,1.877-10.3,4.742-7.749,7.773-15.489,15.556-23.322,23.242-.857.841-2.755,1.81-3.375,1.428a4.714,4.714,0,0,1-1.732-3.529c-.015-5.691.065-11.4.5-17.071.342-4.447,2.582-8.209,5.68-11.372Q81.7,162.1,89.1,154.827a20.356,20.356,0,0,1,14.756-5.952c12.965-.054,25.932-.133,38.9.019,12.564.147,20.782,8.7,20.771,21.277q-.009,9.725,0,19.449Z'
          transform='translate(0 0)'
          fill={logoColor}
        />
        <path
          id='Path_330112'
          data-name='Path 330112'
          d='M69.19,289.58V271.96A20.422,20.422,0,0,1,75.7,256.933c9.244-9.064,18.744-17.873,27.778-27.14a21.286,21.286,0,0,1,15.118-6.747c4.056-.172,6.573,2,6.863,6.016.46,6.371-1.221,11.715-6.091,16.3-8.049,7.57-15.678,15.585-23.5,23.4a13.6,13.6,0,0,0-4.227,10.219c.048,3.108.02,6.219.009,9.328a14.827,14.827,0,0,1-4.145,10.335c-3.894,4.156-7.983,8.13-11.952,12.217-1.228,1.265-2.545,2.158-4.345,1.451-1.846-.725-2.041-2.318-2.028-4.071.044-6.219.017-12.437.017-18.657Z'
          transform='translate(-0.443 -35.752)'
          fill={logoColor}
        />
        <g
          id='Group_246268'
          data-name='Group 246268'
          transform='translate(179.844 164.977)'
        >
          <path
            id='Path_330113'
            data-name='Path 330113'
            d='M319.739,187.551a14.949,14.949,0,0,0-6.485-4.428,27.048,27.048,0,0,0-9.28-1.432h-20.2v52.226h12.654v-14.6h7.549a25.989,25.989,0,0,0,8.409-1.252,15.356,15.356,0,0,0,8.391-6.523,21.512,21.512,0,0,0,2.79-11.405,23.633,23.633,0,0,0-1.235-7.986A15.022,15.022,0,0,0,319.739,187.551Zm-10.318,18.492a4.978,4.978,0,0,1-2.131,1.852,8.024,8.024,0,0,1-3.393.643h-7.472V192.469H303.9a10.078,10.078,0,0,1,3.14.426,5.032,5.032,0,0,1,1.615.857,4.966,4.966,0,0,1,1.542,2.313,12.488,12.488,0,0,1,.563,4.069,14.672,14.672,0,0,1-.452,3.874A7.271,7.271,0,0,1,309.421,206.043Z'
            transform='translate(-283.771 -180.807)'
            fill={logoColor}
          />
          <path
            id='Path_330114'
            data-name='Path 330114'
            d='M409.365,208.5a18.811,18.811,0,0,0,2.18-9.251,21.714,21.714,0,0,0-1.272-7.774,13.976,13.976,0,0,0-2.638-4.385,14.875,14.875,0,0,0-6.467-4.109,28.43,28.43,0,0,0-9.059-1.29H370.832v52.226h12.654V217.1h8.43l6.056,16.815h13.82l-7.679-19.8A16.157,16.157,0,0,0,409.365,208.5Zm-10.7-9.1a9.888,9.888,0,0,1-.375,2.815,5.53,5.53,0,0,1-1.965,2.963,6.62,6.62,0,0,1-4.059,1.149h-8.776V192.469h8.622a8.021,8.021,0,0,1,2.942.5,5.135,5.135,0,0,1,2.678,2.312A8.328,8.328,0,0,1,398.661,199.4Z'
            transform='translate(-325.677 -180.807)'
            fill={logoColor}
          />
          <path
            id='Path_330115'
            data-name='Path 330115'
            d='M499.722,189.039a16.389,16.389,0,0,0-7.255-6.781,26.945,26.945,0,0,0-11.769-2.272,28.321,28.321,0,0,0-10.69,1.793,16.7,16.7,0,0,0-5.653,3.714,19.986,19.986,0,0,0-4.907,9.058,49.04,49.04,0,0,0-1.407,12.728,50.83,50.83,0,0,0,1.1,11.4,23.259,23.259,0,0,0,2.54,6.61,15.974,15.974,0,0,0,7.265,6.541,28.051,28.051,0,0,0,11.753,2.15,29.58,29.58,0,0,0,10.673-1.694,16.588,16.588,0,0,0,5.656-3.558,19.064,19.064,0,0,0,4.919-8.83,47.787,47.787,0,0,0,1.408-12.62,52.045,52.045,0,0,0-1.1-11.49A24.429,24.429,0,0,0,499.722,189.039Zm-28.263,10.794a17.721,17.721,0,0,1,1.056-4.046,7.369,7.369,0,0,1,2.915-3.65,9.846,9.846,0,0,1,5.267-1.227,10.4,10.4,0,0,1,4.823.976,6.488,6.488,0,0,1,2.274,1.944,12.527,12.527,0,0,1,2.01,5.234,46.162,46.162,0,0,1,.588,8.213,48.047,48.047,0,0,1-.457,7.35,16.2,16.2,0,0,1-1.05,3.886,6.834,6.834,0,0,1-2.892,3.412,10.543,10.543,0,0,1-5.3,1.13,11.219,11.219,0,0,1-4.853-.9,6.237,6.237,0,0,1-2.263-1.806,11.39,11.39,0,0,1-1.993-4.98,44.376,44.376,0,0,1-.587-8.095A49.9,49.9,0,0,1,471.459,199.832Z'
            transform='translate(-367.655 -179.986)'
            fill={logoColor}
          />
          <path
            id='Path_330116'
            data-name='Path 330116'
            d='M597.407,192.149a14.458,14.458,0,0,0-9.075-9.025,27.043,27.043,0,0,0-9.28-1.432h-20.2v52.226H571.5v-14.6h7.549a25.99,25.99,0,0,0,8.409-1.252,15.358,15.358,0,0,0,8.391-6.523,21.512,21.512,0,0,0,2.789-11.405A23.633,23.633,0,0,0,597.407,192.149ZM584.5,206.043a4.978,4.978,0,0,1-2.131,1.852,8.023,8.023,0,0,1-3.393.643H571.5V192.469h7.472a10.077,10.077,0,0,1,3.14.426,5.04,5.04,0,0,1,1.615.857,4.973,4.973,0,0,1,1.541,2.313,12.5,12.5,0,0,1,.562,4.069,14.621,14.621,0,0,1-.452,3.874A7.238,7.238,0,0,1,584.5,206.043Z'
            transform='translate(-416.178 -180.807)'
            fill={logoColor}
          />
        </g>
        <path
          id='Path_330117'
          data-name='Path 330117'
          d='M289.027,302.261h8.135L309.214,337.7l11.966-35.437h8.078v41.914h-5.421V319.437q0-1.284.057-4.251t.058-6.363l-11.967,35.352h-5.626l-12.081-35.352v1.284q0,1.54.086,4.693t.086,4.637v24.737h-5.422Z'
          transform='translate(-106.374 -73.886)'
          fill={logoColor}
        />
        <path
          id='Path_330118'
          data-name='Path 330118'
          d='M392,302.261h6.42l15.208,41.914h-6.22l-4.252-12.554H386.579l-4.536,12.554h-5.821ZM401.331,327l-6.363-18.518L388.206,327Z'
          transform='translate(-148.387 -73.886)'
          fill={logoColor}
        />
        <path
          id='Path_330119'
          data-name='Path 330119'
          d='M458.3,302.261h6.688l20.9,33.865V302.261h5.421v41.914h-6.379L463.722,310.35v33.825H458.3Z'
          transform='translate(-187.933 -73.886)'
          fill={logoColor}
        />
        <path
          id='Path_330120'
          data-name='Path 330120'
          d='M548.51,302.261h6.42l15.208,41.914h-6.22l-4.252-12.554H543.09l-4.537,12.554h-5.821ZM557.841,327l-6.363-18.518L544.716,327Z'
          transform='translate(-223.799 -73.886)'
          fill={logoColor}
        />
        <path
          id='Path_330121'
          data-name='Path 330121'
          d='M641.2,302.452q6.159,3.282,7.537,11.5h-5.621a10.226,10.226,0,0,0-4.163-6.691,14.106,14.106,0,0,0-7.963-2.1,12.367,12.367,0,0,0-9.6,4.365q-3.9,4.365-3.9,13.011,0,7.475,3.216,12.169T631.2,339.4a13.228,13.228,0,0,0,9.213-3.3q3.649-3.3,3.734-10.657H631.02v-4.707h18.4v22.426h-3.652l-1.37-5.392a21.273,21.273,0,0,1-5.023,4.394,18.585,18.585,0,0,1-9.347,2.112,18.039,18.039,0,0,1-12.6-4.822q-5.754-6.05-5.754-16.606,0-10.528,5.624-16.748a17.756,17.756,0,0,1,13.836-5.934A20.973,20.973,0,0,1,641.2,302.452Z'
          transform='translate(-261.876 -72.879)'
          fill={logoColor}
        />
        <path
          id='Path_330122'
          data-name='Path 330122'
          d='M703.49,302.261h30.416V307.4h-24.88v12.726h23v4.85h-23v14.209h25.308v4.993H703.49Z'
          transform='translate(-306.071 -73.886)'
          fill={logoColor}
        />
      </g>
    </svg>
  );
}
