import ForgotPasswordComponent from '@components/puplic-pages/ForgotPassword/index';
import PublicLayout from '@layouts/PublicLayout/index';

export default function ForgotPassword() {
  return (
    <PublicLayout isFooter={false} isHeader={false}>
      <ForgotPasswordComponent />
    </PublicLayout>
  );
}
