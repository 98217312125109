import ImageA from '@images/home/dont-worry/a.png';
import ImageB from '@images/home/dont-worry/b.png';
import ImageC from '@images/home/dont-worry/c.png';
import ImageD from '@images/home/dont-worry/d.png';
import ImageE from '@images/home/dont-worry/e.png';
import ImageF from '@images/home/dont-worry/f.png';
import ImageA_placeholder from '@images/home/dont-worry/a_placeholder.png';
import ImageB_placeholder from '@images/home/dont-worry/b_placeholder.png';
import ImageC_placeholder from '@images/home/dont-worry/c_placeholder.png';
import ImageD_placeholder from '@images/home/dont-worry/d_placeholder.png';
import ImageE_placeholder from '@images/home/dont-worry/e_placeholder.png';
import ImageF_placeholder from '@images/home/dont-worry/f_placeholder.png';
import LazyLoadImage from '@components/ui/LazyLoadImage';

export default function ImagesBlock() {
  return (
    <div className='flex gap-8'>
    <div className='flex max-h-[67.5rem] flex-col gap-8 overflow-hidden'>
      <div className='mt-[2.875rem] h-[26.375rem] w-[18.625rem]'>
        <LazyLoadImage
          alt={'E'}
          src={ImageE}
          placeholder={ImageE_placeholder}
          placeholderClassName={'rounded-custom-44 h-auto w-full'}
          className={'h-full w-full'}
        />
      </div>
      <div className='h-[26.375rem] w-[18.625rem]'>
        <LazyLoadImage
          alt={'C'}
          src={ImageC}
          placeholder={ImageC_placeholder}
          placeholderClassName={'rounded-custom-44 h-auto w-full'}
          className={'h-full w-full'}
        />
      </div>
      <div className='h-[26.375rem] w-[18.625rem]'>
        <LazyLoadImage
          alt={'A'}
          src={ImageA}
          placeholder={ImageA_placeholder}
          placeholderClassName={'rounded-custom-44 h-auto w-full'}
          className={'h-full w-full'}
        />
      </div>
    </div>
    <div className='flex max-h-[67.5rem] flex-col gap-8 overflow-hidden '>
      <div className='relative h-[26.375rem] w-[18.625rem]'>
        <LazyLoadImage
          alt={'F'}
          src={ImageF}
          placeholder={ImageF_placeholder}
          placeholderClassName={
            'w-full h-[26.375rem] absolute bottom-0 right-0 rounded-custom-44 h-auto w-full'
          }
          className={'absolute bottom-0 right-0 h-[26.375rem] w-full'}
        />
      </div>
      <div className='h-[26.375rem] w-[18.625rem]'>
        <LazyLoadImage
          alt={'D'}
          src={ImageD}
          placeholder={ImageD_placeholder}
          placeholderClassName={
            'w-full h-full rounded-custom-44 h-auto w-full'
          }
          className={'h-full w-full'}
        />
      </div>
      <div className='h-[26.375rem] w-[18.625rem] overflow-hidden'>
        <LazyLoadImage
          alt={'B'}
          src={ImageB}
          placeholder={ImageB_placeholder}
          placeholderClassName={
            'w-full h-[26.375rem] rounded-custom-44 h-auto w-full'
          }
          className={'h-[26.375rem] w-full'}
        />
      </div>
    </div>
  </div>
  )
}
