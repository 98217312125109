import SignUpComponent from '@components/puplic-pages/SignUp/index';
import PublicLayout from '@layouts/PublicLayout/index';

export default function SignUp() {
  return (
    <PublicLayout isFooter={false} isHeader={false}>
      <SignUpComponent />
    </PublicLayout>
  );
}
