import LazyLoadImage from '@components/ui/LazyLoadImage';
import TrustedByThousandsImg from '@images/ourPartners/trusted-by-thousands/TrustedByThousands.png';
import TrustedByThousandsImg_placeholder from '@images/ourPartners/trusted-by-thousands/TrustedByThousands_placeholder.png';

export default function TrustedByThousands() {
  return (
    <section className='flex min-h-screen flex-col items-center bg-primary_4 px-6 pb-14 laptopS:px-0 laptopS:pb-[4.5rem] laptopS:pt-[13rem]'>
      <div className='max-w-[102.5rem]'>
        <div className='max-w-[51.25rem]'>
          <p className='font-rage-italic text-custom-24 font-normal text-primary_1 laptopS:text-custom-36'>
            Customers Stories
          </p>
          <p className='text-custom-24 font-custom-600 text-primary_2 laptopS:text-custom-48'>
            Trusted by Thousands of Property Management Visionaries
          </p>
          <p className='max-w-[88.875rem] text-custom-12 text-primary_2 laptopS:text-custom-18'>
            Learn How Visionary Property Managers Globally Have Successfully
            Transformed Operations with the Cutting-Edge Capabilities of
            PropManage.
          </p>
        </div>

        <div className='mt-8 rounded-[32px] bg-primary_2 laptopS:mt-[5rem]'>
          <div className='flex flex-col items-start justify-center px-6 py-10 text-white laptopS:px-20 laptopS:py-16'>
            <div className='flex w-full flex-col content-between justify-between gap-10 laptopS:flex-row laptopS:gap-[6.5rem]'>
              <div className='max-w-[55.0625rem]'>
                <p className='text-custom-12 text-primary_1 laptopS:text-custom-20'>
                  Global Realty Innovations
                </p>
                <p className='mb-6 mt-2 text-custom-18 font-custom-600 text-primary_4 laptopS:mb-12 laptopS:mt-4 laptopS:text-custom-30'>
                  A leading real estate firm,partnered with PropManage to
                  enhance their property management efficiency.
                </p>

                <div className='flex flex-col gap-4 text-custom-12 laptopS:gap-6 laptopS:text-custom-16'>
                  <p className='text-primary_4'>
                    <span className='font-custom-600'>Challenges:</span> Faced
                    challenges in streamlining maintenance processes and
                    managing diverse property portfolios.
                  </p>
                  <p className='text-primary_4'>
                    <span className='font-custom-600'>
                      PropManage Solution:
                    </span>{' '}
                    Implemented AI-powered maintenance operations, leading to
                    automated inspections, predictive maintenance, and improved
                    equipment management.{' '}
                  </p>
                  <p className='text-primary_4'>
                    <span className='font-custom-600'>Results:</span> Achieved a
                    30% reduction in maintenance costs, improved tenant
                    satisfaction, and a seamless transition to a more proactive
                    property management approach.
                  </p>
                </div>
              </div>
              <LazyLoadImage
                alt={'Trusted By Thousands'}
                src={TrustedByThousandsImg}
                placeholder={TrustedByThousandsImg_placeholder}
                placeholderClassName={'max-h-[25.563rem] w-[31.938rem]'}
                className={'max-h-[25.563rem] w-[31.938rem]'}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
