import { HashLink } from 'react-router-hash-link';
import button from '@images/home/button-home.png';
import button_placeholder from '@images/home/button-home_placeholder.png';
import LazyLoadImage from '@components/ui/LazyLoadImage';

export default function RevolutionizingProperty() {
  return (
    <section
      id='learn-more'
      className='flex min-h-screen flex-col items-center justify-center bg-primary_2 px-[1.5rem]'
    >
      <HashLink
        to={'/#about-us'}
        className='h-[2.5rem] w-[9.375rem] mb-[1.5rem] tabletS:h-[5.5rem] tabletS:w-[20.688rem] tabletS:mb-[3.5rem]'
      >
        <LazyLoadImage
          alt={'button'}
          src={button}
          placeholder={button_placeholder}
        />
      </HashLink>
      <h1 className='break-words text-center text-[1.5rem] text-primary_4 tabletS:text-custom-36 laptopS:text-custom-48 laptopS:px-[9.375rem]'>
        Revolutinizing{' '}
        <span className='text-primary_1'>property management</span> with
        tech-driven empowerment. Committed to community well-being and
        excellence in Integrated Facility Management.
      </h1>
    </section>
  );
}
