export default function PhoneSvg({ style, width = '24', height = '24' }) {
  return (
    <svg
      className={style}
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 24 24'
    >
      <g
        id='Group_226203'
        data-name='Group 226203'
        transform='translate(-255 -815)'
      >
        <rect
          id='Rectangle_24544'
          data-name='Rectangle 24544'
          width='24'
          height='24'
          transform='translate(255 815)'
          fill='none'
          opacity='0'
        />
        <path
          id='Icon_ionic-ios-call'
          data-name='Icon ionic-ios-call'
          d='M27.8,23.294a20.158,20.158,0,0,0-4.211-2.818c-1.262-.606-1.724-.594-2.618.05-.743.537-1.225,1.037-2.081.85s-2.543-1.462-4.18-3.093S11.8,14.959,11.615,14.1s.319-1.337.85-2.081c.644-.893.662-1.356.05-2.618A19.76,19.76,0,0,0,9.7,5.194c-.918-.918-1.125-.718-1.631-.537a9.279,9.279,0,0,0-1.493.793A4.5,4.5,0,0,0,4.78,7.343c-.356.768-.768,2.2,1.331,5.935a33.119,33.119,0,0,0,5.823,7.766h0l.006.006.006.006h0a33.248,33.248,0,0,0,7.766,5.823c3.736,2.1,5.167,1.687,5.935,1.331a4.428,4.428,0,0,0,1.893-1.793,9.279,9.279,0,0,0,.793-1.493C28.515,24.418,28.721,24.212,27.8,23.294Z'
          transform='translate(250.51 810.497)'
        />
      </g>
    </svg>
  );
}
