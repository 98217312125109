import { XyzTransition } from '@animxyz/react';
import SolutionView from './SolutionsView';
import FilledButton from '@components/ui/buttons/FilledButton';
import ArrowLeftSvg from '@components/ui/svg/ArrowLeftSvg';
import ArrowRightSvg from '@components/ui/svg/ArrowRightSvg';

export default function Stepper({
  data,
  activeIndex,
  setActiveIndex,
  RenderImage,
  handleNext,
  handlePrev,
}) {
  return (
    <div className='flex w-full'>
      <div className='relative me-[5rem] ms-auto flex max-w-[46.25rem] basis-2/4 flex-col justify-center'>
        <XyzTransition
          appear
          xyz='fade left-100% duration-4 ease-in-out iterate-1'
        >
          {data.map(
            (item, index) =>
              activeIndex === index && (
                <div
                  className='flex items-center justify-center'
                  key={item.title + index}
                >
                  <SolutionView item={item} />
                </div>
              ),
          )}
        </XyzTransition>

        <div
          className={`absolute bottom-0 my-12 flex w-full ${activeIndex > 0 ? 'justify-between' : 'justify-end'}`}
        >
          {activeIndex > 0 && (
            <div className='flex items-center gap-4'>
              <FilledButton
                text={<ArrowLeftSvg />}
                isButton
                onClick={handlePrev}
                width='w-[3.5rem]'
                height='h-[3.5rem]'
                className={'rounded-custom-44'}
              />
              <p className='text-custom-16 font-custom-600'>Previous</p>
            </div>
          )}
          {activeIndex + 1 < data.length && (
            <div className='flex items-center gap-4'>
              <FilledButton
                text={<ArrowRightSvg />}
                isButton
                onClick={handleNext}
                width='w-[3.5rem]'
                height='h-[3.5rem]'
                className={'rounded-custom-44'}
              />
              <p className='text-custom-16 font-custom-600'>Next </p>
            </div>
          )}
        </div>
      </div>
      <div className='basis-2/4'>
        <XyzTransition
          appear
          xyz='fade right-100% duration-4 ease-in-out iterate-1'
        >
          {data.map(
            (item, index) =>
              activeIndex === index && (
                <div key={item.title + index}>
                  <RenderImage item={item} />
                </div>
              ),
          )}
        </XyzTransition>
      </div>
    </div>
  );
}
