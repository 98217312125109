import LazyLoadImage from '@components/ui/LazyLoadImage';

export default function ServiceCard({
  title,
  description,
  image,
  PlaceholderImage,
  index,
}) {
  return (
    <section className='px-6 laptopS:min-h-screen laptopS:px-0'>
      <div
        className={`flex flex-col items-center justify-center gap-x-24 gap-y-6 laptopS:flex-row laptopS:pt-[13rem] ${index % 2 === 0 ? 'laptopS:flex-row-reverse' : ''}`}
      >
        <div className='w-full max-w-[52.563rem]'>
          <h2 className='mb-2 text-custom-28 font-custom-600 text-primary_2 laptopS:mb-4 laptopS:text-custom-56'>
            {title}
          </h2>
          <p className='text-custom-12 text-primary_2 laptopS:text-custom-18'>
            {description}
          </p>
        </div>
        <div className={`w-full h-full max-h-[49.5rem] max-w-[43.938rem]`}>
          <LazyLoadImage
            alt={title}
            src={image}
            placeholder={PlaceholderImage}
            placeholderClassName={
              'rounded-custom-20 laptopS:rounded-custom-44 h-auto w-full'
            }
            className={'rounded-custom-20 laptopS:rounded-custom-44'}
          />
        </div>
      </div>
    </section>
  );
}
