import { useMutation } from '@tanstack/react-query';
import VerificationCodeComponent from '../../ui/VerificationCode';
import { useEffect } from 'react';
import {
  postPasswordVerificationQueryFn,
  resendResetPasswordQueryFn,
} from '@queries/publicQueryFns/postQueryFns';
import { usePublicContext } from '@providers/ReactPublicContextProvider';
import useStorage from '@hooks/useStorage';
import useUser from '@hooks/useUser';
import { VerificationCodeSchema } from '@utils/forms-schemas';
import useToastNotifications from '@hooks/useToastNotifications';

export default function PasswordVerification() {
  const { setIsLoading } = usePublicContext();
  const { redirectTo, isAuthenticUser } = useUser();
  const { notifyError, notifySuccess } = useToastNotifications();
  const { getItem, removeItem } = useStorage('session');

  const context = getItem('VerificationContext');

  const { mutate: verificationMutate, isPending: isLoadingVerification } =
    useMutation({
      mutationFn: postPasswordVerificationQueryFn,
      onSuccess: handleSuccessResetVerification,
      onError: handleFailureResetVerification,
    });

  const { mutate: resendMutate, isPending: isLoadingResend } = useMutation({
    mutationFn: resendResetPasswordQueryFn,
    onSuccess(data) {
      notifySuccess(data?.message);
    },
    onError(error) {
      console.error(error);
      notifyError('Something went wrong, please try later!');
    },
  });

  useEffect(() => {
    if (isAuthenticUser) {
      redirectTo('/dashboard');
    }
  }, []);

  useEffect(() => {
    setIsLoading(isLoadingVerification || isLoadingResend);
  }, [isLoadingVerification, isLoadingResend]);

  function handleSuccessResetVerification(data) {
    setIsLoading(false);
    removeItem('VerificationContext');
    redirectTo(`/reset-password?token=${data?.token}`);
  }

  function handleFailureResetVerification(error) {
    console.error(error);
    if (error.response.status === 400)
      notifyError('Invalid or expired password reset code');
    else notifyError('Something went wrong, please try later!');
  }

  return (
    <>
      <VerificationCodeComponent
        verificationMutate={verificationMutate}
        resendMutate={resendMutate}
        initialValues={{
          code: '',
          email: context?.email,
        }}
        validationSchema={VerificationCodeSchema}
        email={context?.email}
      />
    </>
  );
}
