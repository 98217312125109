import LazyLoadImage from '@components/ui/LazyLoadImage';
import FilledButton from '@components/ui/buttons/FilledButton';
import ArrowRightSvg from '@components/ui/svg/ArrowRightSvg';
import WhyPropmanageImage from '@images/home/why-propmanage/WhyPropmanage.png';
import WhyPropmanageImage_placeholder from '@images/home/why-propmanage/WhyPropmanage_placeholder.png';

export default function WhyPropmanage() {
  return (
    <section className='bg-primary_4 px-6 pt-[3.5rem] laptopS:min-h-screen laptopS:px-0 laptopS:pb-[6.125rem] laptopS:pt-[11rem]'>
      <div className='flex flex-col items-center'>
        <div className='h-[10.813rem] mobileM:h-[8rem] laptopS:h-[10.813rem]'>
          <h1 className='relative z-10 text-center text-custom-40 font-custom-600 text-primary_2 laptopS:text-custom-80'>
            Why Propmanage?
            <span
              className='font-rage-italic absolute left-1/2 top-[8rem] -z-10 -translate-x-1/2 -translate-y-1/2 transform text-custom-80 font-normal 
              text-primary_1 mobileM:top-[4.5rem] laptopS:top-[7.375rem] laptopS:text-custom-100'
            >
              Solution
            </span>
          </h1>
        </div>
        <p className='max-w-[88.875rem] text-center text-custom-12 text-primary_2 laptopS:text-custom-18'>
          PropManage stands out as your premier property management solution,
          offering a seamless blend of advanced technology, proactive
          maintenance strategies, and a user-friendly interface. With
          comprehensive supplier management, tailored pricing plans, and
          dedicated customer support, PropManage ensures your property
          management journey is not just efficient but future-ready.
        </p>
        <div className='relative mt-[2.25rem] laptopS:mt-[5rem]'>
          <LazyLoadImage
            alt={'Why Propmanage'}
            src={WhyPropmanageImage}
            placeholder={WhyPropmanageImage_placeholder}
            placeholderClassName={
              'h-[23.6875rem] min-w-[20.4375rem] rounded-custom-20 object-cover laptopS:min-h-[29.25rem] laptopS:min-w-[102.5rem] laptopS:rounded-custom-44'
            }
            className={
              'h-[23.6875rem] min-w-[20.4375rem] rounded-custom-20 object-cover object-[45%] laptopS:min-h-[29.25rem] laptopS:min-w-[102.5rem] laptopS:rounded-custom-44'
            }
          />
          <div className='absolute inset-0 ms-6 flex flex-col items-start justify-center text-white laptopS:ms-[4rem]'>
            <h1 className='text-custom-32 font-custom-600 text-primary_4 laptopS:text-[5rem]'>
              Have Unique Needs?
            </h1>
            <p className='mt-4 max-w-[17.4375rem] text-custom-12 laptopS:max-w-[50rem] laptopS:text-custom-24'>
              For tailored solutions to your unique property management needs,
              reach out to us. Your property, your way - we're here to assist.
            </p>
            <FilledButton
              className={
                'rounded-custom-44 text-custom-12 laptopS:text-custom-20'
              }
              width='w-[9.6875rem] laptopS:w-[17.125rem] mt-[2rem] laptopS:mt-[3rem]'
              height='h-10 laptopS:h-[4rem]'
              text='Contact Us Now'
              path='#contact-us'
              icon={<ArrowRightSvg style='fill-[#292d32] ms-2' />}
            />
          </div>
        </div>
      </div>
    </section>
  );
}
