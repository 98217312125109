export default function X_SocialSvg({
  style,
  width = '1.5rem',
  height = '1.5rem',
}) {
  return (
    <svg
      className={style}
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 24 24'
    >
      <g
        id='Group_228244'
        data-name='Group 228244'
        transform='translate(-943 -3232)'
      >
        <rect
          id='Rectangle_58971'
          data-name='Rectangle 58971'
          width='24'
          height='24'
          transform='translate(943 3232)'
          fill='none'
          opacity='0'
        />
        <path
          id='Path_305096'
          data-name='Path 305096'
          d='M27.161,10.162,35.9,0H33.832L26.241,8.824,20.178,0H13.185l9.168,13.343L13.185,24h2.072l8.016-9.318L29.676,24h6.993L27.161,10.162Zm-2.838,3.3-.929-1.329L16,1.56h3.182l5.965,8.532.929,1.329,7.754,11.091H30.651l-6.327-9.05Z'
          transform='translate(930.331 3232)'
        />
      </g>
    </svg>
  );
}
